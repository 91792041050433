import React from 'react'
interface IconProps {
  color: string
  className: string
}

function Calendar({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M25.5 20c0 1.195-.345 3.425-.635 5H5.885c.335-1.78.615-3.68.615-5v-8h19v8zm-2-15v2h2v4h-19V7h4v1.75c0 .14.11.25.25.25h1.5c.14 0 .25-.11.25-.25v-5.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25V5H4.75c-.14 0-.25.11-.25.25V20c0 2-.77 5.655-.995 6.705a.245.245 0 0 0 .24.295h22.56c.115 0 .215-.08.24-.195.195-.87.955-4.805.955-6.805V5.25c0-.14-.11-.25-.25-.25H23.5zm-6.25 12c.14 0 .25-.11.25-.25v-1.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25v1.5c0 .14.11.25.25.25h1.5zm5 0c.14 0 .25-.11.25-.25v-1.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25v1.5c0 .14.11.25.25.25h1.5zm-10 5c.14 0 .25-.11.25-.25v-1.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25v1.5c0 .14.11.25.25.25h1.5zm5 0c.14 0 .25-.11.25-.25v-1.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25v1.5c0 .14.11.25.25.25h1.5zm-5-5c.14 0 .25-.11.25-.25v-1.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25v1.5c0 .14.11.25.25.25h1.5zm8.25-8.25c0 .14.11.25.25.25h1.5c.14 0 .25-.11.25-.25v-5.5c0-.14-.11-.25-.25-.25h-1.5c-.14 0-.25.11-.25.25V5h-7v2h7v1.75z"/></g></svg>
  )
}

export default Calendar