import dayjs from 'dayjs'
import sv from 'dayjs/locale/sv'
import updateLocale from 'dayjs/plugin/updateLocale'

export function getDayOfWeek(date: string) {
  dayjs.extend(updateLocale)
  dayjs.updateLocale('sv', {
    weekdays: [
      "Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"
    ]
  })

  return dayjs(date).locale(sv).format('dddd')
}