import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Reset({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className}  xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 11.2499V12.7499C11 12.8879 10.888 12.9999 10.75 12.9999H5.25C5.112 12.9999 5 12.8879 5 12.7499V7.24986C5 7.11186 5.112 6.99986 5.25 6.99986H6.75C6.888 6.99986 7 7.11186 7 7.24986V9.70486C9.259 6.47886 13.193 4.51286 17.537 5.10486C22.436 5.77286 26.36 9.79086 26.925 14.7039C27.689 21.3469 22.492 26.9999 16 26.9999C10.021 26.9999 5.142 22.2059 5.003 16.2599C5 16.1189 5.117 15.9999 5.259 15.9999H6.76C6.895 15.9999 7 16.1069 7.003 16.2419C7.132 21.0929 11.118 24.9999 16 24.9999C21.058 24.9999 25.154 20.8079 24.996 15.7149C24.847 10.9209 20.769 6.98586 15.974 6.99986C12.873 7.00886 10.147 8.59986 8.537 10.9999H10.75C10.888 10.9999 11 11.1119 11 11.2499Z" fill={color}/>
    </svg>
  )
}

export default Reset
