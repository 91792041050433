export function getPaymentMethod(paymentProviderType: string) {
  let paymentMethod = ''
  switch(paymentProviderType) {
  case 'Payex':
    paymentMethod = 'Kortbetalning'
    break
  case 'InStore':
    paymentMethod = 'Betala i butik'
    break
  default:
    paymentMethod = 'Kortbetalning'
  }

  return paymentMethod
}