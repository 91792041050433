import React from 'react'
import TimerIcon from '../icons/Timer'
import styles from './Timer.module.css'

function Timer({ createdAt, dynamicHeight }: {createdAt: string, dynamicHeight?: boolean}) {
  const OVERDUEMINUTES = 10
  const [time, setTime] = React.useState('')
  const [overdue, setOverdue] = React.useState(false)
  const classNames = `${overdue ? styles.overdue : styles.time} ${dynamicHeight ? styles.dynamicHeight : ''} `

  React.useEffect(() => {
    calculateTime()
    const timer = setInterval(() => {
      calculateTime()
    }, 10000)
    return () => clearInterval(timer)
  }, [createdAt])

  function calculateTime() {
    const created = new Date(createdAt).getTime()
    const now = new Date().getTime()
    const diff = now - created

    const hours = Math.floor(diff / 1000 / 60 / 60)
    const minutes = Math.floor((diff - (hours * 1000 * 60 * 60)) / 1000 / 60)
    const formattedHours  = hours < 10 ? `0${hours}` : hours > 99 ? '+99' : hours
    const formattedMinutes  = hours > 99 ? '59' : minutes < 10 ? `0${minutes}` : minutes

    if(overdue === false && (hours > 0 || minutes >= OVERDUEMINUTES)) {
      setOverdue(true)
    }

    setTime(`${formattedHours}:${formattedMinutes}`)
  }


  return (
    <span className={classNames}>
      <TimerIcon className={styles.icon} color="currentColor" />
      {time}
    </span>
  )  
}

export default Timer