import * as React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Orders from '../pages/Orders'
import OrdersHistory from '../pages/OrderHistory'
import Login from '../pages/Login'
import SelectStore from '../pages/SelectStore'
import SelectService from '../pages/SelectService'
import OrderView from '../pages/OrderView'
import Catering from '../pages/Catering'
import CateringHistory from '../pages/CateringHistory'
import CateringReport from '../pages/CateringReport'
import { RootState } from '../models'
import { User } from '../types/User'
import MessageToast from '../components/MessageToast'

function AppRoutes({ user }: { user: User | null}) {
  return (
    <>
      <MessageToast />
      <Routes>
        <Route path='/butik/:storeId' element={<Navigate to={`/`} />} />
        <Route
          path='/butik/:storeId/orders'
          element={<PrivateRoute user={user} />}
        >
          <Route path='/butik/:storeId/orders' element={<Orders />} />
        </Route>
        <Route
          path='/butik/:storeId/historik'
          element={<PrivateRoute user={user} />}
        >
          <Route
            path='/butik/:storeId/historik'
            element={<OrdersHistory />}
          />
        </Route>
        <Route path='/butik/:storeId/status' element={<OrderView />} />
        <Route
          path='/butik/:storeId/tjanster'
          element={<PrivateRoute user={user} />}
        >
          <Route
            path='/butik/:storeId/tjanster'
            element={<SelectService />}
          />
        </Route>
        <Route path='/butik' element={<PrivateRoute user={user} />}>
          <Route path='/butik' element={<SelectStore />} />
        </Route>
        <Route
          path='/butik/:storeId/catering'
          element={<PrivateRoute user={user} />}
        >
          <Route path='/butik/:storeId/catering' element={<Catering />} />
        </Route>
        <Route
          path='/butik/:storeId/catering/historik'
          element={<PrivateRoute user={user} />}
        >
          <Route
            path='/butik/:storeId/catering/historik'
            element={<CateringHistory />}
          />
        </Route>
        <Route
          path='/butik/:storeId/catering/rapport'
          element={<PrivateRoute user={user} />}
        >
          <Route
            path='/butik/:storeId/catering/rapport'
            element={<CateringReport />}
          />
        </Route>
        <Route path='/' element={<Login />} />
      </Routes>
    </>
  )
}

const PrivateRoute = ({ user }: { user: User | null}) => {
  const auth = user && Object.keys(user).length > 0

  return auth ? <Outlet /> : <Navigate replace to='/' />
}

function mapState(state: RootState) {
  return {
    user: state.user.current
  }
}

export default connect(mapState, null)(AppRoutes)
