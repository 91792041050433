import { createModel } from "@rematch/core"
import { fetchFrom } from "../utils/fetchData"
import { FETCHING, IDLE, ERROR } from "../helpers/constants"
import type { RootModel } from '.'
import { Store } from "../types/Store"
import { Status } from "../types/Status"
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage"
import logError from "../utils/logging"

export interface StoreState {
  userStores: Store[],
  selectedStore: Store | null,
  status: Status
}

export const store = createModel<RootModel>()({
  state: {
    userStores: [],
    selectedStore: null,
    status: IDLE,
  } as StoreState,
  reducers: {
    startFetching(state) {
      return { ...state, status: FETCHING }
    },
    setStatus(state, payload) {
      return { ...state, status: payload }
    },
    setUserStores(state, payload) {
      return { ...state, status: IDLE, userStores: payload }
    },
    setSelectedStore(state, payload) {
      return { ...state, status: IDLE, selectedStore: payload }
    },
  },
  effects: (dispatch) => ({
    async fetchUserStores(payload?: string) {
      dispatch.store.startFetching()

      try {
        const user = getUserFromLocalStorage()
        const claimStores = user?.claims?.filter((item) => item.type === "store")

        if (claimStores === undefined || claimStores.length === 0) {
          throw new Error("User has no store claims.")
        }
        
        const stores = await fetchFrom<Store[]>("/api/stores/storesForUser", {
          method: "GET",
        })

        if (stores && stores.length > 0) {
          dispatch.store.setUserStores(stores)
          if (payload && typeof payload === "string") {
            const selected = stores.find(
              (s) => s.id.toLowerCase() === payload.toLowerCase()
            )
            if (selected) {
              dispatch.store.setSelectedStore(selected)
            }
          }
        } else {
          throw new Error()
        }
      } catch (error) {
        logError(error as Error)
        dispatch.store.setUserStores([])
        dispatch.store.setStatus(ERROR)
      }
    },
    async selectStore(payload) {
      try {
        const user = getUserFromLocalStorage()
        if (user) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...user,
              selectedStore: payload,
            })
          )
        }
        dispatch.store.setSelectedStore(payload)
      } catch (error) {
        logError(error as Error)
        dispatch.store.setSelectedStore(null)
        dispatch.store.setStatus(ERROR)
      }
    },
    async resetStore() {
      try {
        dispatch.store.setUserStores([])
        dispatch.store.setSelectedStore(null)
      } catch (error) {
        logError(error as Error)
        dispatch.store.setStatus(ERROR)
      }
    },
  }),
})
