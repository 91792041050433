import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Time({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 20C6.037 20 2 15.963 2 11C2 6.037 6.037 2 11 2C15.963 2 20 6.037 20 11C20 15.963 15.963 20 11 20ZM11 0C4.935 0 0 4.935 0 11C0 17.065 4.935 22 11 22C17.065 22 22 17.065 22 11C22 4.935 17.065 0 11 0ZM12 10.5859V4.2499C12 4.1119 11.888 3.9999 11.75 3.9999H10.25C10.112 3.9999 10 4.1119 10 4.2499V11.4139L15.116 16.5299C15.214 16.6279 15.372 16.6279 15.47 16.5299L16.53 15.4699C16.628 15.3719 16.628 15.2139 16.53 15.1159L12 10.5859Z" fill={color}/>
    </svg>
  )
}

export default Time