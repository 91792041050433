import React from 'react'
import styles from './SearchOrder.module.css'
import { getDayOfWeek } from '../helpers/getWeekday'
import CateringOrderLineItem from './CateringOrderLineItem'
import SearchIcon from '../icons/SearchIcon'
import Close from '../icons/Close'
import { CateringOrder, Order } from '../types/Order'

interface Props {
  data: CateringOrder[]
  status?: string
  onLineItemClick: (id: string) => void
  onPrintOrder: (obj: { storeId: string, orderIds: string, userId?: string, type: string }) => void
  storeId: string
  userId?: string
}

function SearchOrder({
  data,
  status = 'upcoming',
  onLineItemClick,
  onPrintOrder,
  storeId,
  userId
}: Props) {
  const divRef = React.useRef<HTMLDivElement>(null)
  const [result, setResult] = React.useState<CateringOrder[]>([])
  const [hasFocus, setHasFocus] = React.useState(false)
  const [searchCriteria, setSearchCriteria] = React.useState('')

  const weekdays = React.useMemo(() => {
    return result.map((order) => {
      return getDayOfWeek(order.date)
    })
  }, [result])

  React.useEffect(() => {
    const handleFocusChange = (event: FocusEvent) => {
      const isChildFocused = divRef?.current?.contains(document.activeElement) ?? false
      const isClickOutside = !divRef?.current?.contains(event.target as Node)

      setHasFocus(isChildFocused && !isClickOutside)
    }

    document.addEventListener('focus', handleFocusChange, true)
    document.addEventListener('click', handleFocusChange)

    return () => {
      document.removeEventListener('focus', handleFocusChange, true)
      document.removeEventListener('click', handleFocusChange)
    }
  }, [])

  React.useEffect(() => {
    setResult(
      data
        .map((date) => {
          return {
            ...date,
            orders: date.orders.filter((order) => searchFilter(order))
          }
        })
        .filter((date) => {
          return date.orders.length > 0
        })
    )
  }, [searchCriteria])

  const searchFilter = React.useCallback(
    (order: Order) => {
      const { orderNo, userName, phoneNumber, deliveryDate } = order

      const compare = searchCriteria.toLowerCase()
      const lowerOrderNo = orderNo.toLowerCase()
      const lowerUserName = userName.toLowerCase()

      return (
        lowerOrderNo.includes(compare) ||
        lowerUserName.includes(compare) ||
        phoneNumber.includes(compare) ||
        deliveryDate.includes(compare)
      )
    },
    [searchCriteria]
  )

  return (
    <div ref={divRef} className={styles.searchFilterContainer}>
      <label className={styles.label}>Sök order</label>
      <div className={styles.searchField}>
        <input
          placeholder='Sök på ordernummer, namn, telefonnummer'
          className={styles.searchFilter}
          value={searchCriteria}
          onChange={(e) => setSearchCriteria(e.target.value)}
        />
        <div className={styles.searchFieldIcons}>
          {searchCriteria.length <= 1 || !hasFocus ? (
            <SearchIcon />
          ) : (
            <>
              <span onClick={() => setSearchCriteria('')}>
                <Close className={styles.closeButton} color={'#CF2E05'} />
              </span>
              <SearchIcon backgroundColor={'#E13205'} color={'#FFFF'} />
            </>
          )}
        </div>
      </div>
      {searchCriteria.length > 1 && hasFocus && (
        <div className={styles.searchResult} 
          style={{ 
            backgroundColor: result.length === 0 ? '#FEF6FA' : '#ffff',
            display: result.length === 0 ? 'flex' : 'block',
            flexDirection: 'row',
          }}>
          {result.length === 0 ? (
            <>
              <div>
                <SearchIcon className={styles.searchIcon} backgroundColor={'transparent'} color={'#A22357'} />
              </div>
              <div>
                <h2 className={styles.noSearchResultHeading}>
                  Din sökning gav ingen träff
                </h2>
                <h3 className={styles.noSearchResultText}>
                  Kolla om orden är rättstavade eller försök med andra ord.
                </h3>
              </div>
            </>
          ) : (
            <div className={styles.resultItemsContainer}>
              <h2 className={styles.searchResultHeading}>Sökresultat <span className={styles.searchResultAmount}>({result.length} st)</span></h2>
              {result.map((date, index) => {
                return (
                  date.orders.length > 0 && (
                    <div className={styles.resultItem} key={index}>
                      <h2 className={styles.sectionHeading}>
                        Leveransdatum: {weekdays[index]}, {date.date}
                      </h2>
                      <ul className={styles.list}>
                        {date.orders.map((order) => {
                          return (
                            <CateringOrderLineItem
                              onPrintOrder={onPrintOrder}
                              status={status}
                              storeId={storeId}
                              onLineItemClick={onLineItemClick}
                              key={order?.id}
                              item={order}
                              userId={userId}
                            />
                          )
                        })}
                      </ul>
                    </div>
                  )
                )
              })}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SearchOrder
