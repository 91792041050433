import React from 'react'
import styles from "./OrderNumber.module.css"

interface Props {
  number: string | number
  fontSize?: string
  height?: string
  dynamicWidth?: boolean
  dynamicHeight?: boolean
  color?: string 
  textColor?: string
}

function OrderNumber({ number, fontSize, height, dynamicWidth, dynamicHeight, color = "#777", textColor = "#FFF" }: Props) {

  const classNames = `${styles.number} ${dynamicWidth ? styles.dynamicWidth : ''} ${dynamicHeight ? styles.dynamicHeight : ''} `

  return (
    <span
      style={{ background: color, color: textColor, fontSize, height }}
      className={classNames}
    >
      {number}
    </span>
  )
}

export default OrderNumber
