import React from "react"
import { format } from "date-fns"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useRef } from 'react'
import { jsPDF } from 'jspdf'
import { FETCHING } from "../helpers/constants"
import CateringOrderItem from "../components/CateringOrderItem"
import PrintCateringReport from "../components/PrintCateringReport"
import DatePicker from "../components/DatePicker"
import Arrow from "../icons/Arrow"
import PdfIcon from "../icons/PdfIcon"
import CloseIcon from "../icons/Close"
import MarketPlaceIcon from "../icons/MarketPlace"
import CateringMenu from "../components/CateringMenu"
import styles from "./CateringReport.module.css"
import { Dispatch, RootState } from "../models"
import { Order } from "../types/Order"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function CateringReport({
  fetchOrders,
  fetchUserStores,
  selectedStore,
  orderReport,
  status,
  printOrder,
  userId
}: Props) {
  const currentDate = new Date()
  const initToDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 7
  )
  const [dateFrom, setDateFrom] = React.useState(currentDate)
  const [dateFromIsVisible, setDateFromIsVisible] = React.useState(false)
  const [dateTo, setDateTo] = React.useState(initToDate)
  const [dateToIsVisible, setDateToIsVisible] = React.useState(false)
  const [activeModal, setActiveModal] = React.useState<string | null>(null)
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const pdfRef = useRef<HTMLDivElement>(null)
  const { storeId } = useParams()

  React.useEffect(() => {
    if (!selectedStore) {
      fetchUserStores(storeId)
    }
  }, [selectedStore])

  function toggleDatePicker(type: string) {
    if (type === "FROM") {
      setDateFromIsVisible((prev) => !prev)
      setDateToIsVisible(false)
    } else if (type === "TO") {
      setDateToIsVisible((prev) => !prev)
      setDateFromIsVisible(false)
    }
  }

  React.useEffect(() => {
    if (storeId && dateFrom && dateTo) {
      getOrders(currentDate, initToDate)
    }
  }, [storeId])

  function getOrders(from: Date, to: Date) {
    fetchOrders({
      storeId,
      dateFrom: format(new Date(from), "yyyy-MM-dd"),
      dateTo: format(new Date(to), "yyyy-MM-dd"),
    })
  }

  function onSearch() {
    const toDate = new Date()
    toDate.setDate(dateTo.getDate() + 1)

    getOrders(dateFrom, toDate)
    setDateFromIsVisible(false)
    setDateToIsVisible(false)
  }

  function getIds(index: number, i: number) {
    let prevOrder: Order | null = orderReport[index].orders[i - 1]
    let nextOrder: Order | null = orderReport[index].orders[i + 1]

    if (!prevOrder) {
      const prevStep = orderReport[index - 1]
      if (prevStep) {
        prevOrder = prevStep.orders[prevStep.orders.length - 1]
      } else {
        prevOrder = null
      }
    }
    if (!nextOrder) {
      const nextStep = orderReport[index + 1]
      if (nextStep) {
        nextOrder = nextStep.orders[0]
      } else {
        nextOrder = null
      }
    }
    const prevId = prevOrder ? prevOrder.id : null
    const nextId = nextOrder ? nextOrder.id : null

    return {
      prevId,
      nextId,
    }
  }

  async function handleDownload () {
    const content = pdfRef.current

    const doc = new jsPDF({
      orientation: "portrait",
      format: "a4",
      unit: "pt",
    })

    if(content) {
      await doc.html(content, {
        margin: [40, 60, 40, 60],
        html2canvas: { scale: 0.75 },
        autoPaging: "text",
        callback: function (doc) {
          doc.save(`${selectedStore?.name}_catering-orderrapport_${new Date().toLocaleDateString('sv-SE')}.pdf`)
        }
      })
    }
  };

  return (
    <div className={styles.cateringReport}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Link className={styles.backButton} to={`/butik/${storeId}/catering`}>
            <Arrow
              className={styles.backIcon}
              direction="left"
              color="#cf2e05"
            />
            Tillbaka
          </Link>
        </div>
        <div className={styles.datePickers}>
          <span className={styles.datePickerLabel}>Startdatum</span>
          <div className={styles.datePicker}>
            <DatePicker
              isActive={dateFromIsVisible}
              setIsActive={() => toggleDatePicker("FROM")}
              firstDayOfWeek={1}
              onDateSelected={({ date }) => setDateFrom(date)}
              selected={dateFrom}
            />
          </div>
          <span className={styles.datePickerLabel}>Slutdatum</span>
          <div className={styles.datePicker}>
            <DatePicker
              isActive={dateToIsVisible}
              setIsActive={() => toggleDatePicker("TO")}
              firstDayOfWeek={1}
              onDateSelected={({ date }) => setDateTo(date)}
              selected={dateTo}
            />
          </div>
          <button
            onClick={onSearch}
            className={styles.searchButton}
            type="button"
          >
            Ok
          </button>
        </div>
      </header>
      {orderReport.length > 0 && (
        <>
          <div className={styles.headingWrapper}>
            <h1 className={styles.heading}>Orderrapport</h1>
            <button className={styles.createReportButton} onClick={handleDownload}>
              <PdfIcon 
                color="currentColor" 
                className={styles.pdfIcon} 
              />
              Skapa orderrapport
            </button>
          </div>
          <div className={styles.printContainer}>
            <div ref={pdfRef}>
              <PrintCateringReport 
                filteredOrders={orderReport} 
                dateFrom={dateFrom} 
                dateTo={dateTo} 
                storeName={selectedStore?.name}
              />
            </div>
          </div>
        </>
      )}
      
      {status === FETCHING ? (
        <div className={styles.loading}>
          <p>Laddar Orderrapport…</p>
        </div>
      ) : orderReport.length > 0 ? (
        <>
          {orderReport.map((day, index) => {
            const { date, orders } = day
            return (
              <section className={styles.section} key={index}>
                <h2 className={styles.sectionHeading}>{date}</h2>
                <ul className={styles.list}>
                  {orders.map((order, i) => {
                    const { prevId, nextId } = getIds(index, i)
                    return (
                      storeId ? 
                        <CateringOrderItem
                          storeId={storeId}
                          modalIsActive={order.id === activeModal}
                          setActiveModal={setActiveModal}
                          prevId={prevId}
                          nextId={nextId}
                          key={order.id}
                          item={order}
                          onPrintOrder={printOrder}
                          userId={userId}
                        /> 
                        : <span>Butik id saknas</span>
                    )
                  })}
                </ul>
              </section>
            )
          })}
        </>
      ) : (
        <div className={styles.noOrders}>
          <p>
            Det gick inte att hitta några ordrar mellan de valda datumen.
          </p>
        </div>
      )}
      <CateringMenu isOpen={menuIsOpen} updateStatus={setMenuIsOpen} channelTypes={selectedStore && selectedStore.channelTypes}/>
      <button
        onClick={() => setMenuIsOpen((prev) => !prev)}
        className={menuIsOpen ? styles.activeMenuButton : styles.menuButton}
        type="button"
      >
        <span className={styles.menuButtonText}>
          {selectedStore && selectedStore?.name ? (
            <>{selectedStore.name}</>
          ) : (
            <>Meny</>
          )}
        </span>
        <div className={styles.menuIcons}>
          <span className={styles.menuIcon}>
            <MarketPlaceIcon color="var(--red)" className={styles.icon} />
          </span>
          <span className={styles.activeMenuIcon}>
            <CloseIcon color="currentColor" className={styles.icon} />
          </span>
        </div>
      </button>
    </div>
  )
}

function mapState(state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
    orderReport: state.order.cateringReport,
    status: state.order.status,
    userId: state.user.current?.id
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    fetchOrders: dispatch.order.fetchCateringReport,
    printOrder: dispatch.order.printCateringOrder,
    fetchUserStores: dispatch.store.fetchUserStores,
  }
}

export default connect(mapState, mapDispatch)(CateringReport)