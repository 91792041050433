import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Groceries({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M26.94 15.956v1c-1.756 0-2.448.747-2.717 1.262a4.072 4.072 0 0 1 1.332-.242c4.7 0 4.575 10.065-.805 10.065-.363 0-.75-.046-1.162-.145l-.276.058a4.42 4.42 0 0 1-.788.073c-2.976 0-4.553-3.257-4.319-6.114.11-1.33.61-2.819 1.81-3.55a2.925 2.925 0 0 1 1.528-.408c.152 0 .306.01.457.03.373.049.751.242 1.118.35.18-.656.931-2.379 3.823-2.379zM13 25.996h3.946a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25H6.992c-1.1 0-1.992-.893-1.992-1.993V11.21c0-.057.02-.113.056-.158l2.94-3.145V5.992c0-1.05.813-1.91 1.844-1.986L9.99 4h6.41c1.102 0 1.994.892 1.994 1.992v1.264l3.643 4.514.048.137v3.68a.25.25 0 0 1-.25.248h-1.5a.25.25 0 0 1-.25-.25v-1.749h-7.085v12.16zm8.543-6.04a.976.976 0 0 0-.489.115c-.449.274-.777 1.041-.856 2.005-.1 1.244.293 2.584.983 3.332.383.417.823.62 1.343.62.187 0 .39-.026.6-.077l.464-.11.465.11c.248.06.483.09.697.09.398 0 .988-.087 1.5-.834.44-.642.69-1.569.69-2.546 0-1.038-.376-2.685-1.385-2.685-.247 0-.554.073-.887.21a2.788 2.788 0 0 1-1.114.221c-.634 0-1.159-.197-1.542-.34-.103-.039-.243-.09-.296-.103a1.83 1.83 0 0 0-.173-.009zM8.953 9.791L7.055 11.78a.255.255 0 0 0-.056.16v13.055a1 1 0 0 0 1 1.001h3v-12.4l-.001-.01v-1.225L8.951 9.792zM15.392 6h-4.396a1 1 0 0 0-1 1v.898l3.137 3.938h6.47l-3.162-3.879a.25.25 0 0 1-.048-.147l-.002-.813a1 1 0 0 0-1-.997z"/></g></svg>
  )
}

export default Groceries