import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Trash({ className, color = '#3A3A3A'  }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M21.068 24H10.931l-.857-13H13v9.75c0 .138.111.25.25.25h.5a.25.25 0 0 0 .25-.25V11h4v9.75c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25V11h2.925l-.857 13zM9 8.424c0-.069.027-.134.076-.183l1.165-1.165A.256.256 0 0 1 10.424 7h11.152c.068 0 .135.027.182.076l1.165 1.165a.258.258 0 0 1 .077.183V9H9v-.576zm14.135-2.799A2.13 2.13 0 0 0 21.628 5H18v-.75a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25V5h-3.628c-.566 0-1.108.225-1.508.625l-1.24 1.239c-.4.4-.624.943-.624 1.508v2.378c0 .138.111.25.25.25h.811l.991 14.768a.25.25 0 0 0 .249.232h13.397a.25.25 0 0 0 .249-.232L23.938 11h.812a.25.25 0 0 0 .25-.25V8.372c0-.565-.225-1.108-.624-1.508l-1.241-1.239z"/></g></svg>
  )
}
  
export default Trash