import React from "react"
import { Link, useParams } from "react-router-dom"
import { connect } from "react-redux"
import Catering from "../icons/Catering"
import Restaurant from "../icons/Restaurant"
import styles from "./SelectService.module.css"
import { Dispatch, RootState } from "../models"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function SelectService({ selectedStore, fetchUserStores }: Props) {
  const { storeId } = useParams() || {}

  React.useEffect(() => {
    if (!selectedStore) {
      fetchUserStores(storeId)
    }
  }, [storeId])

  return (
    <div className={styles.content}>
      <h1 className={styles.heading}>{selectedStore?.name}</h1>
      <div className={styles.services}>
        <Link className={styles.link} to={`/butik/${storeId}/orders`}>
          <Restaurant className={styles.icon} />
          <span className={styles.linkText}>Restaurang</span>
        </Link>
        <Link className={styles.link} to={`/butik/${storeId}/catering`}>
          <Catering className={styles.icon} />
          <span className={styles.linkText}>Catering</span>
        </Link>
      </div>
    </div>
  )
}

function mapState(state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    fetchUserStores: dispatch.store.fetchUserStores,
  }
}

export default connect(mapState, mapDispatch)(SelectService)
