import React from 'react'
import Circle from '../icons/Circle'
import styles from './Switch.module.css'

interface Props {
  id?: string
  label: string
  style?: React.CSSProperties
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
}

function Switch({ id = 'switch', label, style, onChange, checked }: Props) {
  return (
    <>
      <input className={styles.checkbox} id={id} type="checkbox" onChange={onChange} checked={checked} />
      <label className={styles.label} style={style} htmlFor={id}>
        {label && (
          <span>{label}</span>
        )}
        <span className={styles.switch}>
          <span className={styles.thumb}>
            {checked ? (
              <Circle className={styles.icon} color="#FFFFF" />
            ) : (
              <Circle className={styles.icon} color="#BDBBB9"/>
            )}
          </span>
        </span>
      </label>
    </>
  )
}

export default Switch