import React, { SyntheticEvent } from 'react'
import { DateObj, useDayzed } from 'dayzed'
import CalendarIcon from '../icons/Calendar'
import AngleIcon from '../icons/Angle'
import styles from './DatePicker.module.css'

interface Props {
  isActive: boolean
  setIsActive: () => void
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  onDateSelected: (selectedDate: DateObj, event: SyntheticEvent<Element, Event>) => void
  selected: Date
}

function DatePicker(props: Props) {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed(props)
  const { isActive, setIsActive } = props
  
  const monthNamesShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ]
  const weekdayNamesShort = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön']
  
  return (
    <div>
      <button className={styles.datePickerButton} onClick={setIsActive} type="button">
        <span>{props.selected.toLocaleDateString('sv-SE')}</span>
        <CalendarIcon color="#3a3a3a" className={styles.calendarIcon} />
      </button>
      {isActive === true && calendars.length > 0 && (
        <div className={styles.datePicker}>
          {calendars.map(calendar => (
            <div key={`${calendar.month}${calendar.year}`}>
              <div className={styles.calendarHeading}>
                <button className={styles.prevButton} {...getBackProps({ calendars })}>
                  <span className={styles.hidden}>Föregående</span>
                  <AngleIcon className={styles.prevIcon} direction="left" color="#cf2e05" />
                </button>
                <span className={styles.monthYear}>
                  {monthNamesShort[calendar.month]} {calendar.year}
                </span>
                <button className={styles.nextButton} {...getForwardProps({ calendars })}>
                  <span className={styles.hidden}>Nästa</span>
                  <AngleIcon className={styles.nextIcon} direction="right" color="#cf2e05" />
                </button>
              </div>
              <div className={styles.weekdays}>
                {weekdayNamesShort.map(weekday => (
                  <div className={styles.weekdayLabel} key={`${calendar.month}${calendar.year}${weekday}`}>
                    {weekday}
                  </div>
                ))}
              </div>
              <div className={styles.monthSquares}>
                {calendar.weeks.map((week, weekIndex) =>
                  week.map((dateObj, index) => {
                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`
                    if (!dateObj) {
                      return (
                        <div className={styles.emptySquare} key={key} />
                      )
                    }
                    const { date, selected, selectable } = dateObj

                    return (
                      <button
                        className={selected ? styles.squareButtonSelected : styles.squareButton}
                        key={key}
                        {...getDateProps({ dateObj })}
                      >
                        {selectable ? date.getDate() : 'X'}
                      </button>
                    )
                  })
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default DatePicker