import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Edit({ color = "#3a3a3a", className }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.20221 19.7542C1.07121 19.6222 0.847206 19.6762 0.788206 19.8522L0.0132062 22.1762C-0.0517938 22.3712 0.134206 22.5572 0.329206 22.4922L2.65421 21.7172C2.82921 21.6582 2.88321 21.4352 2.75121 21.3042L1.20221 19.7542ZM6.92631 18.4075L4.09831 15.5795L16.9513 2.7245L19.7803 5.5535L6.92631 18.4075ZM23.4923 6.4375L20.4883 3.4325L22.4323 1.4875C22.5303 1.3895 22.5303 1.2315 22.4323 1.1345L21.3713 0.0735C21.2743 -0.0245 21.1153 -0.0245 21.0183 0.0735L19.0723 2.0175L17.1283 0.0735C17.0303 -0.0245 16.8723 -0.0245 16.7753 0.0735L2.68331 14.1655C2.65931 14.1895 1.55831 17.5295 1.31631 18.2645C1.28631 18.3545 1.31131 18.4495 1.37831 18.5155L3.98431 21.1225C4.05431 21.1915 4.15331 21.2185 4.24631 21.1865C4.99431 20.9405 8.31631 19.8455 8.34031 19.8215L21.9023 6.2605L22.6093 6.9675L15.7143 13.8625C15.6163 13.9595 15.6163 14.1185 15.7143 14.2165L16.0683 14.5695C16.1653 14.6675 16.3233 14.6675 16.4213 14.5695L23.4923 7.4985C23.7843 7.2065 23.7843 6.7295 23.4923 6.4375Z" fill={color}/>
    </svg>
  )
}

export default Edit