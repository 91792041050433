import React from 'react'

interface IconProps {
  color?: string
  backgroundColor?: string
  className?: string
}

function SearchIcon({ color = '#868686', backgroundColor = '#EDEDED', className }: IconProps)  {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect width='24' height='24' rx='12' fill={backgroundColor} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9132 15.278C10.7688 15.278 9.02425 13.5335 9.02425 11.389C9.02425 9.245 10.7688 7.5 12.9132 7.5C15.0573 7.5 16.8018 9.245 16.8018 11.389C16.8018 13.5335 15.0573 15.278 12.9132 15.278ZM12.9132 6.5C10.2132 6.5 8.02425 8.689 8.02425 11.389C8.02425 12.559 8.43625 13.632 9.12175 14.4735L6.53675 17.0585C6.48775 17.107 6.48775 17.186 6.53675 17.235L7.06725 17.7655C7.11575 17.814 7.19475 17.814 7.24375 17.7655L9.82875 15.1805C10.6702 15.866 11.7432 16.278 12.9132 16.278C15.6128 16.278 17.8018 14.089 17.8018 11.389C17.8018 8.689 15.6128 6.5 12.9132 6.5Z'
        fill={color}
      />
    </svg>
  )
}

export default SearchIcon
