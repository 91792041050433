import React from 'react'
import styles from './CheckListItem.module.css'
import { OrderItem } from '../types/Order'
import Checkbox from './Checkbox'

interface Props {
  item: OrderItem,
  updateItemStatus: (id: string, status: number) => void
}

function CheckListItem ({ item, updateItemStatus }: Props) {
  const { name, id, status, imageUrl, price, amount, ean } = item

  function onChecked () {
    if (status === 10) {
      updateItemStatus(id, 0)
    } else if (status === 0) {
      updateItemStatus(id, 10)
    }
  }

  function getFormattedPrice (value: number) {
    if (value % 1 !== 0) {
      return `${value.toFixed(2)} kr`
    }
    return `${value} kr`
  }

  return (
    <li className={styles.orderItem}>
      <Checkbox isActive={true} id={id} onChange={onChecked} checked={status === 10}>
        <span className={styles.amount}>
          {amount} st
        </span>
        {imageUrl && (
          <img className={styles.image} src={imageUrl} alt="produktbild" />
        )}
        <span className={styles.price}>
          {getFormattedPrice(price)}
        </span>
        <p className={styles.name}>
          {name}
          <span>{ean}</span>
        </p>
      </Checkbox>
    </li>
  )
}

export default CheckListItem