import { getServiceUrl } from "./serviceUrlResolver"
import { authHeader } from "../helpers/authHeader"

async function buildHeaders(contentType: string, customHeaders: { [key:string]: string }) {
  let headers = {} as { [key:string]: string }
  
  const authHeaders = authHeader()

  const defaultHeaders = {
    "Content-Type": contentType ? contentType : "application/json;charset=UTF-8",
    "Cache-Control": "no-cache",
  }

  headers = { ...authHeaders, ...defaultHeaders }

  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
  }

  return headers
}

async function LogProblem(response: Response) {
  if (response) {
    const contentType = response.headers.get("content-type")

    if (contentType && contentType.includes("application/problem+json")) {
      const clone = response.clone()
      console.error(await clone.json())
    } else {
      console.error(response)
    }
  }
  return
}

export async function getServiceData(service: string, version: string, url: string) {
  const baseUrl = getServiceUrl(service, version)
  return await getData(`${baseUrl}${url}`, [])
}

export async function sendServiceData(service: string, version: string, url: string, data = {}, method = "POST", customHeaders = {}) {
  const baseUrl = getServiceUrl(service, version)
  return await sendData(`${baseUrl}${url}`, data, method, customHeaders)
}

export async function getData(url = "", customHeaders = []) {
  return await sendRequest(url, null, "GET", customHeaders)
}

export async function sendData(url = "", data = {}, method = "POST", customHeaders = {}) {
  return await sendRequest(url, data, method, customHeaders)
}

async function sendRequest(url: string = "", data: { [key:string]: string }|null = {}, method = "POST", customHeaders = {}) {
  const options = {
    method,
    cache: "no-cache",
    headers: await buildHeaders("application/json", customHeaders),
    body: method !== "GET" && method !== "HEAD" ? JSON.stringify(data) : null
  } as RequestInit

  const response = await fetch(url, options)

  if (!response.ok) {
    await LogProblem(response)

    if (response.status === 401) {
      localStorage.removeItem('user')
      window.location.href = '/'
      return
    }
    if (response.status === 503) {
      return
    }
  }

  let responseData = null
  const responseContentType = response.headers.get("content-type")
  if (responseContentType && responseContentType.indexOf("application/json") !== -1) {
    responseData = await response.json()
  }

  return {
    status: response.status,
    statusText: response.statusText,
    ok: response.ok,
    data: responseData
  }
}

export async function downloadFile(url = "", customHeaders = {}) {
  const options = {
    method: "GET",
    cache: "no-cache",
    headers: await buildHeaders("", customHeaders)
  } as RequestInit

  const response = await fetch(url, options)
  let ok = response.ok

  if (!response.ok) {
    await LogProblem(response)

    if (response.status === 401) {
      localStorage.removeItem('user')
      window.location.href = '/'
      return
    } else if (response.status === 503) {
      return
    }
  } else {
    try {
      const header = response.headers.get('Content-Disposition')
      if(header) {
        const parts = header.split(';')
        const filename = parts[1].split('=')[1].replaceAll("\"", "")
  
        const responseBlob = await response.blob()
  
        const fileUrl = window.URL.createObjectURL(responseBlob)
  
        const a = document.createElement("a")
        a.setAttribute('style', "display: none")
        a.href = fileUrl
        a.download = filename
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(fileUrl)
        a.remove()
      } else {
        throw new Error('Content-Disposition header was null')
      }
    } catch (error) {
      ok = false
      console.error(error)
    }
  }
  
  return {
    status: response.status,
    statusText: response.statusText,
    ok: ok,
    data: null
  }
}