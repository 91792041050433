import React from 'react'
import PhoneIcon from '../icons/Contact'
import EmailIcon from '../icons/Email'
import AddressIcon from '../icons/Location'
import styles from './ContactInfo.module.css'

interface Props {
  phoneNumber: string
  address: string | null
  email: string
  deliveryType: string
  deliveryFee: number
}

function ContactInfo({ phoneNumber, address, email, deliveryType, deliveryFee }: Props) {
  return (
    <div>
      {phoneNumber && (
        <div className={styles.contactRow}>
          <PhoneIcon className={styles.rowIcon} color='currentColor' />
          <a className={styles.phoneNumber} href={`tel:${phoneNumber}`}>
            {phoneNumber}
          </a>
        </div>
      )}
      {email && (
        <div className={styles.contactRow}>
          <EmailIcon className={styles.rowIcon} color='currentColor' />
          <span>{email}</span>
        </div>
      )}
      {address && (
        <div className={styles.contactRow}>
          <AddressIcon className={styles.rowIcon} color='currentColor' />
          <span>{address}</span>
        </div>
      )}
      {deliveryType && (
        <div className={styles.deliverySection}>
          <span className={styles.deliveryHeading}>Leveranssätt</span>
          <div className={styles.deliveryInfo}>
            <span>{deliveryType}</span>
            <span>{deliveryFee} kr</span>
          </div>
        </div>
      )}
    </div>
  )
}
export default ContactInfo
