import React from 'react'

interface IconProps {
  color?: string
  className: string
}

function Check({ className, color }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M24.74 8.06a.257.257 0 0 0-.359.029L13.747 20.453 7.62 13.089a.258.258 0 0 0-.36-.03l-1.17.969a.247.247 0 0 0-.03.353l6.906 8.264a1.037 1.037 0 0 0 1.562 0L25.939 9.38a.246.246 0 0 0-.03-.352l-1.17-.97z"/></g></svg>
  )
}

export default Check