import React from 'react'
import OrderNumber from './OrderNumber'
import styles from './DeliveryInfoContainer.module.css'
import DeliveryLabel from './DeliveryLabel'
import TableNumber from './TableNumber'

type DeliveryInfoContainerProps = {
  tableNumber: string | null
  deliveryType: number | null
  orderNumber: number | string
  bongTitle: string
  color: string
}

function DeliveryInfoContainer ({ tableNumber, deliveryType, orderNumber, bongTitle, color }: DeliveryInfoContainerProps) {


  return (
    <div className={styles.deliveryTypeLabel} style={{ borderColor: color }}>
      <OrderNumber dynamicHeight={true} color={color} number={orderNumber} />
      {(deliveryType && deliveryType > 0) || bongTitle ? (
        <DeliveryLabel type={deliveryType} bongTitle={bongTitle} />
      ) : null}
      {tableNumber && (
        <TableNumber number={tableNumber} />
      )
      }
    </div>
  )
}

export default DeliveryInfoContainer