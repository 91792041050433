import React from 'react'
import { getDayOfWeek } from '../helpers/getWeekday'
import { formatVariant } from '../helpers/formatVariant'
import printStyles from './PrintOrder.module.css'
import UserIcon from '../icons/User'
import { CateringOrderItem } from '../types/Order'

const styles = {
  page: {
    fontFamily: 'Ica Text Ny',
    fontSize: '0.875rem',
    color: '#3A3A3A'
  },
  heading: {
    padding: '0',
    margin: '1rem 0rem',
    fontFamily: 'ICA Text Ny',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  orderBox: {
    marginBottom: '1.5rem'
  },
  orderItemBox: {
    marginBottom: '0.5rem', 
    pageBreakInside: 'avoid'  
  },
  orderItem: { 
    pageBreakInside: 'avoid' 
  },
  productHeader: {
    padding: '0.5rem 1rem',
    border: '1px solid #868686',
    borderRadius: '4px 4px 0 0',
  },
  productName: {
    fontFamily: 'ICA Text Ny',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    marginRight: '0.25rem',
    fontWeight: 700,
  },
  productTotalAmount: {
    fontFamily: 'ICA Text Ny Light',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  productAmount: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontFamily: 'ICA Text Ny',
    fontWeight: 700,
    padding: '0 1rem'
  },
  productVariant: {
    marginBottom: '0.5rem'
  },
  productComment: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0 1rem',
  },
  orderComment: { 
    fontSize: '0.75rem',
    display: 'block',
    padding: '0 1rem 0.75rem'
  },
  orderAmount: {
    fontWeight: '700',
    minWidth: '4rem'
  },
  orderCustomer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    minWidth: '15rem',
    height: 'auto'
  },
  orderCustomerName: {
    fontWeight: '700'
  },
  orderNo: {
    fontWeight: '700',
    fontSize: '0.875rem',
    fontFamily: 'ICA Rubrik',
    padding: '0.25rem 0.85rem',
    borderRadius: '4px',
    border: '1px solid #868686',
    marginLeft: 'auto',
  },
  variantBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    padding: '0.5rem 0 0',
  },
  variant: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0rem 1rem'
  },
  userIcon: {
    width: '1.25rem',
    height: 'auto'
  }
} as {[keyof:string]: React.CSSProperties}

interface Props {
  orders: CateringOrderItem[]
  date: string
}

function PrintCateringProductionList({ orders, date }: Props) {
  const dayOfWeek = React.useMemo(() => {
    return getDayOfWeek(date)
  }, [date])

  return orders ? (
    <div className={printStyles.printContainer} style={styles.page}>
      <div>
        <h1 style={styles.heading}>
          Leveransdatum: {dayOfWeek}, {date}
        </h1>
        {orders.map((product, index) => {
          return (
            <div style={styles.orderBox} key={index}>
              <div 
                style={{
                  padding: '0.5rem 1rem',
                  border: '1px solid #868686',
                  borderBottom: product.amount === 0 ? '1px solid #868686' : '0px',
                  borderRadius: '4px 4px 0 0',
                  pageBreakAfter: 'avoid'
                }} >
                <span style={styles.productName}>{product.productName} </span>
                <span style={styles.productTotalAmount}>({product.amount})</span>
              </div>
              {product.products.map((item, index) => {
                if (item.amount > 0) {
                  return (
                    <div style={styles.orderItemBox} key={index}>
                      <div style={styles.orderItem}>
                        <div style={{ border: '1px solid #868686', borderRadius: index === 0 ? '0px' : '4px 4px 0px 0px', padding: '0.75rem 0' }}>
                          <span style={styles.productAmount}>{item.amount} st {product.productName}</span>
                          {item.variantName && 
                            <div style={styles.variantBox}> 
                              {formatVariant(item.variantName)?.map((variant: string, index: number) => (
                                <span style={styles.variant} key={index}>{variant}</span>
                              ))}
                              {item.productComment && (
                                <span style={styles.productComment}>Meddelande från kund: {item.productComment}</span>
                              )}
                              {item.storeComment && (
                                <span style={styles.productComment}>Intern butikskommentar: {item.storeComment}</span>
                              )}
                            </div>
                          }
                        </div>
                        <div style={{ border: '1px solid #868686', borderRadius: '0 0 4px 4px', borderTop: '0px' }}>
                          {item.orders.map((order, index) => {
                            return (
                              <div key={index}>
                                <div 
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '3rem',
                                    padding: '0.5rem 1rem',
                                    fontSize: '0.75rem',
                                    borderTop: index !== 0 ? '1px solid #acacac' : '0px'
                                  }} 
                                  key={order.orderNumber}>
                                  <span style={styles.orderAmount}>{order.amount} st</span>
                                  <div style={styles.orderCustomer}>
                                    <span style={styles.userIcon}> <UserIcon /> </span>
                                    <span style={styles.orderCustomerName}>{order.userName}</span>
                                  </div>
                                  <span style={styles.orderDelivery}>
                                    {order.deliveryDisplayName}
                                    {order.deliveryTimeSlot ? `: ${order.deliveryTimeSlot}` : ''}</span>
                                  <span style={styles.orderNo}>{order.orderNumber.toUpperCase()}</span>
                                </div>
                                {item.orderComment && (
                                  <div style={styles.orderComment}>
                                    <span>
                                      Övrigt meddelande: {item.orderComment}
                                    </span>
                                  </div> 
                                )}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          )
        })}
      </div>
    </div>
  ) : null
}

export default PrintCateringProductionList
