import React from "react"

function Restaurant({ className }: {className: string}) {
  return (
    <svg
      className={className}
      width="200px"
      height="200px"
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Illustrative Icons / Red / ReadyMeals</title>
      <g
        id="Illustrative-Icons-/-Red-/-ReadyMeals"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Bounds"
          fillOpacity="0"
          fill="#FFFFFF"
          x="0"
          y="0"
          width="200"
          height="200"
        ></rect>
        <g
          id="Paths"
          transform="translate(10.316917, 31.626995)"
          fillRule="nonzero"
        >
          <path
            d="M171.052083,63.7690048 C173.708083,48.1230048 176.710083,31.7510048 179.607083,10.6650048 C180.840083,1.67700482 178.779083,0.270004825 176.761083,0.0500048247 C174.068083,-0.246995175 172.241083,0.611004825 169.265083,7.24900482 C167.052083,12.1880048 163.101083,19.5760048 160.047083,27.2350048 C153.002083,44.9010048 152.241083,50.0430048 151.659083,55.3360048 C151.073083,60.6320048 150.302083,64.3570048 156.892083,66.3330048 C163.483083,68.3090048 160.544083,72.2750048 156.545083,87.3710048 C152.547083,102.467005 149.876083,117.451005 148.141083,126.819005 C145.890083,138.983005 160.916083,143.083005 164.193083,128.026005 C167.008083,115.105005 166.953083,87.9270048 171.052083,63.7690048 Z"
            id="Path"
            fill="#F39100"
          ></path>
          <path
            d="M29.7060829,51.7490048 C38.9620829,43.8160048 37.9380829,34.1080048 31.8860829,10.1330048 C29.5030829,0.737004825 26.3840829,0.296004825 27.7040829,7.00800482 C29.2460829,14.8590048 30.0550829,22.1540048 31.0270829,28.0720048 C32.2610829,35.5120048 28.0170829,36.2000048 27.0220829,31.2370048 C26.3570829,27.9560048 25.5790829,24.6900048 24.5620829,19.1770048 C23.4610829,13.1930048 22.7160829,9.13200482 21.8640829,5.45200482 C21.0230829,1.75700482 18.5740829,1.81800482 18.9680829,5.15400482 C19.7330829,11.4500048 20.1300829,14.2010048 20.5220829,19.0430048 C20.8660829,23.2360048 21.8120829,29.5910048 21.9980829,31.7020048 C22.5530829,37.5580048 18.0720829,38.4260048 16.8360829,31.1870048 C16.0220829,26.3630048 15.7240829,24.0950048 15.0060829,19.1380048 C14.2870829,14.1520048 14.1570829,10.0160048 13.7300829,6.96300482 C13.2920829,3.92800482 10.6920829,1.21400482 10.7540829,7.11500482 C10.8180829,13.0420048 11.1080829,16.6580048 11.3200829,25.8130048 C11.5430829,34.9520048 11.5000829,37.9850048 9.81108289,38.0510048 C6.64108289,38.1890048 5.24708289,30.2430048 4.94708289,23.8080048 C4.65708289,17.3580048 4.69608289,9.43800482 4.81408289,5.89500482 C4.93108289,2.35300482 2.96608289,2.92900482 2.15408289,7.12700482 C0.584082886,15.3860048 -0.386917114,23.0440048 0.148082886,31.7010048 C0.938082886,44.8650048 5.17608289,52.1220048 11.5210829,54.2340048 C18.0790829,56.4020048 19.7180829,65.1140048 20.0860829,76.9670048 C20.1840829,80.2870048 20.1910829,83.8700048 20.1770829,87.6160048 C20.1600829,90.3390048 20.1330829,93.1810048 20.1220829,96.0630048 C20.0790829,121.971005 21.9760829,137.726005 30.0140829,138.086005 C40.8940829,138.576005 40.5880829,128.046005 37.5750829,116.619005 C35.7600829,109.714005 32.8290829,94.4900048 31.2650829,86.6990048 C31.1610829,86.1890048 31.0600829,85.7060048 30.9670829,85.2510048 C30.4500829,82.6930048 29.7080829,79.4810048 29.0340829,76.0380048 C27.1580829,66.4840048 25.6470829,55.2350048 29.7060829,51.7490048 Z"
            id="Path"
            fill="#E3000B"
          ></path>
          <g id="Group" transform="translate(23.916505, 21.213410)">
            <path
              d="M137.294578,90.664595 C136.190578,91.659595 134.790578,91.306595 133.228578,91.306595 C108.678578,91.306595 33.2565781,91.306595 33.2565781,91.306595 C33.2565781,91.306595 13.8065781,90.846595 4.42657813,91.306595 C3.87257812,91.334595 0.873578125,91.491595 0.481578125,91.136595 C-0.036421875,90.669595 0,89.037595 0,88.378595 C0,87.059595 0.292578125,84.711595 0.292578125,83.392595 C0.292578125,81.796595 1.38157813,80.754595 3.62957813,80.754595 C26.6905781,80.754595 72.6925781,80.965595 95.8665781,80.754595 C105.940578,80.662595 122.556578,80.754595 131.454578,80.754595 C135.659578,80.754595 137.706578,80.948595 137.706578,83.392595 C137.706578,84.711595 137.756578,87.145595 137.966578,88.922595 C138.043578,89.578595 137.811578,90.197595 137.294578,90.664595 Z"
              id="Path"
              fill="#9B0600"
            ></path>
            <g transform="translate(4.419487, 0.000000)">
              <path
                d="M65.1500908,15.6695192 C41.9440908,15.636595 19.9660908,26.376595 8.31409075,46.970595 C4.11409075,54.392595 0.150090751,62.989595 0.0450907508,71.649595 C-0.0059092492,75.904595 -0.531909249,77.828595 3.87109075,78.135595 C6.72309075,78.336595 9.58509075,78.219595 12.4380908,78.140595 C28.4180908,77.690595 44.4890908,78.255595 60.4770908,78.222595 C80.1860908,78.180595 99.8940908,78.343595 119.603091,78.316595 C122.004091,78.312595 128.174091,79.433595 129.508091,76.779595 C130.831091,74.154595 129.253091,68.978595 128.779091,66.360595 C127.082091,56.973595 123.182091,47.524595 117.715091,39.690595 C105.911091,22.773595 85.0220908,15.697595 65.1500908,15.6695192 Z"
                id="Path"
                fill="#9B0600"
              ></path>
              <path
                d="M56.87208,8.07059502 C56.8780908,4.11359502 59.8780908,0.696595017 63.9660908,0.100595017 C66.1090908,-0.212404983 68.6010908,0.200595017 70.4050908,1.40159502 C72.3820908,2.71559502 73.2600908,5.05859502 73.4210908,7.30459502 C73.7390908,11.719595 70.2470908,16.121595 65.5510908,16.391595 C63.3080908,16.521595 60.9200908,15.685595 59.2530908,14.225595 C57.4910908,12.680595 56.8690908,10.308595 56.87208,8.07059502 Z"
                id="Path"
                fill="#9B0600"
              ></path>
              <path
                d="M33.9230908,45.697595 C32.6670908,47.261595 31.2420908,49.035595 29.8880908,51.275595 C28.4410908,53.666595 27.0330908,56.816595 25.5400908,60.152595 C23.3190908,65.120595 19.0260908,71.405595 15.6830908,70.509595 C12.6980908,69.709595 13.9870908,58.840595 19.3400908,49.066595 C22.8470908,42.664595 28.2210908,35.793595 32.5120908,35.037595 C34.0740908,34.763595 34.9910908,35.146595 35.4270908,35.777595 C38.4130908,40.102595 37.1910908,41.626595 33.9230908,45.697595 Z M49.2390908,29.077595 C49.0650908,29.671595 48.5540908,30.392595 47.5430908,31.171595 C46.5550908,31.926595 44.0070908,34.817595 42.1660908,34.8195973 C40.0590908,34.821595 37.6930908,33.503595 36.4320908,31.545595 C35.3400908,29.848595 36.0000908,28.694595 36.2180908,28.315595 C37.7620908,25.625595 41.2750908,23.299595 44.2170908,23.022595 C46.0820908,22.846595 47.3930908,23.502595 48.2230908,25.032595 C49.5770908,27.520595 49.3890908,28.556595 49.2390908,29.077595 Z"
                id="Shape"
                fill="#F39100"
              ></path>
              <path
                d="M65.4580908,2.89559502 C64.9130908,2.16159502 64.0290908,1.84959502 63.0980908,2.06259502 C62.8030908,2.13759502 62.5850908,2.21859502 62.3590908,2.32659502 C61.1770908,2.89759502 60.1300908,3.98359502 59.4940908,5.30559502 C58.9430908,6.44759502 58.7750908,7.56659502 59.0380908,8.37459502 C59.3120908,9.23659502 60.1500908,9.89559502 60.8930908,9.60059502 C61.2330908,9.46459502 61.7750908,9.08159502 61.9540908,8.50859502 C62.0690908,8.10959502 62.5910908,7.13959502 62.9710908,6.70659502 C63.5020908,6.09659502 64.3120908,5.55759502 65.1390908,5.36859502 C65.4140908,5.30659502 65.6680908,5.07559502 65.8040908,4.77059502 C66.0370908,4.24359502 65.8980908,3.48859502 65.4580908,2.89559502 Z"
                id="Path"
                fill="#F39100"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Restaurant
