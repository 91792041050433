import React from 'react'

function ReadyMeal ({ className }: {className: string}) {
  return (
    <svg className={className}  width="200" height="200" viewBox="0 0 200 200" fill="none">
      <path d="M181.052 94.769C183.708 79.123 186.71 62.751 189.607 41.665C190.84 32.677 188.779 31.27 186.761 31.05C184.068 30.753 182.241 31.611 179.265 38.249C177.052 43.188 173.101 50.576 170.047 58.235C163.002 75.901 162.241 81.043 161.659 86.336C161.073 91.632 160.302 95.357 166.892 97.333C173.483 99.309 170.544 103.275 166.545 118.371C162.547 133.467 159.876 148.451 158.141 157.819C155.89 169.983 170.916 174.083 174.193 159.026C177.008 146.105 176.953 118.927 181.052 94.769Z" fill="#BACD81"/>
      <path d="M39.7061 82.749C48.9621 74.816 47.9381 65.108 41.8861 41.133C39.5031 31.737 36.3841 31.296 37.7041 38.008C39.2461 45.859 40.0551 53.154 41.0271 59.072C42.2611 66.512 38.0171 67.2 37.0221 62.237C36.3571 58.956 35.5791 55.69 34.5621 50.177C33.4611 44.193 32.7161 40.132 31.8641 36.452C31.0231 32.757 28.5741 32.818 28.9681 36.154C29.7331 42.45 30.1301 45.201 30.5221 50.043C30.8661 54.236 31.8121 60.591 31.9981 62.702C32.5531 68.558 28.0721 69.426 26.8361 62.187C26.0221 57.363 25.7241 55.095 25.0061 50.138C24.2871 45.152 24.1571 41.016 23.7301 37.963C23.2921 34.928 20.6921 32.214 20.7541 38.115C20.8181 44.042 21.1081 47.658 21.3201 56.813C21.5431 65.952 21.5001 68.985 19.8111 69.051C16.6411 69.189 15.2471 61.243 14.9471 54.808C14.6571 48.358 14.6961 40.438 14.8141 36.895C14.9311 33.353 12.9661 33.929 12.1541 38.127C10.5841 46.386 9.61308 54.044 10.1481 62.701C10.9381 75.865 15.1761 83.122 21.5211 85.234C28.0791 87.402 29.7181 96.114 30.0861 107.967C30.1841 111.287 30.1911 114.87 30.1771 118.616C30.1601 121.339 30.1331 124.181 30.1221 127.063C30.0791 152.971 31.9761 168.726 40.0141 169.086C50.8941 169.576 50.5881 159.046 47.5751 147.619C45.7601 140.714 42.8291 125.49 41.2651 117.699C41.1611 117.189 41.0601 116.706 40.9671 116.251C40.4501 113.693 39.7081 110.481 39.0341 107.038C37.1581 97.484 35.6471 86.235 39.7061 82.749Z" fill="#94C11A"/>
      <path d="M171.211 142.878C170.107 143.873 168.707 143.52 167.145 143.52C142.595 143.52 67.1731 143.52 67.1731 143.52C67.1731 143.52 47.7231 143.06 38.3431 143.52C37.7891 143.548 34.7901 143.705 34.3981 143.35C33.8801 142.883 33.9171 141.251 33.9171 140.592C33.9171 139.273 34.2091 136.925 34.2091 135.606C34.2091 134.01 35.2981 132.968 37.5461 132.968C60.6071 132.968 106.609 133.179 129.783 132.968C139.857 132.876 156.473 132.968 165.371 132.968C169.576 132.968 171.623 133.162 171.623 135.606C171.623 136.925 171.673 139.359 171.883 141.136C171.96 141.792 171.728 142.411 171.211 142.878Z" fill="#009738"/>
      <path d="M103.486 67.883C80.28 67.85 58.302 78.59 46.65 99.184C42.45 106.606 38.486 115.203 38.381 123.863C38.33 128.118 37.804 130.042 42.207 130.349C45.059 130.55 47.921 130.433 50.774 130.354C66.754 129.904 82.825 130.469 98.813 130.436C118.522 130.394 138.23 130.557 157.939 130.53C160.34 130.526 166.51 131.647 167.844 128.993C169.167 126.368 167.589 121.192 167.115 118.574C165.418 109.187 161.518 99.738 156.051 91.904C144.247 74.987 123.358 67.911 103.486 67.883Z" fill="#009738"/>
      <path d="M95.208 60.284C95.214 56.327 98.214 52.91 102.302 52.314C104.445 52.001 106.937 52.414 108.741 53.615C110.718 54.929 111.596 57.272 111.757 59.518C112.075 63.933 108.583 68.335 103.887 68.605C101.644 68.735 99.256 67.899 97.589 66.439C95.827 64.894 95.205 62.522 95.208 60.284Z" fill="#009738"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M85.8792 83.385C86.8902 82.606 87.4012 81.885 87.5752 81.291C87.7252 80.77 87.9132 79.734 86.5592 77.246C85.7292 75.716 84.4182 75.06 82.5532 75.236C79.6112 75.513 76.0982 77.839 74.5542 80.529C74.3362 80.908 73.6762 82.062 74.7682 83.759C76.0292 85.717 78.3952 87.035 80.5022 87.033C81.9095 87.0315 83.73 85.3417 84.9509 84.2084C85.3271 83.8592 85.6464 83.5628 85.8792 83.385ZM72.2561 97.9148L72.2592 97.911C75.5272 93.84 76.7492 92.316 73.7632 87.991C73.3272 87.36 72.4102 86.977 70.8482 87.251C66.5572 88.007 61.1832 94.878 57.6762 101.28C52.3232 111.054 51.0342 121.923 54.0192 122.723C57.3622 123.619 61.6552 117.334 63.8762 112.366L63.8968 112.32C65.3822 109.001 66.7838 105.869 68.2242 103.489C69.5771 101.251 71.0009 99.4778 72.2561 97.9148Z" fill="#BACD81"/>
      <path d="M103.794 55.109C103.249 54.375 102.365 54.063 101.434 54.276C101.139 54.351 100.921 54.432 100.695 54.54C99.5132 55.111 98.4662 56.197 97.8302 57.519C97.2792 58.661 97.1112 59.78 97.3742 60.588C97.6482 61.45 98.4862 62.109 99.2292 61.814C99.5692 61.678 100.111 61.295 100.29 60.722C100.405 60.323 100.927 59.353 101.307 58.92C101.838 58.31 102.648 57.771 103.475 57.582C103.75 57.52 104.004 57.289 104.14 56.984C104.373 56.457 104.234 55.702 103.794 55.109Z" fill="#BACD81"/>
    </svg>

  )
}

export default ReadyMeal