import { createModel } from "@rematch/core"
import type { RootModel } from '.'

export interface MessageState {
  message: string  
}

export const message = createModel<RootModel>()({
  state: {
    message: ''
  } as MessageState,
  reducers: {
    setMessage (state, newMessage) {
      return { ...state, message: newMessage }
    },
  },
  effects: (dispatch) => ({
    showMessageToast(newMessage: string) {
      dispatch.message.setMessage(newMessage)
      setTimeout(() => {
        dispatch.message.setMessage("")
      }, 5000)
    }
  }),
})
