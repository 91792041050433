import React, { ReactNode } from 'react'
import Checkmark from '../icons/Checkmark'
import styles from './Checkbox.module.css'

interface Props {
  id: string
  checked: boolean
  label?: string
  isActive?: boolean
  onChange: (id: string) => void,
  children?: ReactNode
}

function Checkbox({
  id,
  checked,
  label,
  isActive,
  onChange,
  children
}: Props) {
  
  return (
    <div style={{ display: isActive ? 'block' : 'none' }}>
      <input
        key={id}
        className={styles.hiddenCheckbox}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(id)} />
      <label className={styles.label} htmlFor={id}>
        <span className={styles.checkbox}>
          {checked && (
            <Checkmark color='#ffff' className={styles.checkmark} />
          )}
        </span>
        {label && <span>{label}</span>}
        {children}
      </label>
    </div>
  )
}

export default Checkbox