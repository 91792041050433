import React from 'react'
import CheckListItem from './CheckListItem'
import { OrderItem } from '../types/Order'

interface Props {
  orderId: string
  items: OrderItem[]
  updateItemStatus: (id: string, status: number) => void
}

function CheckList({ orderId, items, updateItemStatus }: Props) {
  
  return (
    <section>
      <ul>
        {items.map((item) => (
          <CheckListItem updateItemStatus={updateItemStatus} key={`${orderId}-${item.id}`} item={item} />
        ))}
      </ul>
    </section>
  )
}

export default CheckList