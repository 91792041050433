import React from 'react'
import styles from "./PrintOrder.module.css"
import { Order } from '../types/Order'

function PrintOrder ({ order }: { order: Order }) {
  const {
    orderNo,
    loopNumber,
    userName,
    street,
    postNumber,
    city,
    phoneNumber,
    orderItemsFood,
    orderItemsGrocery,
    channelName,
    tableNumber,
  } = order || {}

  function getFormattedPrice (value: number) {
    if (value % 1 !== 0) {
      return `${value.toFixed(2)} kr`
    }
    return `${value} kr`
  }

  function getDeliveryDisplayName () {
    if (order.deliveryBongDisplayName) {
      return order.deliveryBongDisplayName
    }
    if (order.deliveryDisplayName) {
      return order.deliveryDisplayName
    }
    return ""
  }

  return order ? (
    <div className={styles.printContainer}>
      <div className={styles.content}>
        <h2>Order {channelName === "icakiosk" ? loopNumber : orderNo} - {getDeliveryDisplayName()} {tableNumber && (<span>- bord: {tableNumber}</span>)}</h2>
      </div>
      {userName || street || postNumber || city || phoneNumber ? (
        <div>
          <h3>Kund</h3>
          {userName && <p>{userName}</p>}
          {street && <p>{street}</p>}
          {postNumber && city && (
            <p>
              {postNumber} {city}
            </p>
          )}
          {phoneNumber && <p>{phoneNumber}</p>}
        </div>
      ) : null}
      <div>
        <h3>Beställning</h3>
        <ul>
          {orderItemsFood &&
            orderItemsFood.length > 0 &&
            orderItemsFood.map(
              ({ id, amount, name, price, customizationItems }) => (
                <li key={id} className={styles.orderItem}>
                  <p>
                    <span>{amount} st</span> <span>{name}</span>{" "}
                    <span>{getFormattedPrice(price)}</span>
                  </p>
                  {customizationItems &&
                    customizationItems.length > 0 &&
                    customizationItems.map((customization, index) => (
                      <div key={index}>
                        {customization?.customizationType === 2 ? (
                          <span>-</span>
                        ) : customization?.customizationType === 1 ? (
                          <span>+</span>
                        ) : null}
                        <span>{customization?.name}</span>{" "}
                        <span>{getFormattedPrice(customization.price)}</span>
                      </div>
                    ))}
                </li>
              )
            )}
        </ul>
        <ul>
          {orderItemsGrocery &&
            orderItemsGrocery.length > 0 &&
            orderItemsGrocery.map(({ id, amount, name, price }, index) => (
              <li key={index}>
                <p>
                  <span>{amount} st</span> <span>{name}</span>{" "}
                  <span>{getFormattedPrice(price)}</span>
                </p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  ) : null
}
export default PrintOrder
