import React from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { FETCHING } from "../helpers/constants"
import Menu from "../components/Menu"
import OrderHistoryItem from "../components/OrderHistoryItem"
import DatePicker from "../components/DatePicker"
import Arrow from "../icons/Arrow"
import MarketPlaceIcon from "../icons/MarketPlace"
import CloseIcon from "../icons/Close"
import styles from "./OrderHistory.module.css"
import { Dispatch, RootState } from "../models"
import { Order } from "../types/Order"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function OrderHistory ({
  fetchOrders,
  fetchUserStores,
  selectedStore,
  orderHistory,
  status,
}: Props) {
  const currentDate = new Date()
  const initFromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 7
  )
  const [dateFrom, setDateFrom] = React.useState(initFromDate)
  const [dateFromIsVisible, setDateFromIsVisible] = React.useState(false)
  const [dateTo, setDateTo] = React.useState(currentDate)
  const [dateToIsVisible, setDateToIsVisible] = React.useState(false)
  const [activeModal, setActiveModal] = React.useState<string | null>(null)
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [showKioskOrders, setShowKioskOrders] = React.useState(true)
  const [showProntoOrders, setShowProntoOrders] = React.useState(true)
  const { storeId } = useParams()

  React.useEffect(() => {
    if (!selectedStore) {
      fetchUserStores(storeId)
    }
  }, [selectedStore])

  function toggleDatePicker (type: string) {
    if (type === "FROM") {
      setDateFromIsVisible((prev) => !prev)
      setDateToIsVisible(false)
    } else if (type === "TO") {
      setDateToIsVisible((prev) => !prev)
      setDateFromIsVisible(false)
    }
  }

  React.useEffect(() => {
    if (storeId && dateFrom && dateTo) {
      getOrders(initFromDate, currentDate)
    }
  }, [storeId])

  function getOrders (from: Date, to: Date) {
    if (storeId) {
      fetchOrders({
        storeId,
        dateFrom: from.toISOString(),
        dateTo: to.toISOString(),
      })
    } else {
      console.error('Failed to fetch orders: Missing storeId!')
    }
  }

  function onSearch () {
    const toDate = new Date()
    toDate.setDate(dateTo.getDate() + 1)

    getOrders(dateFrom, toDate)
    setDateFromIsVisible(false)
    setDateToIsVisible(false)
  }

  function getIds (index: number, i: number) {
    let prevOrder = orderHistory[index].orders[i - 1] as Order | null
    let nextOrder = orderHistory[index].orders[i + 1] as Order | null

    if (!prevOrder) {
      const prevStep = orderHistory[index - 1]
      if (prevStep) {
        prevOrder = prevStep.orders[prevStep.orders.length - 1]
      } else {
        prevOrder = null
      }
    }
    if (!nextOrder) {
      const nextStep = orderHistory[index + 1]
      if (nextStep) {
        nextOrder = nextStep.orders[0]
      } else {
        nextOrder = null
      }
    }
    const prevId = prevOrder ? prevOrder.id : null
    const nextId = nextOrder ? nextOrder.id : null

    return {
      prevId,
      nextId,
    }
  }

  return (
    <div className={styles.history}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <Link className={styles.backButton} to={`/butik/${storeId}/orders`}>
            <Arrow
              className={styles.backIcon}
              direction="left"
              color="#cf2e05"
            />
            Tillbaka
          </Link>
        </div>
        <div className={styles.datePickers}>
          <span className={styles.datePickerLabel}>Startdatum</span>
          <div className={styles.datePicker}>
            <DatePicker
              isActive={dateFromIsVisible}
              setIsActive={() => toggleDatePicker("FROM")}
              firstDayOfWeek={1}
              onDateSelected={({ date }) => setDateFrom(date)}
              selected={dateFrom}
            />
          </div>
          <span className={styles.datePickerLabel}>Slutdatum</span>
          <div className={styles.datePicker}>
            <DatePicker
              isActive={dateToIsVisible}
              setIsActive={() => toggleDatePicker("TO")}
              firstDayOfWeek={1}
              onDateSelected={({ date }) => setDateTo(date)}
              selected={dateTo}
            />
          </div>
          <button
            onClick={onSearch}
            className={styles.searchButton}
            type="button"
          >
            Ok
          </button>
        </div>
      </header>
      {status === FETCHING ? (
        <div className={styles.loading}>
          <p>Laddar Orderhistorik…</p>
        </div>
      ) : orderHistory.length > 0 ? (
        <>
          {orderHistory.map((day, index) => {
            const { date, orders } = day
            return (
              <section className={styles.section} key={index}>
                <h2 className={styles.sectionHeading}>{date}</h2>
                <ul className={styles.list}>
                  {orders.map((order, i) => {
                    const { prevId, nextId } = getIds(index, i)
                    return (
                      <OrderHistoryItem
                        modalIsActive={order.id === activeModal}
                        setActiveModal={setActiveModal}
                        prevId={prevId}
                        nextId={nextId}
                        key={order.id}
                        item={order}
                      />
                    )
                  })}
                </ul>
              </section>
            )
          })}
        </>
      ) : (
        <div className={styles.noOrders}>
          <p>
            Det gick inte att hitta någon orderhistorik mellan de valda datumen.
          </p>
        </div>
      )}
      <Menu
        isOpen={menuIsOpen}
        updateStatus={setMenuIsOpen}
        filter={{
          showKioskOrders,
          setShowKioskOrders,
          showProntoOrders,
          setShowProntoOrders
        }}
        channelTypes={selectedStore && selectedStore.channelTypes}
      />
      <button
        onClick={() => setMenuIsOpen((prev) => !prev)}
        className={menuIsOpen ? styles.activeMenuButton : styles.menuButton}
        type="button"
      >
        <span className={styles.menuButtonText}>
          {selectedStore && selectedStore?.name ? (
            <>{selectedStore.name}</>
          ) : (
            <>Meny</>
          )}
        </span>
        <div className={styles.menuIcons}>
          <span className={styles.menuIcon}>
            <MarketPlaceIcon color="var(--red)" className={styles.icon} />
          </span>
          <span className={styles.activeMenuIcon}>
            <CloseIcon color="currentColor" className={styles.icon} />
          </span>
        </div>
      </button>
    </div>
  )
}

function mapState (state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
    orderHistory: state.order.orderHistory,
    status: state.order.status,
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchOrders: dispatch.order.fetchOrderHistory,
    fetchUserStores: dispatch.store.fetchUserStores,
  }
}

export default connect(mapState, mapDispatch)(OrderHistory)
