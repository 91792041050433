/* eslint-disable indent */
import React from 'react'
import styles from './DeliveryLabel.module.css'

interface Props {
  type: number | null
  bongTitle: string
  dynamicHeight?: boolean
}

function DeliveryLabel ({ type, bongTitle, dynamicHeight }: Props) {
  const [text, setText] = React.useState('')

  const classNames = `${styles.deliveryLabel} ${dynamicHeight ? styles.dynamicHeight : ''}`

  React.useEffect(() => {
    setText(bongTitle ? bongTitle : getDeliveryLabelBy(type))
  }, [type, bongTitle])

  function getDeliveryLabelBy (type: number | null) {
    switch (type) {
      case 100:
        return 'Hämtas'
      case 200:
        return 'Leverans'
      case 600:
        return 'Äta här'
      case 700:
        return 'Ta med'
      default:
        return ''
    }
  }

  return text && <span className={classNames}>{text}</span>
}

export default DeliveryLabel
