import { emptyGuid } from "../constants"
import { OrderItem } from "../types/Order"

const groupCateringOrderItems = (orderItems: OrderItem[]) => {
  const groupedCateringOrderItems = orderItems.reduce((prev, current) => {
    if (current.variantId !== emptyGuid && current.variantId + current.message in prev) {
      return {
        ...prev,
        [current.variantId + current.message]: {
          ...current,
          amount: prev[current.variantId + current.message].amount + current.quantity,
          ids: [...prev[current.variantId + current.message].ids, { id: current.id, quantity: current.quantity }]
        },
      }
    } else if (
      (current.variantId === emptyGuid || current.variantId === null) &&
      current.name + current.message in prev
    ) {
      return {
        ...prev,
        [current.name + current.message]: {
          ...current,
          amount: prev[current.name + current.message]?.amount + current.quantity,
          ids: [...(prev[current.name + current.message]?.ids || []), { id: current.id, quantity: current.quantity }]
        },
      }
    }
    return {
      ...prev,
      [current.variantId !== emptyGuid
        ? current.variantId + current.message
        : current.name + current.message]: {
        ...current,
        amount: current.quantity,
        ids: [{ id: current.id, quantity: current.quantity }],
      },
    }
  }, {} as { [key: string]: OrderItem })
  return Object.values(groupedCateringOrderItems)
}

export default groupCateringOrderItems
