import React from 'react'

interface IconProps {
  color?: string
  className?: string
}

function User({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M6 23.5v3.75c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25V23.5c0-3.454 3.769-5 8-5 3.867 0 8 1.313 8 5v2H11.082a.252.252 0 0 0-.228.146l-.69 1.5a.25.25 0 0 0 .227.354H25.75a.25.25 0 0 0 .25-.25V23.5c0-4.253-3.926-7-10-7-6.28 0-10 2.813-10 7zm14-14c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4zm2 0a6 6 0 0 0-12 0 6 6 0 0 0 12 0z"/></g></svg>
  )
}

export default User