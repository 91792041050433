import React from 'react'

interface IconProps {
  color: string
  className: string
}

function PdfIcon({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.75 15H7.25C7.112 15 7 14.888 7 14.75V4.25C7 4.112 7.112 4 7.25 4H17.311C17.377 4 17.44 4.026 17.487 4.073L24.927 11.513C24.974 11.56 25 11.623 25 11.689V14.75C25 14.888 24.888 15 24.75 15H23.25C23.112 15 23 14.888 23 14.75V13H16.25C16.112 13 16 12.888 16 12.75V6H9V14.75C9 14.888 8.888 15 8.75 15ZM21.586 11L18 7.414V11H21.586Z" fill={color}/>
      <path d="M21.2127 16.0204H25.8867C25.9657 16.0204 25.9997 16.0544 25.9997 16.1344V17.6964C25.9997 17.7644 25.9657 17.8104 25.8867 17.8104H23.0597V19.0864H25.5097C25.5777 19.0864 25.6237 19.1214 25.6237 19.2014V20.7624C25.6237 20.8314 25.5897 20.8764 25.5097 20.8764H23.0597V23.8854C23.0597 23.9654 23.0257 23.9994 22.9457 23.9994H21.2127C21.1447 23.9994 21.0987 23.9544 21.0987 23.8854V16.1344C21.0987 16.0664 21.1447 16.0204 21.2127 16.0204Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.96 21.4465V23.8855C7.96 23.9545 7.926 23.9995 7.847 23.9995H6.114C6.046 23.9995 6 23.9545 6 23.8855V16.1345C6 16.0665 6.046 16.0205 6.114 16.0205H9.135C10.844 16.0205 11.927 17.2865 11.927 18.7455C11.927 20.1925 10.844 21.4465 9.135 21.4465H7.96ZM9.032 17.8105H7.96V19.6575H9.032C9.658 19.6575 9.955 19.2235 9.955 18.7455C9.955 18.2545 9.658 17.8105 9.032 17.8105Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.332 16.1347C13.332 16.0667 13.378 16.0207 13.446 16.0207H16.137C18.428 16.0207 20 17.7877 20 20.0107C20 22.2337 18.438 23.9997 16.137 23.9997H13.446C13.366 23.9997 13.332 23.9537 13.332 23.8857V16.1347ZM16.147 22.2107C17.355 22.2107 18.04 21.0477 18.04 20.0107C18.04 18.9727 17.355 17.8107 16.147 17.8107H15.293V22.2107H16.147Z" fill={color}/>
      <path d="M23.252 25.0029H24.75C24.904 25.0029 25.022 25.1439 24.996 25.2959C24.805 26.4429 24.608 27.4239 24.522 27.8079C24.498 27.9229 24.397 28.0029 24.283 28.0029H6.788C6.628 28.0029 6.513 27.8629 6.543 27.7079C6.641 27.2519 6.83 26.3039 7.012 25.2139C7.032 25.0939 7.137 25.0029 7.259 25.0029H8.749C8.75139 25.0029 8.75354 25.0022 8.7558 25.0015C8.75827 25.0007 8.76086 24.9999 8.764 24.9999C8.7665 24.9999 8.76875 25.0007 8.771 25.0014C8.77325 25.0021 8.7755 25.0029 8.778 25.0029C8.916 25.0109 9.026 25.1229 9.026 25.2629L8.908 26.0029H22.843L22.976 25.2629C22.976 25.1229 23.086 25.0109 23.224 25.0029C23.2264 25.0029 23.2283 25.0022 23.2303 25.0015C23.2325 25.0007 23.2349 24.9999 23.238 24.9999C23.2405 24.9999 23.2428 25.0007 23.245 25.0014C23.2473 25.0022 23.2495 25.0029 23.252 25.0029Z" fill={color}/>
    </svg>
  )
}

export default PdfIcon