import { AnonymousOrder, Order } from "../types/Order"

interface OrdersGroupedByStatus<T> {
  waiting: T[]
  beingPrepared: T[]
  readyForDelivery: T[]
  pickedUp: T[]
  delivered: T[]
  unknown: T[]
}

export function groupByStatus<T>(orders: Order[] | AnonymousOrder[]): OrdersGroupedByStatus<T> {
  const ordersWithStatus = orders.reduce((prev, current) => {
    let statusProp: 'waiting' | 'beingPrepared' | 'readyForDelivery' | 'pickedUp' | 'delivered' | 'unknown'
    let retObj: OrdersGroupedByStatus<T> | undefined
    const orderStatus = current?.status || 0
    
    switch (orderStatus) {
    case 0:
      statusProp = 'waiting'
      break
    case 1:
      statusProp = 'beingPrepared'
      break
    case 2:
      statusProp = 'readyForDelivery'
      break
    case 3:
      statusProp = 'pickedUp'
      break
    case 4:
      statusProp = 'delivered'
      break
    default:
      statusProp = 'unknown'
      break
    }
    
    if(Object.prototype.hasOwnProperty.call(prev, statusProp)) {
      retObj = {
        ...prev,
        [statusProp]: [...prev[statusProp as keyof typeof prev], current]
      }
    } else {
      retObj = {
        ...prev,
        [statusProp]: [current]
      }
    }
    return retObj
  }, {} as OrdersGroupedByStatus<T>)
  return ordersWithStatus || {}
}
