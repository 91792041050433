import { createModel } from "@rematch/core"
import { fetchFrom } from '../utils/fetchData'
import { FETCHING, IDLE, ERROR } from "../helpers/constants"
import type { User } from "../types/User"
import type { Status } from "../types/Status"
import type { RootModel } from '.'
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage"

export interface UserState {
  current: User | null
  status: {
    message: string,
    status: Status
  }
  
}

export const user = createModel<RootModel>()({
  state: {
    current: getUserFromLocalStorage(),
    status: {
      message: '',
      status: IDLE
    }
  } as UserState,
  reducers: {
    startFetching(state) {
      return { ...state, status: { message: '', status: FETCHING } }
    },
    setStatus(state, payload) {
      return { ...state, status: payload }
    },
    setUser(state, payload: User | null) {
      return { ...state, status:  { message: '', status: IDLE }, current: payload }
    },
  },
  effects: (dispatch) => ({
    async login(payload) {
      dispatch.user.startFetching()
      const { username, password } = payload

      try {
        const user = await fetchFrom<User>('/api/users/authenticate', {
          method: 'POST',
          body: JSON.stringify({ username, password })
        })

        if (user) {
          localStorage.setItem('user', JSON.stringify(user))
          dispatch.user.setUser(user)
        }
      } catch (error: any) {
        dispatch.user.setStatus({ message: error.message, status: ERROR })
      }
    },
    async logout() {
      localStorage.removeItem('user')
      dispatch.user.setUser(null)
    }
  }),
})
