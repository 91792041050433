import React from 'react'

interface IconProps {
  color?: string
  className: string
}

function Info({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M7 16c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm-2 0c0 6.065 4.935 11 11 11s11-4.935 11-11S22.065 5 16 5 5 9.935 5 16zm10.16-2.424c-.098 0-.14.056-.14.14v7.144c0 .084.056.14.14.14h1.681c.098 0 .14-.056.14-.14v-7.144c0-.098-.056-.14-.14-.14h-1.68zm-.28-1.709c0 .616.505 1.092 1.121 1.092s1.121-.476 1.121-1.092c0-.616-.505-1.093-1.121-1.093s-1.121.477-1.121 1.093z"/></g></svg>
  )
}

export default Info