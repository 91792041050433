import React from 'react'

interface IconProps {
  color: string
  className: string
}

function SwapIcon({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.84675 15.5021C3.77325 15.5756 3.77325 15.6941 3.84675 15.7676L7.2945 19.2146C7.36725 19.2881 7.4865 19.2881 7.55925 19.2146L8.355 18.4188C8.42775 18.3453 8.42775 18.2268 8.355 18.1533L6.58575 16.3848H17.8125C17.916 16.3848 18 16.3001 18 16.1973V15.0723C18 14.9688 17.916 14.8848 17.8125 14.8848H6.58575L8.355 13.1156C8.42775 13.0421 8.42775 12.9236 8.355 12.8501L7.55925 12.0551C7.4865 11.9816 7.36725 11.9816 7.2945 12.0551L3.84675 15.5021Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M20.1532 8.49785C20.2267 8.42435 20.2267 8.30585 20.1532 8.23235L16.7055 4.78535C16.6327 4.71185 16.5135 4.71185 16.4407 4.78535L15.645 5.5811C15.5722 5.6546 15.5722 5.7731 15.645 5.8466L17.4142 7.6151L6.1875 7.6151C6.084 7.6151 6 7.69985 6 7.8026V8.9276C6 9.0311 6.084 9.1151 6.1875 9.1151H17.4142L15.645 10.8843C15.5722 10.9578 15.5722 11.0763 15.645 11.1498L16.4407 11.9448C16.5135 12.0183 16.6327 12.0183 16.7055 11.9448L20.1532 8.49785Z" fill={color}/>
    </svg>


  )
}

export default SwapIcon