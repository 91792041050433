import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Email({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M16 16.354L7.012 9h17.976L16 16.354zM4.254 7A.254.254 0 0 0 4 7.254v17.493c0 .14.114.253.254.253h1.492c.14 0 .254-.113.254-.253V9.464l9.842 8.053a.247.247 0 0 0 .316 0L26 9.464v13.283c0 .14-.113.253-.254.253H8.996a.252.252 0 0 0-.224.138l-.75 1.5a.25.25 0 0 0 .224.362h19.5a.253.253 0 0 0 .254-.253V7.254A.254.254 0 0 0 27.746 7H4.254z"/></g></svg>
  )
}

export default Email