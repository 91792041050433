export function authHeader() {
  const headers = {} as { [key:string]: string }
  const user = localStorage.getItem("user")

  if (user) {
    const { token } = JSON.parse(user)

    if (token) {
      headers.Authorization = "Bearer " + token
    }
  }
  return headers
}
