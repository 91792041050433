import { OrderUpdateOperation } from "../types/Order"
import { OrderDifference } from "./isOrderUpdated"

/**
 * Creates operations for updating item quantities and store comment
 * 
 * @param {OrderDifference[]} orderUpdates - An array of objects representing the differences between the current and new order states.
 * @param {string | null} storeComment - A string containing a comment to be added to the order, or null if no comment is to be added.
 * @returns {Promise<OrderUpdateOperation[]>} - An array of OrderUpdateOperation objects representing the operations to be performed on the order.
 */

const createEditOrderOperations = async (
  orderUpdates: OrderDifference[], 
  storeComment: string | null
): Promise<OrderUpdateOperation[]> => {

  let operations: OrderUpdateOperation[] = []

  // Loop through each order update
  for (const update of orderUpdates) {
    // Calculate the total quantity from the update
    const quantity = update.ids.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)
    // Calculate the change in quantity
    const change = quantity - update.newQuantity

    if (change === 0) break

    // If the change is negative, update the quantity of the first item
    if (change < 0) {
      const changeValue = update.ids[0].quantity + Math.abs(change)
      operations = [...operations, {
        requestOperationType: 'UpdateQuantity',
        value: changeValue.toString(),
        orderItemId: update.ids[0].id
      }]
      // If the change is positive, distribute the change across items
    } else if (change > 0) {
      let newChangeValue = change
      while (newChangeValue > 0) {
        for (const item of update.ids) {
          if (newChangeValue <= item.quantity) {
            operations = [...operations, {
              requestOperationType: 'UpdateQuantity',
              value: (item.quantity - newChangeValue).toString(),
              orderItemId: item.id
            }]
            newChangeValue = 0
            break
          } else {
            operations = [...operations, {
              requestOperationType: 'UpdateQuantity',
              value: (0).toString(),
              orderItemId: item.id
            }]
            newChangeValue -= item.quantity
          }
        }
      }
    }
  }

  // If there's a store comment, add it as an operation
  if (storeComment) {
    operations = [...operations, {
      requestOperationType: 'UpdateComment',
      value: storeComment,
    }]
  }

  return operations
}

export default createEditOrderOperations