import React from 'react'
import { connect } from 'react-redux'
import ArrowIcon from '../icons/Arrow'
import TimeIcon from '../icons/Time'
import ReportIcon from '../icons/Report'
import MarketPlaceIcon from '../icons/MarketPlace'
import styles from './Menu.module.css'
import { ChannelType } from '../types/Store'
import { Dispatch, RootState } from '../models'
import SwapIcon from '../icons/Swap'
import { applicationVersion } from '../constants'


type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
interface CateringMenuProps extends Props {
  isOpen: boolean
  updateStatus: (value: boolean) => void
  channelTypes: ChannelType[]
}

function CateringMenu ({
  isOpen = false,
  updateStatus,
  selectedStore,
  resetStore,
  logout,
  channelTypes
}: CateringMenuProps) {

  return (
    <>
      <div className={isOpen ? styles.activeMenu : styles.menu}>
        <div className={styles.menuHeading}>
          <span className={styles.storeNameWithButton}>
            {selectedStore?.name}
          </span>
        </div>
        <div className={styles.menuItem}>
          <a
            className={styles.menuItemLink}
            href={`/butik/${selectedStore?.id}/catering/historik`}
          >
            <div className={styles.menuItemText}>
              <TimeIcon
                className={styles.orderHistoryIcon}
                color='var(--text)'
              />
              <span>Orderhistorik</span>
            </div>
            <div className={styles.historyIcon}>
              <ArrowIcon
                direction='right'
                color='var(--red)'
                className={styles.orderHistoryArrow}
              />
            </div>
          </a>
        </div>
        <div className={styles.menuItem}>
          <a
            className={styles.menuItemLink}
            href={`/butik/${selectedStore?.id}/catering/rapport`}
          >
            <div className={styles.menuItemText}>
              <ReportIcon
                className={styles.orderReportIcon}
                color='currentColor'
              />
              <span>Orderrapport</span>
            </div>
            <div className={styles.historyIcon}>
              <ArrowIcon
                direction='right'
                color='var(--red)'
                className={styles.orderHistoryArrow}
              />
            </div>
          </a>
        </div>
        <div className={styles.menuItem}>
          <a
            className={styles.menuItemLink}
            href={`/butik/${selectedStore?.id}/catering`}
          >
            <div className={styles.menuItemText}>
              <ArrowIcon
                direction='left'
                color='currentColor'
                className={styles.backArrow}
              />
              <span style={{ marginLeft: '0.25rem' }}>Tillbaka till start</span>
            </div>
          </a>
        </div>
        {channelTypes && channelTypes.some(obj => obj.headerValue === 'icakiosk' || obj.headerValue === 'icapronto') && (
          <div className={styles.menuItem}>
            <a
              className={styles.menuItemLink}
              href={`/butik/${selectedStore?.id}/orders`}
            >
              <div className={styles.boldText}>
                <SwapIcon
                  color='currentColor'
                  className={styles.refreshIcon}
                />
                <span>Byt tjänst till restaurang</span>
              </div>
            </a>
          </div>
        )}
        <div className={styles.menuItem}>
          <button
            className={styles.boldButton}
            onClick={() => { resetStore(); logout() }}
            type='button'
          >
            <div className={styles.boldText}>
              <MarketPlaceIcon className={styles.logoutIcon} color='currentColor' />
              <span>Logga ut</span>
            </div>
          </button>
        </div>
        <p className={styles.appVersion}>Applikationsversion: {applicationVersion}</p>
      </div>
      <div
        className={isOpen ? styles.activeOverlay : styles.overlay}
        onClick={() => updateStatus(false)}
      ></div>
    </>
  )
}

function mapState (state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    selectStore: dispatch.store.selectStore,
    resetStore: dispatch.store.resetStore,
    logout: dispatch.user.logout
  }
}

export default connect(mapState, mapDispatch)(CateringMenu)
