import { User } from "../types/User"

const getUserFromLocalStorage = () => {
  const localStorageString = localStorage.getItem('user')
  if(localStorageString !== null) {
    return JSON.parse(localStorageString) as User
  }
  return null
}

export default getUserFromLocalStorage