import React from "react"
import { connect } from "react-redux"
import { FETCHING } from "../helpers/constants"
import styles from "./SelectStore.module.css"
import { useNavigate } from "react-router-dom"
import { Dispatch, RootState } from "../models"
import { Store } from "../types/Store"
import logError from "../utils/logging"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function SelectStore({
  stores,
  status,
  fetchUserStores,
  selectStore,
}: Props) {
  const [loading, setLoading] = React.useState(true)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (loading === true) {
      fetchUserStores()
      setLoading(false)
    }
  }, [loading])

  function onSelectStore(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, store: Store) {
    e.preventDefault()
    selectStore(store)
    navigate(`/butik/${store.id}/tjanster`)
  }

  return loading === true || status === FETCHING ? (
    <>Laddar…</>
  ) : (
    <div className={styles.stores}>
      <h1 className={styles.heading}>Välj din butik</h1>
      <ul className={styles.storeList}>
        {stores.map((store) => (
          <li className={styles.storeItem} key={store.id}>
            <a
              className={styles.link}
              onClick={(e) => onSelectStore(e, store)}
              href={`/butik/${store.id}/orders`}
            >
              <h2 className={styles.cardHeading}>{store.name}</h2>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

function mapState(state: RootState) {
  return {
    status: state.store.status,
    stores: state.store.userStores,
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    fetchUserStores: dispatch.store.fetchUserStores,
    selectStore: dispatch.store.selectStore,
  }
}

export default connect(mapState, mapDispatch)(SelectStore)
