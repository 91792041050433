import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Contact({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M22.748 24.848a.502.502 0 0 1-.709 0l-3.19-3.19.73-.724c.453-.428 1.212-1.145 1.226-2.139.006-.384-.105-.956-.66-1.512l-5.429-5.429c-.555-.554-1.128-.664-1.51-.658-.995.013-1.712.772-2.14 1.225l-.725.73-3.19-3.19a.502.502 0 0 1 0-.71L8.215 8.19c.07-.076 1.105-1.155 2.947-1.183 1.933-.03 4.042 1.083 6.268 3.31l4.255 4.253c2.226 2.227 3.34 4.335 3.309 6.27-.027 1.84-1.107 2.874-1.153 2.919l-1.093 1.09zm-3.9-15.95c-2.647-2.647-5.264-3.958-7.78-3.896-2.701.066-4.242 1.733-4.305 1.804l-1.03 1.028a2.508 2.508 0 0 0 0 3.544l3.368 3.368a1.756 1.756 0 0 0 2.48 0l.942-.948c.143-.152.517-.547.701-.595.004.009.033.028.075.07l5.428 5.428c.063.064.078.097.079.098-.033.137-.448.53-.605.678l-.947.941a1.757 1.757 0 0 0 0 2.481l3.367 3.368a2.508 2.508 0 0 0 3.545 0l1.063-1.065c.035-.029 1.703-1.57 1.769-4.27.062-2.516-1.25-5.134-3.895-7.78l-4.254-4.254z"/></g></svg>
  )
}

export default Contact