import React from 'react'
import ContactIcon from '../icons/User'
import ToggleIcon from '../icons/Angle'
import styles from './Contact.module.css'
import ContactInfo from './ContactInfo'

interface Props {
  name: string
  street: string
  city: string
  phoneNumber: string
  email: string
  deliveryType: string
  deliveryFee: number
}

function Contact({ name, phoneNumber, street, city, email, deliveryType, deliveryFee }: Props) {
  const [activeContactDetails, setActiveContactDetails] = React.useState(false)
  const address = street && city ? `${street}, ${city}` : null

  function onToggle() {
    setActiveContactDetails((prev) => !prev)
  }

  return (
    <div className={styles.contact}>
      <div className={styles.contactContent}>
        <button onClick={onToggle} className={styles.contactButton} type='button'>
          <div className={styles.contactButtonText}>
            <ContactIcon color='var(--grayDark)' className={styles.contactIcon} />
            <span>{name}</span>
          </div>
          <span className={activeContactDetails ? styles.activeToggleContactIcon : styles.toggleContactIcon}>
            <ToggleIcon className={styles.toggleIcon} direction='down' color='var(--text)' />
          </span>
        </button>
        <div className={activeContactDetails ? styles.activeContactCard : styles.contactCard}>
          <ContactInfo 
            phoneNumber={phoneNumber}
            email={email} 
            address={address}
            deliveryType={deliveryType}
            deliveryFee={deliveryFee}
          />
        </div>
      </div>
    </div>
  )
}

export default Contact
