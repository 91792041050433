import { Order } from "../types/Order"
/**
 * 
 * @param order 
 * @returns order with calculated values for newValue and oldValue for operations on orderHistory 
 */
export function getOrderHistory (order: Order) {
  if (order.orderHistory && order.orderHistory?.length > 0) {
    let items = [...order.orderItemsCatering]
    let item
    return {
      ...order,
      orderHistory: order.orderHistory.reverse().map((history) => {
        // use reverse to get the latest operations first to be able to calculate the correct values from latest to oldest.
        return {
          ...history,
          operations: history.operations.map((operation) => {
            if (operation.operationType === 'UpdateQuantity') {
              // Find the item that has the operations orderItemId within its associated ids
              item = items.find((item) => item.ids.some(id => id.id === operation.orderItemId))
              if (item && operation.oldValue) {
                const calculated = {
                  ...operation,
                  calculatedNewValue: item.amount,
                  calculatedOldValue: item.amount + (parseInt(operation.oldValue) - parseInt(operation.newValue)),
                  date: history.date,
                  name: item.name,
                  variantName: item.variantName,
                  // use the id of the item found with associated ids as the groupId to group operations that affect the same item.
                  groupId: item.id
                }
                items = items.map((item) => {
                  // find the item that has the operations orderItemId within its associated ids and update the amount with the difference between the old and new value to get historical amount on next iteration.
                  if (item.ids.some(id => id.id === operation.orderItemId)) {
                    return { ...item, amount: item.amount + (parseInt(operation.oldValue as string) - parseInt(operation.newValue)) }
                  } else {
                    return item
                  }
                })
                return calculated
              } else {
                return operation
              }
            } else {
              return operation
            }
          })
        }
      }).flat()
    }
  } else {
    return order
  }
}