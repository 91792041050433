import React from 'react'
import TimerIcon from '../icons/Timer'
import styles from './HistoryTimer.module.css'

function Timer({ createdAt }: {createdAt: string}) {
  const [time, setTime] = React.useState('')

  React.useEffect(() => {
    calculateTime()
    const timer = setInterval(() => {
      calculateTime()
    }, 10000)
    return () => clearInterval(timer)
  }, [createdAt])

  function calculateTime() {
    const created = new Date(createdAt).getTime()
    const now = new Date().getTime()
    const diff = now - created

    const hours = Math.floor(diff / 1000 / 60 / 60)
    const minutes = Math.floor((diff - (hours * 1000 * 60 * 60)) / 1000 / 60)
    const formattedHours  = hours < 10 ? `0${hours}` : hours
    const formattedMinutes  = minutes < 10 ? `0${minutes}` : minutes

    setTime(`${formattedHours}:${formattedMinutes}`)
  }


  return (
    <span className={styles.time}>
      <TimerIcon className={styles.icon} color="currentColor" />
      {time}
    </span>
  )  
}

export default Timer