import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web'
import { appInsights } from './applicationInsights'

const logError = (error: Error, customProperties?: ICustomProperties) => {
  const environment = import.meta.env.VITE_ENV
  if((environment === 'production' || environment === 'staging' || environment === 'testing')) {
    appInsights.trackException({ error, severityLevel: SeverityLevel.Error }, { ...customProperties, userAgent: window.navigator.userAgent })
  }
  console.error(error, { customProperties })
}

export default logError