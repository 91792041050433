import React from "react"
import {
  HubConnection,
  HubConnectionBuilder,
  JsonHubProtocol,
  HttpTransportType,
  LogLevel,
} from "@microsoft/signalr"

type ListenT = {
  topic: string
  callback: (...args: any[]) => void
}

interface SignalRState {
  connection: HubConnection | undefined
  connected: boolean
  error: boolean
  errorCounter: number
  setErrorCounter: (value: number) => void
  listen: (param: ListenT[]) => Promise<void>
  stopListen: (topic: string) => Promise<void>
  connect: () => void
  disconnect: () => void
  reset: () => Promise<void>
}

const protocol = new JsonHubProtocol()
const transport = HttpTransportType.WebSockets
const options = {
  transport,
  logMessageContent: true,
  logger: LogLevel.Information,
  // withCredentials: false,
}

const builder = hubBuilder()
builder.serverTimeoutInMilliseconds = 18000

function hubBuilder () {
  try {
    return new HubConnectionBuilder()
      .withUrl("/MessageHub", options)
      .withHubProtocol(protocol)
      .withAutomaticReconnect([5000, 5000, 5000, 5000, 5000])
      .build()
  } catch (error) {
    return {} as HubConnection
  }
}

const useSignalR = (): SignalRState => {
  const connection: HubConnection = builder
  const [connected, setConnected] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [errorCounter, setErrorCounter] = React.useState(0)

  const handleErrorState = React.useCallback(
    async (state: boolean) => {
      setErrorCounter((prev) => (state ? prev + 1 : 0))
      setError(() => {
        return state
      })
    },
    [setErrorCounter, setError]
  )

  const startConnection = React.useCallback(async () => {
    if (connection && connection?.state === "Disconnected") {
      if (connection.connectionId === null) {
        try {
          await connection.start()
          setConnected(true)
          console.log("Connected to hub")
        } catch (error) {
          console.log("Failed to connect to hub: ", error)
          setTimeout(() => startConnection(), 10000)
        }
      }
    }
  }, [connection])

  const connect = React.useCallback(() => startConnection(), [startConnection])
  const disconnect = React.useCallback(() => setConnected(false), [])

  const listen = React.useCallback(
    async (topics: ListenT[]) => {
      if (connection.state !== "Disconnected") {
        for (const topic of topics) {
          connection?.on(topic.topic, (res) => topic.callback(res))
        }
      }
    },
    [connection]
  )

  React.useEffect(() => {
    connection.off("TerminalConnectionStatus")
    listen([
      {
        topic: "TerminalConnectionStatus",
        callback: async (res) => {
          const jsonRes = JSON.parse(res)
          console.log("Terminal connection status: ", jsonRes.connected)
          const connectionStatus = !jsonRes.connected
          await handleErrorState(connectionStatus)
        },
      },
    ])
  }, [connection, handleErrorState, listen])

  const stopListen = React.useCallback(
    async (topic: string) => {
      connection.off(topic)
    },
    [connection]
  )

  const reset = React.useCallback(async () => {
    if (connection.state !== "Disconnected" && connection.state !== 'Connecting' && !connected) {
      connection?.off("onOrderCreatedMessage")
      connection?.off("onOrderStatusMessage")
      connection?.off("onOrderItemStatusMessage")
      await connection.stop()
      console.log("Disconnected from hub")
    }
  }, [connected, connection])

  return {
    connection,
    connected,
    error,
    errorCounter,
    setErrorCounter,
    listen,
    connect,
    stopListen,
    disconnect,
    reset,
  }
}

export default useSignalR
