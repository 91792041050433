import { emptyGuid } from "../constants"
import { CateringOrderItem, Order } from "../types/Order"

export const groupProductionList = (orders: Order[] ) => {
  const groupedItems: CateringOrderItem[] = []
  const groupedByProductName: CateringOrderItem[]  = []
  let foundItem: CateringOrderItem | undefined

  orders.forEach((order) => {
    order.orderItemsCatering.forEach((item) => {
      if (item.message) {
        groupedItems.push({
          ...item,
          productName: item.name,
          productComment: item.message,
          orderComment: order.restaurantMessage,
          storeComment: order.storeComment,
          deliveryDate: order.deliveryDate,
          orderHistory: order.orderHistory,
          orders: [
            {
              ...order,
              orderNumber: order.orderNo,
              amount: item.amount,
            }
          ]
        } as CateringOrderItem)
      } else {
        if (
          (foundItem = groupedItems.find(
            (i) =>
              i.variantId === item.variantId &&
              i.variantId !== emptyGuid &&
              i.orderComment === order.restaurantMessage &&
              i.productComment === item.message &&
              i.storeComment === order.storeComment &&
              i.deliveryDate === order.deliveryDate
          ))
        ) {
          foundItem.amount += item.amount
          foundItem.orders = [
            ...foundItem.orders,
            {
              orderNumber: order.orderNo,
              amount: item.amount,
              ...order
            }
          ]
        } else {
          if (
            (foundItem = groupedItems.find(
              (i) =>
                i.variantId === emptyGuid &&
                i.productName === item.name &&
                i.orderComment === order.restaurantMessage &&
                i.storeComment === order.storeComment &&
                i.deliveryDate === order.deliveryDate &&
                i.orderHistory === order.orderHistory
            ))
          ) {
            foundItem.amount += item.amount
            foundItem.orders = [
              ...foundItem.orders,
              {
                orderNumber: order.orderNo,
                amount: item.amount,
                ...order
              }
            ]
          } else {
            groupedItems.push({
              productName: item.name,
              productComment: item.message as string,
              orderComment: order.restaurantMessage,
              storeComment: item.storeComment,
              deliveryDate: order.deliveryDate,
              orderHistory: order.orderHistory,
              products: [],
              orders: [
                {
                  orderNumber: order.orderNo,
                  amount: item.amount,
                  ...order
                }
              ],
              ...item
            })
          }
        }
      }
    })
  })

  groupedItems.forEach((item) => {
    if (
      groupedByProductName.some(
        (variant) => variant.productName === item.productName
      )
    ) {
      const variant = groupedByProductName.find(
        (i) => i.productName === item.productName
      )
      if(variant) {
        variant.amount += item.amount
        variant.products.push(item)
      }
    } else {
      groupedByProductName.push({
        ...item,
        products: [item],
      })
    }
  })

  return groupedByProductName 
}