import { PrintedByUserWithType } from "../types/Order"

const getReprintStatusOrders = (printedByUser: string[], printedByUsersWithType: PrintedByUserWithType[], userId: string, orderLastChanged: string) => {
  const printAction = printedByUsersWithType?.find(print => 
    print.actions?.some(action => action.type === "IncomingOrders" && print.userId === userId)
  )?.actions?.find(action => action.type === "IncomingOrders")

  if (orderLastChanged && 
    ((printAction && printAction.printedDateTime < orderLastChanged) 
    || (!printAction && printedByUser.includes(userId as string)))) {
    return true
  } else {
    return false
  }
}

export default getReprintStatusOrders