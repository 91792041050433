import React from 'react'
import { CateringOrder } from '../types/Order'

interface Props {
  filteredOrders: CateringOrder[]
  dateFrom: Date
  dateTo: Date
  storeName?: string
}

function PrintCateringReport({ filteredOrders, dateFrom, dateTo, storeName }: Props) {
  const styles = {
    page: {
      color: 'black',
      fontFamily: 'Arial',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    heading: {
      fontFamily: 'Arial',
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '1rem',
      marginTop: '1.5rem',
    },
    boldText: {
      fontWeight: '700',
      marginTop: '1rem',
    },
    box: {
      border: 'solid 1px #DADADA',
      borderRadius: '0.25rem',
      width: 'fit-content',
      padding: '0.375rem 1.125rem',
      margin: '1.625rem 0rem',
      fontWeight: 700,
      fontSize: '0.75rem',
    },
    borderBottom: {
      borderBottom: 'solid 1px #DADADA',
      marginBottom: '1rem',
      maxWidth: '40rem',
      paddingBottom: '1.375rem',
    },
    listItem: {
      listStyle: 'inside',
      marginLeft: '1rem',
    },
    productComment: {
      paddingLeft: '2.2rem',
    },
  } as {[keyof:string]: React.CSSProperties}

  return (
    filteredOrders.length > 0 && (
      <div style={styles.page}>
        <header>
          <p>
            Orderrapport för {storeName}, perioden {dateFrom.toLocaleDateString('sv-SE')} -{' '}
            {dateTo.toLocaleDateString('sv-SE')}
          </p>
          <p>Sammanställt {new Date().toLocaleDateString('sv-SE')}</p>
        </header>
        {filteredOrders.map((day, index) => {
          const { date, orders } = day
          return (
            <section key={index}>
              <h1 style={styles.heading}>Leveransdag: {date}</h1>
              {orders.map((order, i) => {
                return (
                  <div key={`order${i}`} style={styles.borderBottom}>
                    <span style={styles.box}>{order.orderNo}</span>
                    <p style={styles.boldText}>Leverans</p>
                    <p>Leveranssätt: {order.deliveryDisplayName}</p>
                    <p>
                      {order.deliveryTimeSlot ? 'Leveransdag och tid: ' : 'Leveransdag: '}
                      {order.deliveryDate} {order.deliveryTimeSlot ? `, ${order.deliveryTimeSlot}` : ''}
                    </p>
                    <p style={styles.boldText}>Kunduppgifter</p>
                    <p>Namn: {order.userName}</p>
                    {order.street && order.postNumber && order.city && (
                      <p>
                        Adress: {order.street}, {order.postNumber} {order.city}
                      </p>
                    )}
                    <p>Telefonnummer: {order.phoneNumber}</p>
                    <p>Mail: {order.email}</p>
                    {order.restaurantMessage && <p>Kundkommentar: {order.restaurantMessage}</p>}
                    <p style={styles.boldText}>Beställning</p>
                    <ul>
                      {order.orderItemsCatering.map((o, index) => {
                        return (
                          <span key={`product${index}`}>
                            <li style={styles.listItem}>
                              {o.amount} st {o.name}
                              {o.variantName && `, ${o.variantName.replaceAll('%', '')}`}
                            </li>
                            {o.message && <p style={styles.productComment}>Meddelande från kund: {o.message}</p>}
                          </span>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </section>
          )
        })}
      </div>
    )
  )
}
export default PrintCateringReport
