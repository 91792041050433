import React, { SetStateAction } from 'react'
import { formatVariant } from "../helpers/formatVariant"
import styles from "./CateringItemDetail.module.css"
import ReadyMealIcon from "../icons/ReadyMeal"
import { getFormattedPrice } from "../helpers/formatPrice"
import { OrderChange, OrderHistoryOperation, OrderItem } from "../types/Order"

interface Props {
  item: OrderItem
  orderHistory?: OrderChange[]
  editMode?: boolean
  setError?: React.Dispatch<SetStateAction<{ errorText: string; isError: boolean; }>>
  error?: boolean
  onAmountChange?: (localAmount: number | string, itemId: string) => void 
}

function CateringItemDetail({ item, orderHistory, editMode, setError, error, onAmountChange }: Props) {
  const { name, imageUrl, amount, priceGross, variantName, message } = item || {}
  const variants = formatVariant(variantName)
  const [localAmount, setLocalAmount] = React.useState<number | ''>('')
  const [oldAmount, setOldAmount] = React.useState<string>('')

  React.useEffect(() => {
    setLocalAmount(item.amount)
  }, [item.amount, editMode])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError && setError({ errorText: '', isError: false })
    setLocalAmount(e.target.value !== '' ? parseInt(e.target.value) : '')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.keyCode === 189 || e.keyCode === 40 ) {
      e.preventDefault()
    }
  }

  const handleInputBlur = () => {
    onAmountChange && onAmountChange(localAmount && +localAmount, item.id)
  }

  React.useEffect(() => {
    if (orderHistory) {
      const foundOperation = orderHistory.reduce<OrderHistoryOperation | null>((acc, history) => {
        if (acc) return acc
        return history.operations.findLast(operation => 
          item.ids?.some(id => id.id === operation.orderItemId)
        ) || null
      }, null)
     
      if (foundOperation) {
        setOldAmount(foundOperation.calculatedOldValue as string)
      }
    }
  }, [orderHistory])
   
  return (
    <div className={styles.item}>
      {imageUrl ? (
        <div className={styles.image}>
          <img className={styles.img} alt="" src={imageUrl} />
        </div>
      ) : (
        <div className={styles.placeholderImage}>
          <ReadyMealIcon className={styles.readyMealIcon} />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.itemDetails}>
          <h3 className={styles.name}>{name}</h3>
          {variants && variants.length > 0 ? (
            <ul>
              {variants.map((variant: string, index: number) => (
                <li className={styles.variantRow} key={index}>
                  {variant}
                </li>
              ))}
            </ul>
          ) : null}
          {message ? (
            <p>
              <span className={styles.messageLabel}>
                Meddelande till butik:
              </span>{" "}
              {message}
            </p>
          ) : null}
        </div>
        <div className={styles.priceDetails}>
          <span className={styles.oldAmount}>{oldAmount ? `${oldAmount} st` : ''}</span>
          {!editMode && <span>{amount} st</span>}
          <span className={styles.pricePerItem}>{priceGross} kr/st</span>
          {editMode && (
            <span className={styles.inputContainer}>
              <input
                type="number" 
                value={localAmount as number}
                className={error ? styles.inputError : styles.input} 
                onChange={handleInputChange}
                onBlur={handleInputBlur} 
                onKeyDown={handleKeyDown}
                onWheel={ e => e.currentTarget.blur()}
              />
            </span>
          )}
          <span className={styles.price}>
            {localAmount && getFormattedPrice(Math.round(priceGross * localAmount * 100) * 0.01)}
            {localAmount === '' && '0 kr'}
          </span> 
        </div>
      </div>
    </div>
  )
}
export default CateringItemDetail
