import React from 'react'

interface IconProps {
  direction: 'up' | 'left' | 'right' | 'down'
  color: string
  className: string
}

function Angle({ direction, color = "#3a3a3a", className }: IconProps) {

  return direction === 'left' ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M10.048 16L22.41 5.586a.25.25 0 0 0 .03-.352l-.97-1.146a.25.25 0 0 0-.352-.029L7.855 15.235c-.473.4-.473 1.13 0 1.53L21.119 27.94c.105.09.263.077.352-.029l.97-1.145a.251.251 0 0 0-.03-.353L10.048 16z"/></g></svg>
  ) : direction === 'right' ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M10.88 4.06a.25.25 0 0 0-.351.029l-.97 1.146a.248.248 0 0 0 .03.352L21.952 16 9.589 26.414a.248.248 0 0 0-.03.352l.97 1.146a.25.25 0 0 0 .352.029l13.264-11.176c.473-.4.473-1.13 0-1.53L10.88 4.06z" style={{ fill: color }}/></g></svg>
  ) : direction === 'down' ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M26.765 9.56a.248.248 0 0 0-.35.029L16 21.953 5.586 9.589a.25.25 0 0 0-.352-.03l-1.146.97a.25.25 0 0 0-.029.352l11.176 13.264c.4.473 1.13.473 1.53 0L27.94 10.88a.25.25 0 0 0-.029-.352l-1.146-.97z"/></g></svg>
  ) : null
}

export default Angle