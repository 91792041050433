import { Component, ErrorInfo, ReactNode } from "react"
import logError from '../utils/logging'

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo)
    logError(error, { errorName: error.name, errorMessage: error.message, errorStack: error.stack, errorCause: error.cause, componentStack: errorInfo.componentStack })
  }

  public render() {
    return this.props.children
  }
}

export default ErrorBoundary
