import React from 'react'
import Card from './Card'
import Modal from './Modal'
import OrderNumber from './OrderNumber'
import DeliveryLabel from './DeliveryLabel'
import Timer from './Timer'
import Contact from './Contact'
import ItemList from './ItemList'
import CheckList from './CheckList'
import TextButton from './TextButton'
import AngleIcon from '../icons/Angle'
import PrintOrder from './PrintOrder'
import GroceriesIcon from '../icons/Groceries'
import PrintIcon from '../icons/Print'
import InfoIcon from '../icons/Info'
import styles from './OrderItem.module.css'
import { Order } from '../types/Order'
import { Status } from '../types/Status'
import logError from '../utils/logging'

interface Props {
  item: Order
  onUpdateStatus: (orderId: string, status: number) => void
  onUpdateItemStatus: (orderId: string, itemId: string, itemStatus: number) => void
  cardType: Status
  modalIsActive: boolean
  setActiveModal: (modal: { index: number, fromGroceryButton: boolean } | null) => void
  numberOfColoumnItems: number
  index: number
  groceriesFirst: boolean
}

function OrderItem ({
  item,
  onUpdateStatus,
  onUpdateItemStatus,
  cardType,
  modalIsActive,
  setActiveModal,
  numberOfColoumnItems,
  index,
  groceriesFirst
}: Props) {
  const printEl = React.useRef<HTMLDivElement>(null)
  const printItem = item
  const [printIsComplete, setPrintIsComplete] = React.useState<boolean>(false)
  const [numberOfCheckedItems, setNumberOfCheckedItems] = React.useState(0)
  const {
    id,
    orderNo,
    loopNumber,
    orderItemsFood,
    orderItemsGrocery,
    deliveryType,
    deliveryDisplayName,
    deliveryBongDisplayName,
    deliveryFee,
    street,
    city,
    email,
    status,
    createdAt,
    userName,
    phoneNumber,
    channelName,
    tableNumber,
  } = printItem

  const abortController = React.useMemo(() => {
    return new AbortController()
  }, [])


  React.useEffect(() => {
    if (orderItemsGrocery && orderItemsGrocery.length > 0) {
      const checkedItems = orderItemsGrocery.filter((groceryItem) => groceryItem.status === 10)
      const numberOfChecked = checkedItems?.length || 0
      setNumberOfCheckedItems(numberOfChecked)
    }
  }, [orderItemsGrocery])

  function onOpenDetail ({ fromGroceryButton = false }) {
    setActiveModal({
      index,
      fromGroceryButton,
    })
  }

  const nextStatus = React.useMemo(() => {
    let updateWith = 1

    if ((status === 2 && deliveryType !== 200)) {
      updateWith = 2
    }
    return status + updateWith
  }, [status, deliveryType])

  React.useEffect(() => {
    async function forwardItemToPreparing () {
      await updateStatus()
    } const signal = abortController.signal


    if (!signal.aborted && status === 0) {
      forwardItemToPreparing()
    }

    return () => {
      abortController.abort()
    }
  }, [abortController])

  async function updateStatus () {
    const print = status === 0

    if (modalIsActive) {
      setActiveModal(null)
    }
    if (print && autoPrint()) {
      // await handlePrint()
      await onPrint({ autoPrint: true })
    } else {
      // Incorrect IDE warning: 'await' has no effect on the type of this expression. it does have an effect.
      await onUpdateStatus(id, nextStatus)
    }
  }

  React.useEffect(() => {
    async function updateOrderStatus () {
      try {
        // Incorrect IDE warning: 'await' has no effect on the type of this expression. it does have an effect.
        await onUpdateStatus(id, nextStatus)
      } finally {
        setPrintIsComplete(false)
      }
    }

    if (printIsComplete) {
      updateOrderStatus()
    }

  }, [printIsComplete])

  function autoPrint () {
    try {
      const settingsStorage = localStorage.getItem('settings')
      const settings = settingsStorage ? JSON.parse(settingsStorage) as { printOrders: boolean } : null
      if (window && settings && Object.prototype.hasOwnProperty.call(settings, "printOrders") && settings?.printOrders === true) {
        return true
      } else {
        return false
      }
    } catch (error) {
      logError(error as Error)
      return false
    }
  }

  async function onPrint (printCondition: { autoPrint: boolean }) {
    try {
      if (document && printEl !== null && printEl.current) {
        const printContent = printEl.current.innerHTML
        const frame = (window.document.getElementById('printframe') as HTMLIFrameElement)?.contentWindow
        if (frame !== null) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
          printCondition.autoPrint && setPrintIsComplete(true)
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function updateItemStatus (itemId: string, itemStatus: number) {
    onUpdateItemStatus(id, itemId, itemStatus)
  }

  return (
    <>
      <Card
        onDivClick={() => onOpenDetail({ fromGroceryButton: false })}
        number={channelName === 'icakiosk' ? loopNumber : orderNo}
        cardType={cardType}
        bongTitle={deliveryBongDisplayName}
        deliveryType={deliveryType}
        createdAt={createdAt}
        tableNumber={tableNumber}
        userName={userName}
        returns='FULL'
      >
        <div onClick={() => onOpenDetail({ fromGroceryButton: false })} className={cardType !== 'READY' ? styles.cardContent : undefined}>
          {cardType !== 'READY' && <ItemList orderId={id} items={orderItemsFood || []} />}
        </div>
        {
          cardType === 'PREPARING' ? (
            <div className={styles.preparingActions}>
              <TextButton
                style={{ borderLeft: '0' }}
                dynamicBorder={!orderItemsGrocery.length && true}
                onClick={() => onPrint({ autoPrint: false })} text='Skriv ut'>
                <PrintIcon className={styles.printIcon} color='var(--red)' />
              </TextButton>
              {orderItemsGrocery && orderItemsGrocery.length > 0 && (
                <TextButton
                  onClick={() => onOpenDetail({ fromGroceryButton: true })}
                  text={`Plocka varor ${numberOfCheckedItems}/${orderItemsGrocery.length}`}
                >
                  <GroceriesIcon className={styles.groceriesIcon} color='var(--red)' />
                </TextButton>
              )}
              <TextButton onClick={updateStatus} text='Redo att hämtas' />
            </div>
          ) : cardType === 'READY' ? (
            <div className={styles.readyAction}>
              <TextButton onClick={updateStatus} text='Lämna ut' />
            </div>
          ) : null}
      </Card>
      <Modal
        maxWidth='938px'
        overflow="hidden"
        active={modalIsActive}
        onConfirm={() => null}
        onCancel={() => setActiveModal(null)}
        withClose={false}
      >
        <div className={styles.content}>
          <button
            className={styles.prevButton}
            disabled={index === 0}
            onClick={() => setActiveModal({ index: index - 1, fromGroceryButton: false })}
            type='button'
          >
            <AngleIcon className={styles.prevIcon} direction='left' color='currentColor' />
            <span className={styles.hidden}>Föregående</span>
          </button>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={() => setActiveModal(null)} type='button'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7.19173 8.32304C7.11362 8.40115 7.11362 8.52778 7.19173 8.60589L10.5858 12L7.19173 15.3941C7.11362 15.4722 7.11362 15.5989 7.19173 15.677L8.3231 16.8083C8.4012 16.8864 8.52783 16.8864 8.60594 16.8083L12.0001 13.4142L15.3942 16.8083C15.4723 16.8864 15.5989 16.8864 15.677 16.8083L16.8084 15.677C16.8865 15.5989 16.8865 15.4722 16.8084 15.3941L13.4143 12L16.8084 8.60589C16.8865 8.52778 16.8865 8.40115 16.8084 8.32304L15.677 7.19167C15.5989 7.11357 15.4723 7.11357 15.3942 7.19167L12.0001 10.5858L8.60594 7.19167C8.52783 7.11357 8.4012 7.11357 8.3231 7.19167L7.19173 8.32304Z'
                  fill='#cf2e05'
                />
              </svg>
              <span className={styles.hidden}>Stäng</span>
            </button>
            <div className={styles.modalHeader}>
              <Card
                number={channelName === 'icakiosk' ? loopNumber : orderNo}
                cardType={cardType}
                bongTitle={deliveryBongDisplayName}
                deliveryType={deliveryType}
                createdAt={createdAt}
                tableNumber={tableNumber}
                userName={userName} returns='INFO_ONLY' />
              <Timer createdAt={createdAt} dynamicHeight={true} />
              {(userName || phoneNumber || email || street) && (
                <Contact
                  name={userName}
                  phoneNumber={phoneNumber}
                  street={street}
                  city={city}
                  email={email}
                  deliveryType={deliveryDisplayName}
                  deliveryFee={deliveryFee}
                />
              )}
            </div>
            <div className={styles.allItems}>
              {(deliveryType === 100 || deliveryType === 200) && (deliveryDisplayName || deliveryBongDisplayName) && (
                <div className={styles.deliveryInfo}>
                  <div>
                    <InfoIcon className={styles.infoIcon} color='currentColor' />
                  </div>
                  <span>{deliveryBongDisplayName ? deliveryBongDisplayName : deliveryDisplayName}</span>
                </div>
              )}
              <div className={groceriesFirst === true ? styles.orderItemsReverse : styles.orderItems}>
                {orderItemsFood && orderItemsFood.length > 0 && (
                  <div className={styles.modalSection}>
                    <h4 className={styles.modalSectionHeading}>Restaurang</h4>
                    <ItemList orderId={id} items={orderItemsFood} />
                  </div>
                )}
                {orderItemsGrocery && orderItemsGrocery.length > 0 && (
                  <div className={styles.modalSection}>
                    <h4 className={styles.modalSectionHeading}>
                      Plockvaror {numberOfCheckedItems} av {orderItemsGrocery.length}
                    </h4>
                    <CheckList orderId={id} items={orderItemsGrocery} updateItemStatus={updateItemStatus} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.modalFooter}>
              <TextButton
                style={{ padding: '1.5rem' }}
                dynamicBorder={true}
                onClick={() => onPrint({ autoPrint: false })}>
                <PrintIcon className={styles.printIcon} color='var(--red)' />
                <span>Skriv ut</span>
              </TextButton>
              <TextButton
                style={{ padding: '1.5rem' }}
                onClick={updateStatus}
                text={
                  cardType === 'PREPARING'
                    ? 'Redo att hämtas'
                    : cardType === 'READY'
                      ? 'Lämna ut'
                      : ''
                }
              />
            </div>
          </div>
          <button
            className={styles.nextButton}
            disabled={index + 1 === numberOfColoumnItems}
            onClick={() => setActiveModal({ index: index + 1, fromGroceryButton: false })}
            type='button'
          >
            <AngleIcon className={styles.nextIcon} direction='right' color='currentColor' />
            <span className={styles.hidden}>Nästa</span>
          </button>
        </div>
      </Modal>
      {printItem &&
        <div ref={printEl}>
          <PrintOrder order={printItem} />
        </div>
      }
    </>
  )
}

export default OrderItem
