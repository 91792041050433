import React from 'react'
import CustomizationList from './CustomizationList'
import styles from './ItemList.module.css'
import { OrderItem } from '../types/Order'

interface Props {
  orderId: string
  items: OrderItem[]
}

function ItemList({ orderId, items = [] }: Props) {
  return (
    <ul className={styles.orderItems}>
      {items && items.map(({ name, amount = 1, id, customizationItems }) => (
        <li className={styles.orderItem} key={`${orderId}-${id}`}>
          <div>
            <span className={styles.count}>{amount} st</span>
            {name}
          </div>
          {customizationItems && customizationItems.length > 0 && (
            <CustomizationList items={customizationItems} />
          )}
        </li>
      ))}
    </ul>
  )
}

export default ItemList