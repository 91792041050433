import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Location({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M15.998 24.553c-1.29-1.377-3.971-4.307-5.17-6.104-1.831-2.749-1.831-4.06-1.831-5.447 0-3.86 3.14-7.002 7-7.002 3.859 0 7 3.141 7 7.002 0 1.387 0 2.698-1.832 5.447-1.23 1.846-3.886 4.74-5.167 6.104zM7.035 14.278c.109 1.29.527 2.876 2.13 5.28 1.305 1.959 4.636 5.564 6.11 7.128a.987.987 0 0 0 1.442.002c1.467-1.552 4.77-5.118 6.112-7.13 2.168-3.253 2.168-5.007 2.168-6.556 0-5.064-4.201-9.164-9.301-8.997-5.267.172-9.105 5.02-8.661 10.273zm6.462-1.276c0-1.38 1.12-2.5 2.5-2.5 1.379 0 2.5 1.12 2.5 2.5s-1.121 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5zm-1 0c0 1.93 1.57 3.501 3.5 3.501s3.5-1.57 3.5-3.5c0-1.931-1.57-3.502-3.5-3.502s-3.5 1.57-3.5 3.501z"/></g></svg>
  )
}

export default Location