import React from 'react'

interface IconProps {
  color?: string
  className: string
}

function Warning({ color = "#3a3a3a", className }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M14.9075 12.1249C14.9075 12.0418 14.9628 11.9864 15.0459 11.9864H16.957C17.04 11.9864 17.0954 12.0418 17.0954 12.1249L16.8601 18.7025C16.8601 18.7993 16.8185 18.8409 16.7217 18.8409H15.2812C15.1843 18.8409 15.1428 18.7993 15.1428 18.7025L14.9075 12.1249ZM16.0009 19.4924C16.6525 19.4924 17.1517 20.0184 17.1517 20.6284C17.1517 21.2374 16.6525 21.7634 16.0009 21.7634C15.3504 21.7634 14.8521 21.2374 14.8521 20.6284C14.8521 20.0184 15.3504 19.4924 16.0009 19.4924ZM7.10243 23.6577L16.0004 7.98433L24.8984 23.6577H7.10243ZM27.9682 25.2682L16.2169 4.12754C16.122 3.95749 15.8788 3.95749 15.7839 4.12754L4.03163 25.2682C3.93968 25.4323 4.05931 25.635 4.24716 25.635H27.7537C27.9415 25.635 28.0602 25.4323 27.9682 25.2682Z" fill={color}/>
    </svg>
  )
}

export default Warning