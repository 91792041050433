import axios, { AxiosError } from "axios"
import { authHeader } from "../helpers/authHeader"
import { store } from '../app/store'

  interface CustomRequestOptions extends RequestInit {
  noAuth?: boolean
}

function handleError (error: AxiosError) {
  const { response, request } = error
  const err = new Error() as any
  err.status = response?.status
  err.message = response ? `${request.responseURL} ${response?.status} ${response?.statusText}` : '"Something went wrong while fetching data"'
  if (error?.response?.status === 401) {
    localStorage.removeItem('user')
    window.location.href = '/'
    return err
  } else if (error?.response?.status === 403) {
    store.dispatch({ type: 'message/showMessageToast', payload: "Behörighet saknas" })
    throw err
  } else {
    throw err
  }
}

export default async function fetchFrom<T>(requestUrl: string, options?: CustomRequestOptions): Promise<T>  {
  const authHeaders =
    options?.noAuth && options?.noAuth === true ? {} : authHeader()
 
  const headers = {
    "Content-Type": "application/json; charset=UTF-8",
    "Cache-Control": "no-cache",
    ...authHeaders,
  }
  if (options?.method === "POST") {
    try {
      const { status, data } = await axios.post(requestUrl, options.body, {
        headers,
      })

      if (status === 200) {
        return data
      }
    } catch (error: any) {
      handleError(error)
    }
  } else if (options?.method === "PUT") {
    try {
      const { status, data } = await axios.put(requestUrl, options.body, {
        headers,
      })

      if (status === 200) {
        return data
      }
    } catch (error: any) {
      handleError(error)
    }
  } else {
    try {
      const { data, status } = await axios.get(requestUrl, {
        headers,
      })

      if (status === 200) {
        return data
      }

    } catch (error: any) {
      handleError(error)
    }
  }
  throw new Error("Request was not successful")
}
export { fetchFrom }
