import React from "react"
import { connect } from "react-redux"
import { FETCHING, WAITING, PREPARING, READY } from "../helpers/constants"
import Menu from "../components/Menu"
import OrderItem from "../components/OrderItem"
import MarketPlaceIcon from "../icons/MarketPlace"
import CloseIcon from "../icons/Close"
import styles from "./Orders.module.css"
import { useParams } from "react-router-dom"
import { Dispatch, RootState } from "../models"
import { Order, OrderItem as OrderItemType } from "../types/Order"
import logError from "../utils/logging"
import useSignalR from "../utils/useSignalR"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function Orders ({
  allOrders,
  ordersWaiting,
  ordersBeingPrepared,
  ordersReadyForDelivery,
  status,
  fetchOrders,
  fetchOrderById,
  updateStatusAsync,
  updateStatusState,
  updateItemStatusAsync,
  updateItemStatus,
  fetchUserStores,
  selectedStore,
}: Props) {
  const { storeId } = useParams()
  const { connect: connectSignalR, reset, listen, stopListen } = useSignalR()
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [activeWaitingModal, setActiveWaitingModal] = React.useState<{ index: number, fromGroceryButton: boolean } | null>(null)
  const [activeBeingPreparedModal, setActiveBeingPreparedModal] = React.useState<{ index: number, fromGroceryButton: boolean } | null>(null)
  const [activeReadyModal, setActiveReadyModal] = React.useState<{ index: number, fromGroceryButton: boolean } | null>(null)
  const [activeColumn, setActiveColumn] = React.useState(PREPARING)
  const [cancelReadyStatus, setCancelReadyStatus] = React.useState(false)
  const [updatingReadyStatus, setUpdatingReadyStatus] = React.useState<{ orderId: string, status: number, updating: boolean } | Record<string, never>>({})
  const [hubCreateMessage, setHubCreateMessage] = React.useState(null)
  const [hubStatusMessage, setHubStatusMessage] = React.useState(null)
  const [hubItemStatusMessage, setHubItemStatusMessage] = React.useState(null)
  const [audio, setAudio] = React.useState<HTMLAudioElement>()
  const [audioConsent, setAudioConsent] = React.useState(false)
  const [audioLoop, setAuidoLoop] = React.useState<number | null>(null)
  const [showKioskOrders, setShowKioskOrders] = React.useState(true)
  const [showProntoOrders, setShowProntoOrders] = React.useState(true)
  const [chosenSoundOption, setChosenSoundOption] = React.useState(localStorage.getItem('selectedSoundOption') === null ? 0 : Number(localStorage.getItem('selectedSoundOption')))
  const [shouldPlaySound, setShouldPlaySound] = React.useState(true)
  const timerRef = React.useRef<number | null>(null)

  React.useEffect(() => {
    if (chosenSoundOption === 2) {

      const resetTimer = () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
          setShouldPlaySound(false)
        }
        timerRef.current = setTimeout(() => {
          setShouldPlaySound(true)
        }, 60000)
      }

      window.addEventListener('touchstart', resetTimer)
      window.addEventListener('mousedown', resetTimer)

      resetTimer()

      return () => {
        if (timerRef.current) {
          window.removeEventListener('touchstart', resetTimer)
          window.removeEventListener('mousedown', resetTimer)
          clearTimeout(timerRef.current)
        }
      }
    }
  }, [chosenSoundOption])

  function onSelectSoundOption (option: number) {
    setChosenSoundOption(option)
    localStorage.setItem('selectedSoundOption', String(option))
  }

  // const healthCheckRef = React.useRef<number | null>(null)

  React.useEffect(() => {
    if (allOrders !== null) {
      connectSignalR()
    }
  }, [allOrders, connectSignalR])

  React.useEffect(() => {
    async function unLoad () {
      await reset()
    }
    unLoad()
  }, [reset])

  React.useEffect(() => {
    async function activateListner () {

      const topics = [
        {
          topic: "onOrderCreatedMessage",
          callback: (res: any) => setHubCreateMessage(res),
        },
        {
          topic: "onOrderStatusMessage",
          callback: (res: any) => setHubStatusMessage(res),
        },
        {
          topic: "onOrderItemStatusMessage",
          callback: (res: any) => setHubItemStatusMessage(res),
        },
      ]

      await listen(topics)
    }
    activateListner()

    return () => {
      stopListen("onOrderCreatedMessage")
      stopListen("onOrderStatusMessage")
      stopListen("onOrderItemStatusMessage")
    }
  }, [listen, stopListen])


  React.useEffect(() => {
    if (!selectedStore) {
      fetchUserStores(storeId)
    }
    fetchOrders({
      storeId,
      fetching: true,
    })
  }, [storeId, selectedStore])

  React.useEffect(() => {
    if (hubCreateMessage) {
      onOrderCreatedMessage(hubCreateMessage)
    }
  }, [hubCreateMessage])

  React.useEffect(() => {
    if (hubStatusMessage) {
      onOrderStatusMessage(hubStatusMessage)
    }
  }, [hubStatusMessage])

  React.useEffect(() => {
    if (hubItemStatusMessage) {
      onOrderItemStatusMessage(hubItemStatusMessage)
    }
  }, [hubItemStatusMessage])

  React.useEffect(() => {
    const { updating, orderId, status } = updatingReadyStatus

    if (updating) {
      if (cancelReadyStatus) {
        setUpdatingReadyStatus({})
        setCancelReadyStatus(false)
      } else {
        setUpdatingReadyStatus({})
        onUpdateStatus(orderId, status)
      }
    }
  }, [updatingReadyStatus, cancelReadyStatus])

  function onAcceptAudio () {
    const _audio = new Audio("/bell_service_desk.mp3")
    _audio.load()
    _audio.addEventListener("canplaythrough", () => {
      setAudio(_audio)
    })
    setAudioConsent(true)
  }

  React.useEffect(() => {
    setShouldPlaySound(chosenSoundOption === 0)
  }, [chosenSoundOption])

  async function onUpdateStatus (orderId: string, status: number) {
    await updateStatusAsync({
      storeId,
      orderId,
      status,
    })
    stopNotificationSound()
  }

  function onUpdateReadyStatus (orderId: string, status: number) {
    setUpdatingReadyStatus({
      orderId,
      status,
      updating: true,
    })
  }

  async function onUpdateItemStatus (orderId: string, itemId: string, itemStatus: number) {
    await updateItemStatusAsync({
      storeId,
      orderId,
      itemId,
      itemStatus,
    })
  }

  async function createNotification () {
    if (shouldPlaySound) {
      if (audio) audio.currentTime = 0
      if (ordersBeingPrepared.length > 0) {
        audio && audio.play().catch((e) => logError(e as Error))
      } else {
        const interval = window.setInterval(() => {
          audio && audio.play().catch((e) => logError(e as Error))
        }, 0.1)
        setAuidoLoop(interval)
      }
    }
  }

  function stopNotificationSound () {
    audioLoop && clearInterval(audioLoop)
  }


  // React.useEffect(() => {
  //   return () => {
  //     healthCheckRef.current && clearInterval(healthCheckRef.current)
  //   }
  // }, [])

  function onOrderCreatedMessage (res: string) {
    setHubCreateMessage(null)

    try {
      const result = JSON.parse(res) as Order

      if (storeId?.toLowerCase() === result?.storeId?.toLowerCase()) {
        const existingOrder = allOrders?.find((order) => order.id === result.id)
        if (!existingOrder) {
          fetchOrderById({
            storeId,
            orderId: result.id,
            fetching: false,
          })
          if ((result.channelName === 'icakiosk' && showKioskOrders === true) || (result.channelName === 'icapronto' && showProntoOrders === true)) {
            createNotification()
          }
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onOrderStatusMessage (res: string) {
    setHubStatusMessage(null)
    stopNotificationSound()
    try {
      const result = JSON.parse(res) as Order
      if (storeId?.toLowerCase() === result?.storeId?.toLowerCase()) {
        const existingOrder = allOrders?.find((order) => order.id === result.id)
        if (existingOrder) {
          if (existingOrder.status !== result.status) {
            updateStatusState({ existingOrder, orderStatus: result.status })
          }
        } else {
          fetchOrders({
            storeId,
            fetching: false,
          })
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onOrderItemStatusMessage (res: string) {
    setHubItemStatusMessage(null)
    try {
      const result = JSON.parse(res) as OrderItemType

      if (storeId?.toLowerCase() === result?.storeId?.toLowerCase()) {
        const existingOrder = allOrders?.find((order) => order.id === result.id)
        if (existingOrder && existingOrder.orderItemsGrocery.length > 0) {
          existingOrder.orderItemsGrocery.map((groceryItem) => {
            if (groceryItem.itemId === result.itemId) {
              updateItemStatus({
                orderId: result.id,
                itemId: result.itemId,
                itemStatus: result.status,
              })
            }
          })
        } else {
          fetchOrders({
            storeId,
            fetching: false,
          })
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function getFilteredOrders (items: Order[]) {
    return items.filter(order => {
      if (showKioskOrders === true && order.channelName === 'icakiosk') {
        return true
      } else if (showProntoOrders === true && order.channelName === 'icapronto') {
        return true
      } else {
        return false
      }
    })
  }

  const filteredOrdersBeingPrepared = React.useMemo(() => {
    return [...getFilteredOrders(ordersBeingPrepared), ...getFilteredOrders(ordersWaiting)]
  }, [ordersBeingPrepared, ordersWaiting])
  const filteredOrdersReadyForDelivery = React.useMemo(() => getFilteredOrders(ordersReadyForDelivery), [ordersReadyForDelivery])

  return (
    <>
      <div className={styles.siteContent}>
        <div className={styles.page}>
          <div className={styles.columnMenu}>
            <button
              className={
                activeColumn === PREPARING
                  ? styles.activeColumnMenuButton
                  : styles.columnMenuButton
              }
              onClick={() => setActiveColumn(PREPARING)}
              type="button"
            >
              Tillagas <span>({filteredOrdersBeingPrepared.length})</span>
            </button>
            <button
              className={
                activeColumn === READY
                  ? styles.activeColumnMenuButton
                  : styles.columnMenuButton
              }
              onClick={() => setActiveColumn(READY)}
              type="button"
            >
              Redo <span>({ordersReadyForDelivery.length})</span>
            </button>
          </div>
          <div
            className={
              activeColumn === PREPARING
                ? styles.ordersBeingPrepared
                : activeColumn === READY
                  ? styles.ordersReady
                  : styles.orders
            }
          >
            <div className={styles.colPreparing}>
              <h2 className={styles.heading}>
                Tillagas <span>({filteredOrdersBeingPrepared.length})</span>
              </h2>
              {status === FETCHING ? (
                <div>Hämtar påbörjade ordrar…</div>
              ) : (
                <div className={styles.preparingContent}>
                  {filteredOrdersBeingPrepared.map((item, index) => (
                    <OrderItem
                      modalIsActive={
                        index ===
                        activeBeingPreparedModal?.index
                      }
                      groceriesFirst={
                        activeBeingPreparedModal ? activeBeingPreparedModal?.fromGroceryButton : false
                      }
                      setActiveModal={setActiveBeingPreparedModal}
                      numberOfColoumnItems={filteredOrdersBeingPrepared.length}
                      index={index}
                      cardType="PREPARING"
                      key={item.id}
                      item={item}
                      onUpdateStatus={onUpdateStatus}
                      onUpdateItemStatus={onUpdateItemStatus}
                    />
                  ))}
                </div>
              )}
            </div>
            <div className={styles.colReady}>
              <h2 className={styles.heading}>
                Redo att hämtas <span>({filteredOrdersReadyForDelivery.length})</span>
              </h2>
              {status === FETCHING ? (
                <div>Hämtar orders redo att hämtas…</div>
              ) : (
                <>
                  {filteredOrdersReadyForDelivery.map((item, index) => (
                    <OrderItem
                      modalIsActive={
                        index === activeReadyModal?.index
                      }
                      groceriesFirst={false}
                      setActiveModal={setActiveReadyModal}
                      numberOfColoumnItems={filteredOrdersReadyForDelivery.length}
                      index={index}
                      cardType="READY"
                      key={item.id}
                      item={item}
                      onUpdateStatus={(orderId, status) =>
                        onUpdateReadyStatus(orderId, status)
                      }
                      onUpdateItemStatus={onUpdateItemStatus}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <Menu
          chosenSoundOption={chosenSoundOption}
          onSelectSoundOption={onSelectSoundOption}
          isOpen={menuIsOpen}
          updateStatus={setMenuIsOpen}
          filter={{
            showKioskOrders,
            setShowKioskOrders,
            showProntoOrders,
            setShowProntoOrders
          }}
          channelTypes={selectedStore && selectedStore.channelTypes}
        />
        <button
          className={
            updatingReadyStatus?.updating
              ? styles.cancelStatusButtonVisible
              : styles.cancelStatusButton
          }
          onClick={() => setCancelReadyStatus(true)}
          type="button"
        >
          Ångra upphämtad
        </button>
        <button
          onClick={() => setMenuIsOpen((prev) => !prev)}
          className={menuIsOpen ? styles.activeMenuButton : styles.menuButton}
          type="button"
        >
          <span className={styles.menuButtonText}>
            {selectedStore && selectedStore?.name ? (
              <>{selectedStore.name}</>
            ) : (
              <>Meny</>
            )}
          </span>
          <div className={styles.menuIcons}>
            <span className={styles.menuIcon}>
              <MarketPlaceIcon color="var(--red)" className={styles.icon} />
            </span>
            <span className={styles.activeMenuIcon}>
              <CloseIcon color="currentColor" className={styles.icon} />
            </span>
          </div>
        </button>
        {audioConsent === false && (
          <div className={styles.audioConsent}>
            <div className={styles.audioConsentContent}>
              <p className={styles.audioConsentText}>
                Tryck <strong>OK</strong> nedan för att tillåta webbläsaren att
                spela upp notiser då en ny order kommer in.
              </p>
              <button
                className={styles.audioConsentButton}
                onClick={onAcceptAudio}
                type="button"
              >
                OK
              </button>
              <p className={styles.audioConsentInfo}>
                Pga webbläsarens integritetsinställningar måste detta göras
                varje gång då sidan laddas om.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

function mapState (state: RootState) {
  return {
    allOrders: state.order.allOrders,
    ordersWaiting: state.order.ordersWaiting,
    ordersBeingPrepared: state.order.ordersBeingPrepared,
    ordersReadyForDelivery: state.order.ordersReadyForDelivery,
    status: state.order.status,
    selectedStore: state.store.selectedStore,
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchOrders: dispatch.order.fetchOrders,
    fetchOrderById: dispatch.order.fetchOrderById,
    fetchUserStores: dispatch.store.fetchUserStores,
    updateStatusAsync: dispatch.order.updateStatusAsync,
    updateStatusState: dispatch.order.updateStatusState,
    updateItemStatusAsync: dispatch.order.updateItemStatusAsync,
    updateItemStatus: dispatch.order.updateItemStatus,
  }
}

export default connect(mapState, mapDispatch)(Orders)
