import { CateringOrderItem } from "../types/Order"

export interface ProductionListToReprint {
  date: string
  cateringProducts: CateringOrderItem[]
  orderNo?: string | string[]
  orderId?: string
}

const getReprintStatusProductionLists = (productionLists: CateringOrderItem[][], userId: string): ProductionListToReprint[] => {
  const productionListsWithOrderHistory = productionLists
    .map((list) => list.filter((order) => {
      return order.products?.filter(product =>
        product.orders?.find(orderItem =>
          orderItem.orderHistory && orderItem.orderHistory.length > 0
        )
      ).length > 0
    })).filter(group => group.length > 0)

  return productionListsWithOrderHistory.reduce((acc, list) => {
    list.forEach((order) => {
      order.products.forEach((product) => {
        product.orders.forEach((subOrder) => {
          const orderLastChanged = subOrder.orderHistory?.[0]?.date
          subOrder.printedByUsersWithType?.forEach((printedByUser) => {
            if (userId === printedByUser.userId && orderLastChanged) {
              const printAction = printedByUser.actions.find(action => action.type === "ProductionList")
              if (printAction && printAction.printedDateTime < orderLastChanged) {

                const productionListWithUpdatedOrders = {
                  date: subOrder.deliveryDate,
                  cateringProducts: list,
                  orderNo: subOrder.orderNumber,
                  orderId: subOrder.id
                }
                const listString = JSON.stringify(productionListWithUpdatedOrders)
                if (!acc.uniqueSet.has(listString)) {
                  acc.uniqueSet.add(listString)
                  acc.listsToReprint.push(productionListWithUpdatedOrders)
                }
              }
            }
          })
        })
      })
    })
    return acc
  }, {
    uniqueSet: new Set(),
    listsToReprint: [] as ProductionListToReprint[],
  }).listsToReprint
}

export default getReprintStatusProductionLists