import { Customization, Order, OrderItem } from "../types/Order"

export function getItemsCountFrom<T> (items: Order[]) {
  if (items && items.length > 0) {
    return items.map((item) => {
      return {
        ...item,
        orderItemsFood: item.orderItemsFood.reduce((prev, current) => {
          const name = current.name
          const existing = prev.find(
            (prevItem) =>
              prevItem.name === name && prevItem.customizationItems.length === current.customizationItems.length && withSamecustomization(prevItem.customizationItems, current.customizationItems)
          )
          if (
            existing
          ) {
            const prevWithoutDouble = prev.filter((prevItem) => prevItem.id !== existing.id)
            return [
              ...prevWithoutDouble,
              {
                ...existing,
                amount: existing.amount += 1,
              }
            ]
          } else {
            return [...prev, { ...current, amount: 1 }]
          }
        }, [] as OrderItem[]),
      }
    })
  }
  return items
}

function withSamecustomization (array1: Customization[], array2: Customization[]) {
  return array1.every((e1) => array2.some((e2) => e1.id === e2.id))
}
