import React from 'react'
import styles from './TextButton.module.css'

interface Props {
  type?: "button" | "submit" | "reset"
  text?: string
  onClick: () => void
  style?: React.CSSProperties
  hidden?: boolean
  children?: React.ReactNode
  dynamicBorder?: any
  className?: string
}

function TextButton({ type = 'button', text = '', onClick, style, hidden = false, dynamicBorder, children, className = '' }: Props) {

  const classNames = `${styles.textButton}  ${hidden ? styles.hidden : ''} ${dynamicBorder ? styles.dynamicBorder : ''} ` + className

  return (
    <button
      onClick={onClick}
      style={style}
      type={type}
      className={classNames}
    >
      {children}
      {text}
    </button>
  )
}

export default TextButton
