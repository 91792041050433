import React from "react"
import { connect } from "react-redux"
import styles from "./OrderView.module.css"
import { useParams } from "react-router-dom"
import { Dispatch, RootState } from "../models"
import { OrderStatusUpdate } from "../types/Order"
import logError from "../utils/logging"
import useSignalR from "../utils/useSignalR"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function OrderView ({
  allOrders,
  ordersWaiting,
  ordersBeingPrepared,
  ordersReadyForDelivery,
  fetchOrders,
  fetchOrderById,
  updateStatusState,
}: Props) {
  const { storeId } = useParams()
  const { connect: connectSignalR, reset, listen, stopListen } = useSignalR()
  const [hubCreateMessage, setHubCreateMessage] = React.useState(null)
  const [hubStatusMessage, setHubStatusMessage] = React.useState(null)
  const [hubItemStatusMessage, setHubItemStatusMessage] = React.useState(null)

  // const healthCheckRef = React.useRef<number | null>(null)

  React.useEffect(() => {
    if (storeId) {
      fetchOrders({
        storeId,
        fetching: true,
      })
    }
  }, [storeId])

  React.useEffect(() => {
    if (allOrders !== null) {
      connectSignalR()
    }
  }, [allOrders, connectSignalR])

  React.useEffect(() => {
    async function unLoad () {
      await reset()
    }
    unLoad()
  }, [reset])

  React.useEffect(() => {
    async function activateListner () {

      const topics = [
        {
          topic: "onOrderCreatedMessage",
          callback: (res: any) => setHubCreateMessage(res),
        },
        {
          topic: "onOrderStatusMessage",
          callback: (res: any) => setHubStatusMessage(res),
        },
        {
          topic: "onOrderItemStatusMessage",
          callback: (res: any) => setHubItemStatusMessage(res),
        },
      ]

      await listen(topics)
    }
    activateListner()

    return () => {
      stopListen("onOrderCreatedMessage")
      stopListen("onOrderStatusMessage")
      stopListen("onOrderItemStatusMessage")
    }
  }, [listen, stopListen])

  React.useEffect(() => {
    if (hubCreateMessage) {
      onOrderCreatedMessage(hubCreateMessage)
    }
  }, [hubCreateMessage])

  React.useEffect(() => {
    if (hubStatusMessage) {
      onOrderStatusMessage(hubStatusMessage)
    }
  }, [hubStatusMessage])

  React.useEffect(() => {
    if (hubItemStatusMessage) {
      onOrderItemStatusMessage(hubItemStatusMessage)
    }
  }, [hubItemStatusMessage])

  // React.useEffect(() => {
  //   return () => {
  //     healthCheckRef.current && clearInterval(healthCheckRef.current)
  //   }
  // }, [])

  function onOrderCreatedMessage (res: string) {
    setHubCreateMessage(null)

    try {
      const result = JSON.parse(res) as OrderStatusUpdate

      if (storeId && storeId.toLowerCase() === result?.storeId?.toLowerCase()) {
        const existingOrder = allOrders.find((order) => order.orderNo === result.id)
        if (!existingOrder) {
          fetchOrderById({
            storeId,
            orderId: result.id,
            fetching: false,
          })
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onOrderStatusMessage (res: string) {
    setHubStatusMessage(null)
    try {
      const result = JSON.parse(res) as OrderStatusUpdate
      if (storeId && storeId.toLowerCase() === result?.storeId?.toLowerCase()) {
        const existingOrder = allOrders.find((order) => order.orderNo === result.id)
        if (existingOrder) {
          if (existingOrder.status !== result.status) {
            updateStatusState({ existingOrder })
          }
        } else {
          fetchOrders({
            storeId,
            fetching: false,
          })
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onOrderItemStatusMessage (res: string) {
    setHubItemStatusMessage(null)
    try {
      const result = JSON.parse(res) as OrderStatusUpdate
      if (storeId && storeId.toLowerCase() === result?.storeId?.toLowerCase()) {
        fetchOrders({
          storeId,
          fetching: false,
        })
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  return (
    <div className={styles.page}>
      <div className={styles.column}>
        <h2 className={styles.heading}>Väntar</h2>
        <ul className={styles.list}>
          {[...ordersBeingPrepared, ...ordersWaiting].map(
            ({ orderNo, loopNumber, isKiosk = true }, index) => (
              <li className={styles.item} key={`${orderNo}-${index}`}>
                <div className={styles.itemContent}>
                  {isKiosk === true ? loopNumber : <span className={styles.orderNumber}>{orderNo}</span>}
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className={styles.readyColumn}>
        <h2 className={styles.readyHeading}>Redo att hämtas</h2>
        <ul className={styles.readyList}>
          {ordersReadyForDelivery
            .reverse()
            .map(({ orderNo, loopNumber, isKiosk = true }, index) => (
              <li className={styles.readyItem} key={`${orderNo}-${index}`}>
                <div className={styles.readyItemContent}>
                  {isKiosk === true ? loopNumber : <span className={styles.orderNumber}>{orderNo}</span>}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

function mapState (state: RootState) {
  return {
    allOrders: state.anonymousOrder.allOrders,
    ordersWaiting: state.anonymousOrder.ordersWaiting,
    ordersBeingPrepared: state.anonymousOrder.ordersBeingPrepared,
    ordersReadyForDelivery: state.anonymousOrder.ordersReadyForDelivery,
    status: state.anonymousOrder.status,
    selectedStore: state.store.selectedStore,
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchOrders: dispatch.anonymousOrder.fetchStatusOrders,
    fetchOrderById: dispatch.anonymousOrder.fetchStatusOrderById,
    updateStatusState: dispatch.anonymousOrder.updateStatusState,
  }
}

export default connect(mapState, mapDispatch)(OrderView)
