import React from 'react'
import { connect } from 'react-redux'
import PrintIcon from '../icons/Print'
import UserIcon from '../icons/User'
import PrintCateringProductionList from './PrintCateringProductionList'
import OrderNumber from './OrderNumber'
import { getDayOfWeek } from '../helpers/getWeekday'
import { formatVariant } from '../helpers/formatVariant'
import styles from './CateringProductionList.module.css'
import { Dispatch } from '../models'
import { CateringOrderItem, Order } from '../types/Order'
import logError from '../utils/logging'
import { groupProductionList } from '../helpers/groupProductionLists'

interface Props {
  orders: Order[]
  date: string,
  storeId: string,
  userId: string,
  onPrintOrder: (payload: { storeId: string, orderIds: string[], userId?: string, isSingleOrder: boolean, type: string }) => void
}

function CateringProductionList({
  orders,
  date,
  storeId,
  userId,
  onPrintOrder
}: Props) {
  const [groupedOrder, setGroupedOrder] = React.useState<CateringOrderItem[]>([])
  const printEl = React.useRef<HTMLDivElement>(null)

  const dayOfWeek = React.useMemo(() => {
    return getDayOfWeek(date)
  }, [date])

  function onPrint() {
    try {
      if (document && printEl) {
        const printContent = printEl.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if(frame && printContent) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  const printProductionList = () => {
    const idsToPrint = groupedOrder.reduce((acc: string[], list) => {
      const productOrdersIds = list.products.map(product =>
        product.orders.map(order => order.id) 
      ).flat() 
    
      return [...acc,...productOrdersIds]
    }, [] as string[])
   
    const uniqueIdsToPrint = [...new Set(idsToPrint)]

    onPrintOrder({
      storeId,
      orderIds: uniqueIdsToPrint,
      userId,
      isSingleOrder: false,
      type: 'ProductionList',
    })
    onPrint()
  }

  React.useEffect(() => {
    const groupedOrder = groupProductionList(orders)
    setGroupedOrder(groupedOrder)
  }, [])

  return groupedOrder ? (
    <>
      <div className={styles.sectionHeading}>
        <h2 className={styles.headingText}>
          Leveransdag: {dayOfWeek}, {date}
        </h2>
        <button onClick={printProductionList} className={styles.printButton}>
          <PrintIcon color='#CF2E05' className={styles.printIcon} />
          Skriv ut
        </button>
      </div>
      <div className={styles.products}>
        {groupedOrder?.map((order) => {
          if (order.amount > 0) {
            return (
              <div className={styles.item} key={order.id}>
                <div className={styles.product}>
                  <div className={styles.itemHeadingBox}>
                    <h2 className={styles.itemHeadingText}>
                      {order.productName}{' '}
                      <span className={styles.itemHeadingAmount}>
                        ({order.amount})
                      </span>
                    </h2>
                  </div>
                  {order.products.map((item) => {
                    if (item.amount > 0) {
                      return (
                        <div className={styles.product} key={item.itemId}>
                          <div className={styles.productInfo}>
                            <span className={styles.amount}>
                              {item.amount} st {item.productName}
                            </span>
                            <div className={styles.variantBox}>
                              {item.variantName &&
                                formatVariant(item.variantName)?.map(
                                  (v: string, index: number) => {
                                    return (
                                      <span
                                        className={styles.variant}
                                        key={item.variantId + index}
                                      >
                                        {v}
                                      </span>
                                    )    
                                  }                       
                                )}
                              {item.productComment && (
                                <span className={styles.variant}>
                                  Meddelande: {item.productComment}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className={styles.orderBox}>
                            {item.orders.map((order, i) => {
                              if (order.amount > 0) {
                                return (
                                  <div key={i}>
                                    <div className={styles.orderInfoBox} key={order.orderNumber}>
                                      <p className={styles.orderInfo} style={{ borderBottom: item.orderComment ? '' : '1px solid var(--graySpecial)' }}>
                                        <span className={styles.orderAmount}>
                                          {order.amount} styck
                                        </span>
                                        <span className={styles.orderName}>
                                          <UserIcon
                                            className={styles.userIcon}
                                            color='#CF2E05'
                                          />
                                          <span className={styles.name}>
                                            {order.userName}
                                          </span>
                                        </span>
                                        <span className={styles.orderDelivery}>
                                          {order.deliveryDisplayName}
                                          {order.deliveryTimeSlot ? `: ${order.deliveryTimeSlot}` : ''}
                                        </span>
                                        <span className={styles.orderNumber}>
                                          <OrderNumber
                                            color='#fdc300'
                                            number={order.orderNumber}
                                            dynamicWidth={true}
                                          />
                                        </span>
                                      </p>
                                    </div>
                                    {item.orderComment && (
                                      <p className={styles.orderComment}>
                                        Övrigt meddelande: {item.orderComment}
                                      </p>
                                    )}
                                  </div>
                                )
                              }
                            })}
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            )
          } else {
            <></>
          }
        })}
      </div>
      <div ref={printEl}>
        <PrintCateringProductionList orders={groupedOrder} date={date} />
      </div>
    </>
  ) : (
    <div></div>
  )
}

function mapDispatch(dispatch: Dispatch) {
  return {
    printOrder: dispatch.order.printCateringOrder
  }
}

export default connect(null, mapDispatch)(CateringProductionList)
