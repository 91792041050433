import React from 'react'

interface IconProps {
  color: string
  className: string
}

function Timer({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M16 14.5C15.82 14.5 15.648 14.532 15.482 14.576L11.354 10.5C11.256 10.402 11.098 10.402 11 10.5L9.939 11.561C9.842 11.658 9.842 11.816 9.939 11.914L14.072 15.995C14.03 16.157 14 16.324 14 16.5C14 17.604 14.896 18.5 16 18.5C17.104 18.5 18 17.604 18 16.5C18 15.396 17.104 14.5 16 14.5ZM16 24.5C11.313 24.5 7.5 20.687 7.5 16C7.5 11.313 11.313 7.5 16 7.5C20.687 7.5 24.5 11.313 24.5 16C24.5 20.687 20.687 24.5 16 24.5ZM21.952 7.359C21.98 7.244 22 7.125 22 7C22 6.171 21.328 5.5 20.5 5.5C20.031 5.5 19.619 5.719 19.344 6.055C18.596 5.803 17.812 5.628 17 5.551V4.5H17.75C17.888 4.5 18 4.388 18 4.25V2.75C18 2.612 17.888 2.5 17.75 2.5H14.25C14.112 2.5 14 2.612 14 2.75V4.25C14 4.388 14.112 4.5 14.25 4.5H15V5.551C9.678 6.056 5.5 10.547 5.5 16C5.5 21.79 10.21 26.5 16 26.5C21.79 26.5 26.5 21.79 26.5 16C26.5 12.419 24.696 9.255 21.952 7.359Z" fill={color}/>
    </svg>
  )
}

export default Timer