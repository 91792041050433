import React from 'react'
interface IconProps {
  color: string
  className: string
}
function Circle({ className, color }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <circle cx="7" cy="7" r="7" fill={color}/>
    </svg>
  )
}

export default Circle