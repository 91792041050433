import { isEnvironment } from './environment'

export function getServiceUrl(service: string, version: string) {
  switch (service) {
  case 'orderApi':
    return getOrderUrl(version)
  default:
    return ''
  }
}

function getOrderUrl(version: string) {
  if (isEnvironment('development')) {
    return `https://api-testing.icapronto.se/svc-order/${version}`
  }
  else if (isEnvironment('testing')) {
    return `https://api-testing.icapronto.se/svc-order/${version}`
  }
  else if (isEnvironment('staging')) {
    return `https://api-staging.icapronto.se/svc-order/${version}`
  }
  else if (isEnvironment('production')) {
    return `https://api.icapronto.se/svc-order/${version}`
  }
}

