import React from 'react'
import styles from './CustomizationList.module.css'
import { Customization } from '../types/Order'

interface CustomizationTypes {
  customizable: Customization[]
  addable: Customization[]
  removable: Customization[]
}

function CustomizationList ({ items }: { items: Customization[] }) {
  const [customizations, setCustomizations] = React.useState<CustomizationTypes | null>(null)

  React.useEffect(() => {
    const customizationItems = items.reduce((prev, current) => {
      if (current.customizationType === 1) {
        return {
          ...prev,
          addable: [
            ...prev.addable,
            current
          ]
        }
      } else if (current.customizationType === 2) {
        return {
          ...prev,
          removable: [
            ...prev.removable,
            current
          ]
        }
      } else if (current.customizationType === 3) {
        return {
          ...prev,
          customizable: [
            ...prev.customizable,
            current
          ]
        }
      }
      return prev

    }, { addable: [], removable: [], customizable: [] } as CustomizationTypes)
    setCustomizations(customizationItems)
  }, [items])

  return customizations ? (
    <div>
      {customizations?.customizable.length > 0 && customizations.customizable.map(({ name }, index) => (
        <p className={styles.customizationItem} key={index}>{name}</p>
      ))}
      {customizations?.addable.length > 0 && customizations.addable.map(({ name }, index) => (
        <p className={styles.customizationItem} key={index}>+ {name}</p>
      ))}
      {customizations?.removable.length > 0 && customizations.removable.map(({ name }, index) => (
        <p className={styles.customizationItem} key={index}>- {name}</p>
      ))}
    </div>
  ) : null
}

export default CustomizationList