import { ApplicationInsights, } from '@microsoft/applicationinsights-web'
const prodConnectionString = 'InstrumentationKey=553b4eb5-a010-461b-9c41-5ef9747fd6ba;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
const stageConnectionString = 'InstrumentationKey=22e789d0-21e0-41bd-be24-344d1443ee65;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
const testConnectionString = 'InstrumentationKey=382365ed-326a-4161-8346-88e3885092cc;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/'
const appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_ENV === 'production' ? prodConnectionString : import.meta.env.VITE_ENV === 'staging' ? stageConnectionString : testConnectionString,
  }
})

export { appInsights }