import { Models, RematchDispatch, RematchRootState } from '@rematch/core'
import { user } from './user'
import { order } from './order'
import { store } from './store'
import { message } from './message'
import { anonymousOrder } from './anonymousOrder'

export interface RootModel extends Models<RootModel> {
	user: typeof user
	order: typeof order
	store: typeof store
	message: typeof message
	anonymousOrder: typeof anonymousOrder
}

export type RootState = RematchRootState<RootModel>
export type Dispatch = RematchDispatch<RootModel>
export const models: RootModel = { user, order, store, message, anonymousOrder }