import React from 'react'

interface IconProps {
  className: string
  color: string
}

function Print({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><path fill="none" d="M0 0h32v32H0z"/><path fill={color} d="M9.75 9a.25.25 0 0 0 .25-.25V6h12v2.75c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-4.5a.25.25 0 0 0-.25-.25H8.25a.25.25 0 0 0-.25.25v4.5c0 .138.112.25.25.25h1.5zM26 19a1 1 0 0 1-1 1h-1v-2.75a.25.25 0 0 0-.25-.25H8.25a.25.25 0 0 0-.25.25V20H7a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v6zM9.43 26c.27-1.53.57-3.57.57-5v-2h12v2c0 1.195-.345 3.425-.635 5H9.43zM7 10a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h.955c-.159 2.041-.699 4.812-.89 5.705A.246.246 0 0 0 7.31 28h15.495c.115 0 .215-.08.24-.195.166-.742.741-3.706.908-5.805H25a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H7zm4.25 11a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h9.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-9.5zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h7.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-7.5zm12-10a.25.25 0 0 0-.25.25v1.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-1.5a.25.25 0 0 0-.25-.25h-1.5z"/></g></svg>
  )
}

export default Print