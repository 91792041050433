import React from 'react'
import OrderNumber from './OrderNumber'
import Modal from './Modal'
import ItemList from './ItemList'
import HistoryTimer from './HistoryTimer'
import TextButton from './TextButton'
import DeliveryLabel from './DeliveryLabel'
import Contact from './Contact'
import PrintIcon from '../icons/Print'
import PrintOrder from './PrintOrder'
import AngleIcon from '../icons/Angle'
import CheckList from './CheckList'
import CheckIcon from '../icons/Check'
import styles from './OrderHistoryItem.module.css'
import { Order } from '../types/Order'
import logError from '../utils/logging'
import Banner from './Banner'

interface Props {
  item: Order
  modalIsActive: boolean
  setActiveModal: (id: string | null) => void
  prevId: string | null
  nextId: string | null
}

function OrderHistoryItem({ item, modalIsActive, setActiveModal, prevId, nextId }: Props) {
  const {
    id,
    orderNo,
    loopNumber,
    createdAt,
    deliveryType,
    deliveryFee,
    deliveryBongDisplayName,
    deliveryDisplayName,
    orderItemsFood,
    orderItemsGrocery,
    userName,
    phoneNumber,
    email,
    street,
    city,
    channelName
  } = item || {}
  const printEl = React.useRef<HTMLDivElement>(null)
  const [numberOfCheckedItems, setNumberOfCheckedItems] = React.useState(0)

  React.useEffect(() => {
    if (orderItemsGrocery && orderItemsGrocery.length > 0) {
      const checkedItems = orderItemsGrocery.filter((groceryItem) => groceryItem.status === 10)
      const numberOfChecked = checkedItems?.length || 0
      setNumberOfCheckedItems(numberOfChecked)
    }
  }, [orderItemsGrocery])

  function getArticleText() {
    if (orderItemsFood.length !== 1) {
      return `${orderItemsFood.length} artiklar`
    }
    return `${orderItemsFood.length} artikel`
  }

  function getGroceryText() {
    if (orderItemsGrocery.length > 0) {
      const checked = orderItemsGrocery.filter((groceryItem) => groceryItem.status === 10)

      return `Plockvaror: ${checked.length}/${orderItemsGrocery.length}`
    }
    return 'Plockvaror: -'
  }

  function getTimeFrom(date: string) {
    const dateObj = new Date(date)
    const hours = dateObj.getHours()
    const minutes = dateObj.getMinutes()

    const formattedHours = hours < 10 ? `0${hours}` : hours
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

    return `${formattedHours}:${formattedMinutes}`
  }

  function onOpenDetail() {
    setActiveModal(id)
  }

  function onPrint() {
    try {
      if (document) {
        const printContent = printEl.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if(frame && printContent){
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  return (
    <>
      <li>
        <div className={styles.item} onClick={onOpenDetail}>
          <div>
            <OrderNumber color='#94c11a' number={channelName === 'icakiosk' ? loopNumber : orderNo} />
          </div>
          <div className={styles.articles}>
            <span>{getArticleText()}</span>
          </div>
          <div className={styles.articles}>
            <span>{getGroceryText()}</span>
          </div>
          <div>Beställd {getTimeFrom(createdAt)}</div>
        </div>
      </li>
      <Modal
        maxWidth='938px'
        active={modalIsActive}
        onConfirm={() => null}
        onCancel={() => setActiveModal(null)}
        withClose={false}
      >
        <div className={styles.content}>
          <button className={styles.prevButton} disabled={!prevId} onClick={() => setActiveModal(prevId)} type='button'>
            <AngleIcon className={styles.prevIcon} direction='left' color='currentColor' />
            <span className={styles.hidden}>Föregående</span>
          </button>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={() => setActiveModal(null)} type='button'>
              <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7.19173 8.32304C7.11362 8.40115 7.11362 8.52778 7.19173 8.60589L10.5858 12L7.19173 15.3941C7.11362 15.4722 7.11362 15.5989 7.19173 15.677L8.3231 16.8083C8.4012 16.8864 8.52783 16.8864 8.60594 16.8083L12.0001 13.4142L15.3942 16.8083C15.4723 16.8864 15.5989 16.8864 15.677 16.8083L16.8084 15.677C16.8865 15.5989 16.8865 15.4722 16.8084 15.3941L13.4143 12L16.8084 8.60589C16.8865 8.52778 16.8865 8.40115 16.8084 8.32304L15.677 7.19167C15.5989 7.11357 15.4723 7.11357 15.3942 7.19167L12.0001 10.5858L8.60594 7.19167C8.52783 7.11357 8.4012 7.11357 8.3231 7.19167L7.19173 8.32304Z'
                  fill='#cf2e05'
                />
              </svg>
              <span className={styles.hidden}>Stäng</span>
            </button>
            <div className={styles.modalHeader}>
              <OrderNumber
                color='#94c11a'
                fontSize="2rem"
                height="40px"
                number={channelName === 'icakiosk' ? loopNumber : orderNo}
              />
              {deliveryType && deliveryType > 0 ? (
                <DeliveryLabel type={deliveryType} bongTitle={deliveryBongDisplayName} dynamicHeight={true}/>
              ) : null}
              <HistoryTimer createdAt={createdAt} />
              {(userName || phoneNumber || email || street) && (
                <Contact
                  name={userName}
                  phoneNumber={phoneNumber}
                  street={street}
                  city={city}
                  email={email}
                  deliveryType={deliveryDisplayName}
                  deliveryFee={deliveryFee}
                />
              )}
            </div>
            <Banner theme="success">
              <span>Levererad</span>
            </Banner>
            {orderItemsFood && orderItemsFood.length > 0 && (
              <div className={styles.modalSection}>
                <h4 className={styles.modalSectionHeading}>Restaurang</h4>
                <ItemList orderId={id} items={orderItemsFood} />
              </div>
            )}
            {orderItemsGrocery && orderItemsGrocery.length > 0 && (
              <div className={styles.modalSection}>
                <h4 className={styles.modalSectionHeading}>
                  Plockvaror {numberOfCheckedItems} av {orderItemsGrocery.length}
                </h4>
                <CheckList orderId={id} items={orderItemsGrocery} updateItemStatus={() => null} />
              </div>
            )}
            <div className={styles.modalFooter}>
              <TextButton onClick={onPrint} text='Skriv ut' style={{ padding: '1.5rem' }}>
                <PrintIcon color='currentColor' className={styles.printIcon} />
              </TextButton>
            </div>
          </div>
          <button className={styles.nextButton} disabled={!nextId} onClick={() => setActiveModal(nextId)} type='button'>
            <AngleIcon className={styles.nextIcon} direction='right' color='currentColor' />
            <span className={styles.hidden}>Nästa</span>
          </button>
        </div>
      </Modal>
      <div ref={printEl}>
        <PrintOrder order={item} />
      </div>
    </>
  )
}
export default OrderHistoryItem
