import React from 'react'
import { connect } from 'react-redux'
import Switch from './Switch'
import TimeIcon from '../icons/Time'
import ArrowIcon from '../icons/Arrow'
import ResetIcon from '../icons/Reset'
import styles from './Menu.module.css'
import MarketPlaceIcon from '../icons/MarketPlace'
import Check from '../icons/Check'
import { Dispatch, RootState } from '../models'
import { ChannelType } from '../types/Store'
import logError from '../utils/logging'
import SwapIcon from '../icons/Swap'
import { applicationVersion } from '../constants'
import RadioButton from './RadioButton'


type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

interface MenuProps extends Props {
  isOpen?: boolean
  updateStatus: (status: boolean) => void
  filter: {
    showKioskOrders: boolean
    setShowKioskOrders: React.Dispatch<React.SetStateAction<boolean>>
    showProntoOrders: boolean
    setShowProntoOrders: React.Dispatch<React.SetStateAction<boolean>>
  }
  channelTypes: ChannelType[]
  chosenSoundOption?: number
  onSelectSoundOption?: (option: number) => void
}

function Menu ({
  isOpen = false,
  updateStatus,
  selectedStore,
  resetStore,
  logout,
  filter,
  channelTypes,
  chosenSoundOption,
  onSelectSoundOption
}: MenuProps) {
  const [printOrders, setPrintOrders] = React.useState(false)
  const {
    showKioskOrders,
    setShowKioskOrders,
    showProntoOrders,
    setShowProntoOrders
  } = filter || {}

  React.useEffect(() => {
    shouldPrintOrders()
  }, [])


  const soundOptions = ['Spela ljud', 'Spela inte ljud', 'Spela ljud efter 60s inaktivitet']

  function shouldPrintOrders () {
    const settingsStorage = localStorage.getItem('settings')
    const settings = settingsStorage ? JSON.parse(settingsStorage) as { printOrders: boolean } : null
    if (
      settings &&
      Object.prototype.hasOwnProperty.call(settings, "printOrders") &&
      settings?.printOrders === true
    ) {
      setPrintOrders(true)
    }
  }

  function savePrintSettings () {
    try {
      const settingsStorage = localStorage.getItem('settings')
      const settings = settingsStorage ? JSON.parse(settingsStorage) as { printOrders: boolean } : null
      let settingsObj
      if (settings) {
        settingsObj = {
          ...settings,
          printOrders: !printOrders
        }
      } else {
        settingsObj = {
          printOrders: !printOrders
        }
      }
      localStorage.setItem('settings', JSON.stringify(settingsObj))
      setPrintOrders((prev) => !prev)
    } catch (error) {
      logError(error as Error)
    }
  }

  return (
    <>
      <div className={isOpen ? styles.activeMenu : styles.menu}>
        <div className={styles.menuHeading}>
          <span className={styles.storeNameWithButton}>
            {selectedStore?.name}
          </span>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.settingsMenu}>
            <p className={styles.settingsMenuHeading}>Inställningar</p>
            <div className={styles.settingsItem}>
              <Switch
                checked={printOrders}
                onChange={savePrintSettings}
                label='Skriv ut bong'
              />
            </div>
            <div className={styles.soundSettings}>
              <p className={styles.settingsMenuHeading}>Ljudinställningar</p>
              {onSelectSoundOption && soundOptions.map((option, index) => {
                return (
                  <RadioButton
                    key={index}
                    checked={chosenSoundOption === index} id={`soundOption-${index}`} name={`sounds`} onChange={() => onSelectSoundOption(index)}>
                    {option}
                  </RadioButton>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.menuItem}>
          <div className={styles.filterMenu}>
            <div className={styles.menuItemText}>
              <span className={styles.filterMenuHeading}>Visa ordrar från</span>
            </div>
            <div className={styles.filter}>
              <div>
                <input
                  className={styles.filterInput}
                  onChange={() => setShowKioskOrders((prev) => !prev)}
                  id='kioskCheckbox'
                  type='checkbox'
                  checked={showKioskOrders}
                />
                <label className={styles.filterLabel} htmlFor='kioskCheckbox'>
                  <span className={styles.filterCheckbox}>
                    <Check
                      className={styles.filterCheckIcon}
                      color='currentColor'
                    />
                  </span>
                  <span className={styles.filterName}>Kiosk</span>
                </label>
              </div>
              <div>
                <input
                  className={styles.filterInput}
                  onChange={() => setShowProntoOrders((prev) => !prev)}
                  id='prontoCheckbox'
                  type='checkbox'
                  checked={showProntoOrders}
                />
                <label className={styles.filterLabel} htmlFor='prontoCheckbox'>
                  <span className={styles.filterCheckbox}>
                    <Check
                      className={styles.filterCheckIcon}
                      color='currentColor'
                    />
                  </span>
                  <span className={styles.filterName}>Pronto App</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.menuItem}>
          <a
            className={styles.menuItemLink}
            href={`/butik/${selectedStore?.id}/historik`}
          >
            <div className={styles.menuItemText}>
              <TimeIcon
                className={styles.orderHistoryIcon}
                color='var(--text)'
              />
              <span>Orderhistorik</span>
            </div>
            <div className={styles.historyIcon}>
              <ArrowIcon
                direction='right'
                color='var(--red)'
                className={styles.orderHistoryArrow}
              />
            </div>
          </a>
        </div>
        <div className={styles.menuItem}>
          <a
            className={styles.menuItemLink}
            href={`/butik/${selectedStore?.id}/orders`}
          >
            <div className={styles.menuItemText}>
              <ArrowIcon
                direction='left'
                color='currentColor'
                className={styles.backArrow}
              />
              <span style={{ marginLeft: '0.25rem' }}>Tillbaka till start</span>
            </div>
          </a>
        </div>
        <div className={styles.menuItem}>
          <button
            className={styles.boldButton}
            onClick={() => window.location.reload()}
            type='button'
          >
            <div className={styles.boldText}>
              <ResetIcon
                className={styles.changeStoreIcon}
                color='currentColor'
              />
              <span>Ladda om sidan</span>
            </div>
          </button>
        </div>
        {channelTypes && channelTypes.some(obj => obj.headerValue === 'icacatering') && (
          <div className={styles.menuItem}>
            <a
              className={styles.menuItemLink}
              href={`/butik/${selectedStore?.id}/catering`}
            >
              <div className={styles.boldText}>
                <SwapIcon
                  color='currentColor'
                  className={styles.refreshIcon}
                />
                <span>Byt tjänst till catering</span>
              </div>
            </a>
          </div>
        )}
        <div className={styles.menuItem}>
          <button
            className={styles.boldButton}
            onClick={() => { resetStore(); logout() }}
            type='button'
          >
            <div className={styles.boldText}>
              <MarketPlaceIcon className={styles.logoutIcon} color='currentColor' />
              <span>Logga ut</span>
            </div>
          </button>
        </div>
        <p className={styles.appVersion}>Applikationsversion: {applicationVersion}</p>
      </div>
      <div
        className={isOpen ? styles.activeOverlay : styles.overlay}
        onClick={() => updateStatus(false)}
      >
      </div>
    </>
  )
}

function mapState (state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
    stores: state.store.userStores
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    selectStore: dispatch.store.selectStore,
    resetStore: dispatch.store.resetStore,
    logout: dispatch.user.logout
  }
}

export default connect(mapState, mapDispatch)(Menu)
