import React from 'react'
import WarningIcon from '../icons/Warning'
import ArrowIcon from '../icons/Arrow'
import styles from './Alert.module.css'

interface Props {
  heading: string
  text: string
  linkText: string
}

function Alert({
  heading,
  text,
  linkText
}: Props) {
  
  return (
    <div className={styles.alert}>
      <div className={styles.alertIconWrapper}>
        <WarningIcon className={styles.alertIcon} color='#ffff' /> 
      </div>
      <div className={styles.alertContent}>
        <div className={styles.alertInfo}>
          <h3 className={styles.alertHeading}>{heading}</h3>
          <p className={styles.alertText}>{text}</p>
        </div>
        <div className={styles.alertLink}>
          <span>{linkText}</span>
          <ArrowIcon
            className={styles.arrowIcon}
            direction='right'
            color='#cf2e05'
          />
        </div>
      </div>
    </div>
  )
}

export default Alert