/* eslint-disable indent */
import React from 'react'
import { format } from 'date-fns'
import OrderNumber from './OrderNumber.js'
import PrintIcon from '../icons/Print.js'
import PrintCateringOrder from './PrintCateringOrder.js'
import CheckIcon from '../icons/Check.js'
import { getPaymentMethod } from '../helpers/getPaymentMethod.js'
import styles from './CateringOrderItem.module.css'
import { Order, PrintedByUserWithType } from '../types/Order.js'
import logError from '../utils/logging.js'
import getReprintStatusOrders from '../helpers/getReprintStatusOrders.js'

interface Props {
  item: Order
  userId?: string
  onLineItemClick?: (id: string) => void
  status: string | number
  storeId: string
  onPrintOrder?: (obj: { storeId: string, orderIds: string, userId?: string, type: string }) => void
  updatedOrderModal?: boolean
}

function CateringOrderLineItem({
  item,
  userId,
  onLineItemClick,
  status,
  storeId,
  onPrintOrder,
  updatedOrderModal = false
}: Props) {
  const {
    id,
    deliveryDisplayName,
    deliveryBongDisplayName,
    orderItemsCatering,
    deliveryTimeSlot,
    paymentStatus,
    paymentProviderType,
    orderNo,
    printedByUser,
    printedByUsersWithType,
    orderHistory
  } = item || {}

  const printedCateringOrder = () => {
     const printed = printedByUsersWithType?.find(print => 
      print.actions?.some(action => action.type === "IncomingOrders" && print.userId === userId)
    )?.actions?.find(action => action.type === "IncomingOrders")
    
    if (printed) {
      return true
    } else {
      return false
    }
  }

    // Remove when printedByUser[] property becomes obsolete
    const printedOrder = React.useMemo(() => {
      if (printedByUser.includes(userId || '')) {
        return true
      } else {
        return false
      }
    }, [printedByUser.length])

  const today = new Date().toISOString().split('T', 1)[0]
  const deliveryDate = format(new Date(item.deliveryDate), 'yyyy-MM-dd')
  const orderNoColor =
    status === 5 ? '#ededed' : status === 'upcoming' ? '#fdc300' : '#94c11a'
  const printEl = React.useRef<HTMLDivElement>(null)
  const orderLastChanged = orderHistory && orderHistory[0].date
  const [needsReprinting, setNeedsReprinting] = React.useState(false)

  React.useEffect(() => {
    if (userId) { 
      const reprintStatus = getReprintStatusOrders(printedByUser, printedByUsersWithType as PrintedByUserWithType[], userId, orderLastChanged as string) as boolean
      setNeedsReprinting(reprintStatus)
    }
   }, [item, item.printedByUsersWithType, item.orderHistory])

  const paymentInfo = React.useMemo(() => {
    let infoText = 'Reserverad'
    if (paymentStatus === 200) {
      infoText = 'Betald'
    } else if (paymentStatus === 300) {
      infoText = 'Misslyckad'
    } else if (paymentStatus !== 200 && deliveryDate === today) {
      infoText = 'Pågående'
    }
    return infoText
  }, [paymentStatus])

  const paymentMethod = React.useMemo(() => {
    return getPaymentMethod(paymentProviderType)
  }, [paymentProviderType])

  function getArticleText() {
    const numberOfItems = orderItemsCatering?.reduce((prev, current) => {
      return prev + current.amount
    }, 0)
    if (numberOfItems > 1 || numberOfItems === 0) {
      return `${numberOfItems} artiklar`
    }
    return `${numberOfItems} artikel`
  }

  function onOpenDetail(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const element = event.target as HTMLElement
    if (element.tagName !== 'svg' && element.tagName !== 'path') {
      onLineItemClick && onLineItemClick(id)
    }
  }

  function onPrint() {
    try {
      if (document && printEl !== null) {
        const printContent = printEl.current?.innerHTML
        const frame = (document.getElementById('printframe')  as HTMLIFrameElement).contentWindow
        if(frame && printContent) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onPrintStatus() {
    onPrintOrder && onPrintOrder({
      storeId,
      orderIds: id,
      userId,
      type: 'IncomingOrders'
    })
    onPrint()
  }

  return (
    <>
      <li>
        <div 
        className={styles.item}
          onClick={(event) => !updatedOrderModal && onOpenDetail(event)}
          style={{ cursor: updatedOrderModal ? 'default' : 'pointer' }}>
          <div className={styles.text}>
            <div className={styles.orderNo} style={{ paddingRight: !updatedOrderModal ? '2.5rem' : '0' }}>
              <OrderNumber
                color={orderNoColor}
                textColor={status === 5 ? '#bdbbb9' : '#FFFFFF'}
                number={orderNo}
                dynamicWidth={true}
              />
            </div>

            {!updatedOrderModal ? (
              <>
                <div className={styles.articles}>
                  <span>{getArticleText()}</span>
                </div>
                <div className={styles.paymentStatus}>
                  {paymentMethod}
                  {paymentMethod === 'Betala i butik' ? '' : `: ${paymentInfo}`}
                </div>
                <div className={styles.deliveryTime}>
                  {deliveryBongDisplayName
                      ? deliveryBongDisplayName
                      : deliveryDisplayName}
                  {deliveryTimeSlot ? `: ${item.deliveryTimeSlot}` : ''}
                </div>
                <div className={styles.printStatus}>
                  {(printedOrder || printedCateringOrder()) && !needsReprinting ? (
                    <>
                      <div className={styles.printedLabel}>
                        <div className={styles.checkIconBox}>
                          <CheckIcon className={styles.checkIcon} color='#2F6147' />
                        </div>
                        Utskriven
                      </div>
                      <button
                          onClick={(event) => {
                            event.stopPropagation()
                            onPrint()
                          } }
                          className={styles.printedButton}
                        >
                        <div className={styles.printIconBox}>
                          <PrintIcon color='#cf2e05' className={styles.printIcon} />
                        </div>
                      </button>
                    </>
                    ) : (
                      <button
                        onClick={onPrintStatus}
                        className={styles.printedButton}
                      >
                        <div className={styles.printIconBox}>
                          <PrintIcon color='#cf2e05' className={styles.printIcon} />
                        </div>
                      </button>
                    )}
                </div>
              </>
          ) : (
            <>
              <div>Leveransdatum: {deliveryDate}</div>
              <div>Ändrad: {orderHistory && format(new Date(orderHistory[orderHistory.length - 1].date), 'yyyy-MM-dd')}</div>
              <div>Ändrad av: Butik</div>
            </>
          )}
          </div>
        </div>
      </li>
      <div ref={printEl}>
        <PrintCateringOrder order={item} />
      </div>
    </>
  )
}

export default CateringOrderLineItem
