import React, { FormEvent } from "react"
import { connect } from "react-redux"
import { FETCHING, ERROR, IDLE } from "../helpers/constants"
import styles from "./Login.module.css"
import { useNavigate } from "react-router-dom"
import { Dispatch, RootState } from "../models"

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function Login({ login, resetStore, user, status }: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [username, setUsername] = React.useState("")
  const [isValidUsername, setIsValidUsername] = React.useState(IDLE)
  const [password, setPassword] = React.useState("")
  const [isValidPassword, setIsValidPassword] = React.useState(IDLE)
  const navigate = useNavigate()

  React.useEffect(() => {
    inputRef.current?.focus()
  }, [])

  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      whereToGoNext()
    }
  }, [user])

  function whereToGoNext() {
    const stores = user?.claims?.filter((item) => item.type === "store") || []

    if (stores.length === 1) {
      const [store] = stores
      navigate(`/butik/${store.value}/tjanster`)
    } else {
      navigate(`/butik`)
    }
  }

  function validateUsername() {
    if (username && username.length !== 0) {
      if (username.length > 2) {
        setIsValidUsername("TRUE")
        return true
      } else {
        setIsValidUsername(ERROR)
      }
    } else {
      setIsValidUsername(IDLE)
    }
    return false
  }

  function validatePassword() {
    if (password && password.length !== 0) {
      if (password.length > 2) {
        setIsValidPassword("TRUE")
        return true
      } else {
        setIsValidPassword(ERROR)
      }
    } else {
      setIsValidPassword(IDLE)
    }
    return false
  }

  function validateForm() {
    const validUsername = validateUsername()
    const validPassword = validatePassword()

    if (validUsername === false || validPassword === false) {
      if (!validUsername) {
        setIsValidUsername(ERROR)
      }
      if (!validPassword) {
        setIsValidPassword(ERROR)
      }
      return false
    }
    return true
  }

  function handleLogin(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    //Make sure old token is discarded
    if(localStorage.getItem('user') !== null) {
      localStorage.removeItem('user')
    }
    
    const isValidForm = validateForm()

    if (isValidForm) {
      resetStore()
      
      login({
        username,
        password,
      })
    }
  }

  return (
    <div className={styles.login}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Logga in</h1>
        <form onSubmit={handleLogin}>
          <div>
            <input
              ref={inputRef}
              className={styles.input}
              onFocus={() => setIsValidUsername("IDLE")}
              onBlur={validateUsername}
              placeholder="Användarnamn"
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              autoCapitalize="off"
              value={username}
            />
            {isValidUsername === ERROR && <p className={styles.error}>Ange ett giltigt användarnamn</p>}
          </div>
          <div>
            <input
              className={styles.input}
              onFocus={() => setIsValidPassword("IDLE")}
              onBlur={validatePassword}
              placeholder="Lösenord"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              value={password}
            />
            {isValidPassword === ERROR && <p className={styles.error}>Ange ett giltigt lösenord</p>}
          </div>
          {status.status === ERROR && (
            <p className={styles.error}>{status.message}</p>
          )}
          <button
            className={styles.submit}
            disabled={status.status === FETCHING}
            type="submit"
          >
            {status.status === FETCHING ? 'Laddar...' : 'Logga in'}
          </button>
        </form>
      </div>
    </div>
  )
}

function mapState(state: RootState) {
  return {
    user: state.user.current,
    status: state.user.status,
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    resetStore: dispatch.store.resetStore,
    login: dispatch.user.login,
  }
}

export default connect(mapState, mapDispatch)(Login)
