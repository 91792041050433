import React from 'react'

function Catering({ className }: {className: string}) {
  return (
    
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <path d="M182.19 107.07C176.16 107.07 164.9 107 158.07 107.07C142.37 107.22 111.19 107.07 95.5701 107.07C94.0401 107.07 93.3101 107.83 93.3101 108.99C93.3101 109.95 93.8201 112.84 94.0801 113.74C94.3801 114.75 96.4701 114.75 96.8501 114.74C97.5201 114.7 98.2601 114.68 99.0401 114.66C99.0201 115.26 99.3601 116.33 100.18 118.24C101.48 121.27 100.99 122.38 109.48 122.38C116.17 122.38 126.39 122.34 138.41 122.38C153.82 122.43 162.51 122.75 167.93 122.38C178.52 121.66 178.53 121.55 180.38 116.72C180.76 115.73 180.74 115.13 180.57 114.74H182.96C184.02 114.74 184.97 115 185.71 114.27C186.06 113.93 186.42 109.95 186.42 108.99C186.42 107.22 185.03 107.07 182.18 107.07H182.19Z" fill="#9B0600"/>
      <path d="M99.3998 105.23C101.32 105.54 111.55 105.66 137.09 105.66C150.45 105.66 163.21 105.75 176.57 105.73C178.19 105.73 182.38 105.34 183.28 103.41C184.18 101.5 183.69 98.34 183.37 96.44C182.22 89.62 179.58 82.76 175.87 77.07C168.22 65.32 154.95 60.12 142.03 59.67C144.41 58.81 146.03 56.35 145.85 53.88C145.74 52.37 145.15 50.8 143.81 49.92C142.59 49.11 140.9 48.84 139.45 49.05C136.68 49.45 134.65 51.74 134.64 54.4C134.64 55.9 135.06 57.5 136.26 58.53C136.84 59.04 137.56 59.43 138.33 59.68C123.33 60.27 109.31 68.01 101.74 82.36C98.8898 87.75 96.2098 94 96.1398 100.28C96.1098 103.37 96.4598 104.75 99.3998 105.23Z" fill="#9B0600"/>
      <path d="M120.15 73.48C119.79 72.48 118.3 71.83 117.08 72.02C114.41 72.43 110.74 76.54 108.56 80.03C104.99 85.73 104.05 93.08 105.26 93.47C106.16 93.76 107.25 91.8 108.7 89.17C109.94 86.92 111.88 84.01 114.33 80.9C115.22 79.78 119.18 75.93 119.34 75.78C120.07 75.06 120.35 74.04 120.15 73.49V73.48Z" fill="#F39100"/>
      <path d="M128.23 66.05C127.78 65.36 126.98 65.1 126.13 65.22C122.75 65.69 121.38 67.5 121.01 68.2C120.48 69.22 120.83 71.75 123.82 71.33C125 71.16 126.44 70.02 127.29 69.39C128.78 68.28 128.65 66.7 128.22 66.04L128.23 66.05Z" fill="#F39100"/>
      <path d="M140.05 50.58C139.07 50.48 137.3 51.52 136.81 53.15C136.09 55.58 139.61 56.53 139.88 54.35C140.08 52.78 141.68 50.75 140.04 50.59L140.05 50.58Z" fill="#F39100"/>
      <path d="M85.7499 80.33C85.8199 74.19 85.9299 69.22 86.1299 63.07C86.1799 61.32 87.3099 53.06 85.8299 53.03C85.0199 52.71 23.4999 53.41 20.2699 53.52C18.2099 54 18.9299 55.92 18.9399 57.69C18.9399 60.69 18.9599 63.1 18.9799 66.1C19.0499 74.41 19.1499 81.55 19.4399 89.85C19.4899 91.25 20.9199 90.77 21.9399 90.83C23.1099 90.89 29.8099 91.14 31.9899 91.19C34.6799 91.25 37.3699 91.3 40.0599 91.34C50.5699 91.49 53.9099 91.13 64.5199 91.13C68.1399 91.13 84.0899 90.91 84.8799 90.87C86.3199 90.79 85.7899 87.79 85.7699 86.67C85.7399 84.36 85.7499 82.64 85.7499 80.33Z" fill="#F39100"/>
      <path d="M87.5399 50.47C87.4799 49.55 86.8899 48.8 86.1199 48.7C85.4199 48.6 84.3999 48.54 83.3999 48.47C81.1899 48.33 78.0699 48.2 74.1399 48.09C66.5699 47.88 56.5299 47.75 47.2899 47.75C39.1199 47.75 32.1299 47.85 27.0899 48.03C24.3099 48.13 22.1499 48.26 20.6599 48.4C20.1999 48.44 19.5799 48.51 19.0799 48.6C18.3299 48.74 17.7599 49.49 17.7199 50.4C17.6899 51.15 17.2699 58.43 17.5499 60.09C17.6499 60.68 17.9799 61.18 18.4299 61.44C18.8799 61.7 19.9699 61.35 19.9699 61.35C20.9999 60.71 22.9299 59.53 24.9799 59.53C26.5799 59.53 27.5399 60.06 28.5699 60.62C29.7099 61.24 30.8899 61.89 32.9299 61.89C34.5799 61.89 35.7699 61.31 36.9299 60.74C38.1399 60.15 39.3899 59.53 41.2899 59.53C42.9499 59.53 44.1899 60.04 45.5099 60.59C47.0499 61.23 48.6399 61.89 51.0499 61.89C53.1899 61.89 54.6099 61.32 56.1199 60.71C57.5499 60.13 59.0299 59.54 61.2099 59.54C63.2199 59.54 64.6099 60.17 65.9399 60.78C67.1899 61.35 68.3699 61.89 69.9299 61.89C71.7299 61.89 73.1299 61.32 74.6199 60.72C76.0699 60.13 77.5699 59.53 79.4599 59.53C82.8899 59.53 84.4799 60.49 85.3799 61.21C85.8299 61.57 86.4099 61.64 86.8999 61.38C87.3999 61.13 87.7599 60.59 87.8599 59.95C88.0899 58.44 87.5299 50.48 87.5299 50.48L87.5399 50.47Z" fill="#E3000B"/>
      <path d="M87.8202 69.93C87.8202 68.55 85.1102 68.7 83.1301 68.62C74.4801 68.2 63.4301 68.12 52.8401 68.26C42.2501 68.11 31.2001 68.2 22.5501 68.62C20.5701 68.7 18.7001 68.44 17.6701 69.04C17.2101 69.31 17.2201 70.55 17.4101 74.28C17.6901 79.83 17.3201 79.85 18.4101 79.98C24.6701 80.74 85.6602 80.63 87.1602 80.06C87.6402 79.88 87.9902 78.74 88.0002 75.86C88.0201 72.8 87.8202 72.65 87.8202 69.93Z" fill="#9B0600"/>
      <path d="M87.05 90.81C81.49 90.65 65.98 90.7 51.67 90.72C36.63 90.74 23.59 90.95 20.22 90.76C19.19 90.7 18.02 90.7 17.37 90.92C16.97 91.06 15.82 91.26 16.63 93.47C18.27 97.99 18.27 97.59 27.71 98.01C29.7 98.1 31.92 98.18 34.58 98.23C35.13 100.93 34.9 101.52 38.7 101.95C41.08 102.22 44.46 102.29 48.41 102.32C47.91 105.17 47.7 109.27 47.37 113.65C47.35 113.94 47.09 114.19 46.78 114.19C46.76 114.19 44.67 114.26 43.74 114.32C43.74 114.32 37.93 114.72 37.13 114.85C32.07 115.7 26.96 118.66 26.48 119.03C25.99 119.42 25.77 120.03 25.92 120.62C26.06 121.21 26.54 121.67 27.16 121.82C28.5 122.14 30.99 122.29 34.78 122.29C36.93 122.29 39.33 122.25 41.88 122.2C41.88 122.2 65.07 122.37 68.71 122.37C72.84 122.37 75.25 122.23 76.97 121.95C77.51 121.86 77.98 121.53 78.21 121.06C78.45 120.59 78.43 120.04 78.16 119.58C76.76 117.22 73.89 116.06 68.23 114.95C64.21 114.16 60.02 114.13 57.77 114.12C57.77 114.12 56.66 114.03 56.56 113.89C56.46 113.76 56.51 113.32 56.52 113.27C56.84 111.8 56.41 108.53 55.94 106.17C55.65 104.73 55.51 103.45 55.38 102.35C60.3 102.34 63.2 102.21 65.53 102C69.74 101.63 70.29 101.27 70.92 98.22C74.4 98.17 77.45 98.08 79.94 97.94C87.49 97.5 86.87 97.31 88.03 94.48C89.85 90.02 89.05 90.85 87.06 90.79L87.05 90.81Z" fill="#E3000B"/>
      <path d="M60.8499 39.71C60.5299 38.76 59.8399 37.92 58.6599 37.42C55.7199 36.18 54.9599 37.92 52.1299 37.7C48.8699 37.44 49.8799 34.23 49.8799 34.13C48.1699 33.63 42.5999 34.52 40.9299 38.08C40.2999 39.41 40.1099 41.05 40.2799 42.62C40.8399 42.25 41.5399 41.97 42.4099 41.81C47.4299 40.89 48.1599 43.68 54.8999 44.15C60.6899 44.55 61.2799 40.95 60.8599 39.71H60.8499Z" fill="#E3000B"/>
      <path d="M64.5602 47.89C58.3002 47.78 51.5302 47.75 45.0502 47.75C43.1102 47.75 41.2402 47.75 39.4302 47.77C46.5802 48.06 48.2802 52.05 53.1002 52.86C62.6502 54.46 64.1802 50.45 64.4602 49.75C64.6902 49.18 64.7502 48.31 64.5602 47.89Z" fill="#9B0600"/>
      <path d="M60.8501 39.71C61.2101 40.96 60.6801 44.55 54.8901 44.15C48.1501 43.68 47.4201 40.88 42.4001 41.81C41.5401 41.97 40.8401 42.25 40.2701 42.62C38.4501 43.82 38.0801 45.94 38.2401 47.81C40.3201 47.76 42.7501 47.78 43.3201 47.83C47.0301 48.24 49.4101 49.98 54.9601 50.48C64.2501 51.32 64.8301 47.09 64.8901 47.15C65.6501 43.05 62.6701 39.88 60.8401 39.71H60.8501Z" fill="#F39100"/>
      <path d="M193.01 123.37C193.03 122.75 190.03 121.84 163.94 122.17C116.23 122.78 28.5199 121.97 13.6199 121.88C12.2199 121.88 9.79991 121.88 9.85991 123.29C10.2499 131.62 2.27991 165.8 8.18991 165.8C18.6899 165.8 28.2399 159.73 39.3899 159.73C45.4999 159.73 57.9499 165.8 69.1499 165.8C78.2199 165.8 89.1799 159.58 98.3799 159.62C107.26 159.66 122.43 165.8 131.71 165.8C143.51 165.8 154.1 159.73 162.44 159.73C169.44 159.73 185.56 165.8 192.13 165.8C198.2 165.8 192.54 141.21 193.01 123.36V123.37Z" fill="#F39100"/>
    </svg>
  )
}

export default Catering





