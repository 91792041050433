import React from "react"
import { format } from 'date-fns'
import { getDayOfWeek } from '../helpers/getWeekday'
import { getPaymentMethod } from "../helpers/getPaymentMethod"
import { formatVariant } from "../helpers/formatVariant"
import styles from './PrintOrder.module.css'
import { Order } from "../types/Order"
import { getFormattedPrice } from "../helpers/formatPrice"

function PrintCateringOrder({ order }: {order: Order}) {
  const {
    userName,
    phoneNumber,
    email,
    street,
    city,
    postNumber,
    orderItemsCatering,
    restaurantMessage,
    deliveryType,
    deliveryDisplayName,
    deliveryTimeSlot,
    orderNo,
    paymentStatus,
    paymentProviderType,
    totalPrice,
    deliveryFee,
    totalDiscount,
    storeComment
  } = order || {}

  const totalNumberOfArticles = React.useMemo(() => {
    return order.orderItemsCatering?.reduce((acc, item) => acc + item.amount, 0)
  }, [order])

  const today = new Date().toISOString().split('T', 1)[0]
  const deliveryDate = format(new Date(order?.deliveryDate), 'yyyy-MM-dd')
  const dayOfWeek = React.useMemo(() => {
    return getDayOfWeek(deliveryDate)
  }, [deliveryDate])
  const paymentMethod = React.useMemo(() => {
    return getPaymentMethod(paymentProviderType)
  }, [paymentProviderType])

  function getPaymentStatus(){
    let infoText = 'Reserverad'
    if (paymentStatus === 200) {
      infoText = 'Betald'
    } else if (paymentStatus === 300) {
      infoText = 'Misslyckad'
    } else if (paymentStatus === 400)  {
      infoText = 'Avbruten'
    } else if (paymentStatus !== 200 && deliveryDate === today) {
      infoText = 'Pågående'
    }
    return infoText
  }

  return order ? (
    <div
      className={styles.printContainer}
      style={{ 
        fontFamily: 'Ica Text Ny', 
        fontSize: '0.75rem', 
        lineHeight: '1.5rem', 
        color: '#3a3a3a' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {deliveryDate && (
          <span style={{ fontFamily: 'Ica Text Ny', fontSize: '1rem', fontWeight: 700 }}>
            {dayOfWeek}, {deliveryDate} {deliveryTimeSlot ? `, ${deliveryTimeSlot}` : ''}
          </span>
        )}
        <h2
          style={{
            border: 'solid 1px #3a3a3a',
            borderRadius: '4px',
            width: 'fit-content',
            padding: '0.25rem 1.25rem',
            margin: '1.25rem 0rem',
            fontFamily: 'ICA Rubrik',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            fontWeight: 700,
          }}
        >
          {orderNo.toUpperCase()}
        </h2>
      </div>

      {/* LEVERANS, KUNDUPPGIFTER, BETALNING */}
      <div 
        style={{ 
          border: '1px solid #868686',
          borderRadius: '4px',
          marginBottom: '0.5rem'
        }}>
        <p 
          style={{ 
            fontSize: '0.875', 
            lineHeight: '1.25rem', 
            fontFamily: 'ICA Text Ny', 
            fontWeight: 700, 
            padding: '0.5rem 1rem',
            borderBottom: '1px solid #868686',
            margin: '0'
          }}>
          Leverans
        </p>
        <div
          style={{
            padding: '0.5rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1rem' 
          }}>
          {deliveryDisplayName && 
          <span>Leveranssätt: {deliveryDisplayName}</span>
          }
          {deliveryDate && (
            <span>
              {deliveryTimeSlot ? 'Leveransdag och tid: ' : 'Leveransdag: '}
              {dayOfWeek}, {deliveryDate}{deliveryTimeSlot ? `, ${deliveryTimeSlot}` : ''}
            </span>
          )}
          {deliveryType === 1000 && (
            <p>
              {street && postNumber && city && `Leveransadress: ${street}, ${postNumber} ${city}`}
            </p>
          )}
          {restaurantMessage && (
            <span>
              Meddelande från kund: {restaurantMessage}
            </span>
          )}
          {storeComment && (
            <span>
              Intern butikskommentar: {storeComment}
            </span>
          )}
        </div>
      </div>
      <div 
        style={{ 
          border: '1px solid #868686',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '0.5rem'
        }}>
        <p 
          style={{ 
            fontSize: '0.875', 
            lineHeight: '1.25rem', 
            fontFamily: 'ICA Text Ny', 
            fontWeight: 700, 
            padding: '0.5rem 1rem',
            borderBottom: '1px solid #868686',
            margin: '0'
          }}>
          Kunduppgifter
        </p>
        <div
          style={{
            padding: '0.5rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1rem' 
          }}> 
          {userName && <span>Namn: {userName}</span>}
          {phoneNumber && <span>Telefonnummer: {phoneNumber}</span>}
          {email && <span>Mejl: {email}</span>}
        </div>
      </div>
      <div 
        style={{ 
          border: '1px solid #868686', 
          borderRadius: '4px',
          marginBottom: '1.5rem'
        }}>
        <p 
          style={{ 
            fontSize: '0.875', 
            lineHeight: '1.25rem', 
            fontFamily: 'ICA Text Ny', 
            fontWeight: 700, 
            padding: '0.5rem 1rem',
            borderBottom: '1px solid #868686',
            margin: '0'
          }}>
          Betalning
        </p>

        <div
          style={{
            padding: '0.5rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.75rem',
            lineHeight: '1rem' 
          }}>
          <span style={{ fontWeight: '700' }}>
            Betalsätt: {paymentMethod}
          </span>
          {paymentMethod !== 'Betala i butik' && 
          <span>
            Betalstatus: {getPaymentStatus()}
          </span>
          }
        </div>
      </div>

      {/* BESTÄLLNING */}
      <h2
        style={{
          padding: '0',
          margin: '0 0 0.5rem',
          fontFamily: 'ICA Text Ny',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          fontWeight: 700,
        }}
      >
        Beställning
      </h2>
      <div>
        {orderItemsCatering &&
          orderItemsCatering.length > 0 &&
          orderItemsCatering.map(({ id, amount, name, priceGross, variantName, message }) => {
            if (amount > 0) {
              return (
                <div 
                  key={id} 
                  style={{ 
                    border: '1px solid #868686',
                    borderRadius: '4px',
                    display: 'block',
                    marginBottom: '0.5rem',
                    pageBreakInside: 'avoid'
    
                  }}>
                  <div style={{ paddingBottom: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                    <span 
                      style={{ 
                        fontFamily: 'ICA Text Ny',  
                        fontSize: '0.75rem', 
                        fontWeight: 700, 
                        padding: '0.75rem 1rem 0',
                      }}>
                      {name}
                    </span>
                    {variantName &&
                    formatVariant(variantName)?.map((variant: string, index: number) => (
                      <span 
                        key={index} 
                        style={{ 
                          fontFamily: 'Ica Text Ny',
                          fontSize: '0.75rem',
                          lineHeight: '1.25rem',
                          fontWeight: '400',
                          padding: '0 1rem', 
                        }}>
                        {variant} 
                      </span>
                    ))}
                  </div>
                  <div
                    style={{
                      borderTop: '1px solid #868686'
                    }}> 
                    <div
                      style={{
                        display: 'flex',
                        padding:'0.5rem 1rem',
                        fontSize: '0.75rem',
                        flexDirection: 'column'
                      }}> 
                      <div
                        style={{
                          display: 'flex',
                          justifyContent:'space-between',
                          alignItems: 'center',
                        }}> 
                        <div>
                          <span
                            style={{
                              fontWeight: '700',
                            }}>
                            {amount} st
                          </span>
                        </div>
                        <div>
                          <span style={{ marginRight: '2rem' }}>{priceGross} kr/st</span>
                          <span
                            style={{
                              fontWeight: '700',
                            }}>
                            {getFormattedPrice(Math.round(priceGross * amount * 100) * 0.01)}
                          </span>
                        </div>
                      </div>  
                      <div>
                        {message && (
                          <span>
                            Övrigt meddelande: <span style={{ fontWeight: 'bold' }}>{message}</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )

            }
           
          })}
      </div>
           
      {/* TOTALT */}
      <div
        style={{
          border: '1px solid #868686',
          borderRadius: '4px',
          marginTop: '1.5rem'
        }}
      >
        {totalDiscount > 0 ? (
          <div style={{
            padding: '0.5rem 1rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span style={{ fontFamily: 'ICA Text Ny', fontWeight: 700 }}>Rabatt</span>
            <span style={{ fontFamily: 'ICA Rubrik', fontWeight: 700 }}>-{totalDiscount} kr</span>
          </div>
        ) : null}
        <div 
          style={{ 
            fontSize: '1rem', 
            lineHeight: '1.5rem', 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5rem 1rem' 
          }}>
          <div> 
            <span style={{ fontFamily: 'ICA Text Ny', fontWeight: 700, marginRight: '0.75rem' }}>
              Totalt
              <span style={{ fontWeight: 300 }}> ({totalNumberOfArticles} st artiklar)</span>
            </span>
          </div>
          <div>
            <span style={{ fontFamily: 'ICA Rubrik', fontWeight: 700 }}>{totalPrice} kr</span>
            <span style={{ fontSize: '0.75rem', fontFamily: 'ICA Rubrik', fontWeight: 300, paddingLeft: '0.5rem' }}>{deliveryFee > 0 ? `varav leveranskostnad ${deliveryFee} kr` : ''}</span>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
export default PrintCateringOrder
