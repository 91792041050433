import React from 'react'
import Timer from './Timer'
import ContactIcon from '../icons/User'
import styles from './Card.module.css'
import { Status } from '../types/Status'
import DeliveryInfoContainer from './DeliveryInfoContainer'

interface Props {
  cardType: Status
  number: number | string
  deliveryType: number | null
  bongTitle: string
  createdAt: string
  onDivClick?: () => void
  children?: React.ReactNode
  tableNumber: string | null
  userName: string
  returns: 'FULL' | 'INFO_ONLY'
}

function Card ({
  cardType = 'PREPARING',
  number,
  deliveryType,
  bongTitle,
  createdAt,
  onDivClick,
  children,
  tableNumber,
  userName,
  returns = 'FULL'
}: Props) {

  const cardStyles = React.useMemo(() => {
    const styled = {
      style: styles.cardPreparing,
      color: '#94c11a',
      header: styles.cardHeader
    }
    if (deliveryType === 200 || deliveryType === 700) {
      styled.color = '#e84c05'
    } else if (bongTitle.length > 0) {
      styled.color = 'blue'
    }
    if (cardType === 'READY') {
      styled.style = styles.cardReady
      styled.header = styles.readyHeader

    }
    return styled
  }, [cardType])

  return (
    returns === 'FULL' ? (
      <div className={cardStyles.style}>
        <div className={cardStyles.header} onClick={onDivClick}>
          <DeliveryInfoContainer
            color={cardStyles.color}
            tableNumber={tableNumber}
            deliveryType={deliveryType}
            orderNumber={number}
            bongTitle={bongTitle}
          />
          {cardType !== 'READY' && <Timer createdAt={createdAt} />}
          {cardType === 'PREPARING' && userName && (
            <div className={styles.contactInfo}>
              <ContactIcon color='var(--grayDark)' className={styles.contactIcon} />
              <span>{userName}</span>
            </div>
          )}
        </div>
        <div className={cardType !== 'READY' ? styles.cardContainer : ''}>{children}</div>
      </div>
    ) :
      (
        <DeliveryInfoContainer
          color={cardStyles.color}
          tableNumber={tableNumber}
          deliveryType={deliveryType}
          orderNumber={number}
          bongTitle={bongTitle}
        />
      )
  )
}

export default Card
