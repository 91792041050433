import React from 'react'
import { Dispatch, RootState } from "../models"
import { connect } from 'react-redux'
import styles from './MessageToast.module.css'

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function MessageToast({ message }: Props) {
  return (
    <>
      {message && <div className={styles.messageToast}>
        <div>{message}</div>
      </div>}
    </>
  )
}

function mapState(state: RootState) {
  return {
    message: state.message.message
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    showMessageToast: dispatch.message.showMessageToast
  }
}

export default connect(mapState, mapDispatch)(MessageToast)
