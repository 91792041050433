import React from 'react'
interface IconProps {
  color: string
  className: string
}

function Copy({ className, color = '#3A3A3A' }: IconProps) {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.71 2H5.63C4.8 2 4.13 2.67 4.13 3.5V4.135H3.5C2.67 4.135 2 4.805 2 5.635V12.715C2 13.545 2.67 14.215 3.5 14.215H10.58C11.41 14.215 12.08 13.545 12.08 12.715V12.08H12.71C13.54 12.08 14.21 11.41 14.21 10.58V3.5C14.21 2.67 13.54 2 12.71 2ZM11.08 12.71C11.08 12.985 10.855 13.21 10.58 13.21H3.5C3.225 13.21 3 12.985 3 12.71V5.635C3 5.36 3.225 5.135 3.5 5.135H10.58C10.855 5.135 11.08 5.36 11.08 5.635V12.715V12.71ZM13.21 7.075V10.575C13.21 10.85 12.985 11.075 12.71 11.075H12.08V5.635C12.08 4.805 11.41 4.135 10.58 4.135H5.13V3.5C5.13 3.225 5.355 3 5.63 3H12.71C12.985 3 13.21 3.225 13.21 3.5V7.08V7.075Z" fill={color}/>
    </svg>
  )
}

export default Copy