import { createModel } from '@rematch/core'
import { FETCHING, IDLE } from '../helpers/constants'
import { groupByStatus } from '../helpers/groupOrders'
import { fetchFrom } from '../utils/fetchData'
import type { RootModel } from '.'
import { Status } from '../types/Status'
import { AnonymousOrder } from '../types/Order'
import { getItemsCountFrom } from '../helpers/getItemsCount'
import logError from '../utils/logging'

interface AnonymousOrderState {
  status: Status,
  allOrders: AnonymousOrder[],
  ordersWaiting: AnonymousOrder[],
  ordersBeingPrepared: AnonymousOrder[],
  ordersReadyForDelivery: AnonymousOrder[],
  ordersPickedUp: AnonymousOrder[],
}

export const anonymousOrder = createModel<RootModel>()({
  state: {
    status: IDLE,
    allOrders: [],
    ordersWaiting: [],
    ordersBeingPrepared: [],
    ordersReadyForDelivery: [],
    ordersPickedUp: [],
  } as AnonymousOrderState,
  reducers: {
    startFetching(state) {
      return { ...state, status: FETCHING }
    },
    setOrdersWaiting(state, payload: AnonymousOrder[]) {
      return { ...state, status: IDLE, ordersWaiting: payload }
    },
    setAllOrders(state, payload: AnonymousOrder[]) {
      return { ...state, status: IDLE, allOrders: payload }
    },
    setOrdersBeingPrepared(state, payload: AnonymousOrder[]) {
      return { ...state, status: IDLE, ordersBeingPrepared: payload }
    },
    setOrdersReadyForDelivery(state, payload: AnonymousOrder[]) {
      return { ...state, status: IDLE, ordersReadyForDelivery: payload }
    },
    setOrdersPickedUp(state, payload: AnonymousOrder[]) {
      return { ...state, status: IDLE, ordersPickedUp: payload }
    },
  },
  effects: (dispatch) => ({
    clearAnonymousOrder() {
      dispatch.anonymousOrder.setAllOrders([])
      dispatch.anonymousOrder.setOrdersWaiting([])
      dispatch.anonymousOrder.setOrdersBeingPrepared([])
      dispatch.anonymousOrder.setOrdersReadyForDelivery([])
      dispatch.anonymousOrder.setOrdersPickedUp([])
    },
    updateStatusState(_payload, state) {
      const storeOrders = state.anonymousOrder.allOrders || []
      dispatch.anonymousOrder.setAllOrders(storeOrders)
      const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<AnonymousOrder>(storeOrders)
      dispatch.anonymousOrder.setOrdersWaiting(waiting)
      dispatch.anonymousOrder.setOrdersBeingPrepared(beingPrepared)
      dispatch.anonymousOrder.setOrdersReadyForDelivery(readyForDelivery)
      dispatch.anonymousOrder.setOrdersPickedUp(pickedUp)
    },
    async fetchStatusOrders(payload) {
      const { storeId, fetching } = payload
      if (fetching === true) {
        dispatch.anonymousOrder.startFetching()
      }

      try {
        const storeOrders = await fetchFrom<AnonymousOrder[]>(`/api/anonymous/stores/${storeId}/orders/anonymous`, {
          noAuth: true,
        })

        if (storeOrders && storeOrders.length > 0) {
          dispatch.anonymousOrder.setAllOrders(storeOrders)

          const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<AnonymousOrder>(storeOrders)

          dispatch.anonymousOrder.setOrdersWaiting(waiting)
          dispatch.anonymousOrder.setOrdersBeingPrepared(beingPrepared)
          dispatch.anonymousOrder.setOrdersReadyForDelivery(readyForDelivery)
          dispatch.anonymousOrder.setOrdersPickedUp(pickedUp)
        } else {
          dispatch.anonymousOrder.clearAnonymousOrder()
        }
      } catch (error) {
        logError(error as Error)
        dispatch.anonymousOrder.clearAnonymousOrder()
      }
    },
    async fetchStatusOrderById(payload, state) {
      const { storeId, orderId, loading } = payload

      if (loading === true) {
        dispatch.anonymousOrder.startFetching()
      }

      try {
        const newOrder = await fetchFrom<AnonymousOrder>(`/api/anonymous/stores/${storeId}/orders/${orderId}/anonymous`)
        if (newOrder) {
          const newOrderStatus = newOrder?.status || 0

          if (newOrderStatus >= 0 || newOrderStatus < 4) {
            if (state.anonymousOrder.allOrders) {
              dispatch.anonymousOrder.setAllOrders([...state.anonymousOrder.allOrders, newOrder])
            } else {
              dispatch.anonymousOrder.setAllOrders([newOrder])
            }

            switch (newOrderStatus) {
            case 0:
              dispatch.anonymousOrder.setOrdersWaiting([...state.anonymousOrder.ordersWaiting, newOrder])
              break
            case 1:
              dispatch.anonymousOrder.setOrdersBeingPrepared([...state.anonymousOrder.ordersBeingPrepared, newOrder])
              break
            case 2:
              dispatch.anonymousOrder.setOrdersReadyForDelivery([...state.anonymousOrder.ordersReadyForDelivery, newOrder])
              break
            case 3:
              dispatch.anonymousOrder.setOrdersPickedUp([...state.anonymousOrder.ordersPickedUp, newOrder])
              break
            default:
              break
            }
          }
        }
      } catch (error) {
        logError(error as Error)
      }
    },
  })
})
